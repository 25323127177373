import React from 'react';


export default {

    notify: (helpers, { key, title, message, options }) => {

        if(document.visibilityState === 'visible') {
            return;
        }

        Notification.requestPermission((result) => {

            if(result === 'granted') {


                let notification = new Notification(title, {
                    ...(options || {}),
                    badge: 1,
                    body: message || '',
                    tag: key,
                    vibrate: [200, 100, 200]
                })
                setTimeout(() => {
                    notification.close.bind(notification)
                }, 5000)
            }
        })
    }
}
