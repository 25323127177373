import React, { PureComponent, useRef, useState, useEffect } from 'react';

import { easeCubic } from 'd3-ease';
import { lineString } from '@turf/helpers';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import ReactMapGL, { Source, Layer, Popup, Marker, FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

export const GHSCoordinate = {
    latitude: 32.972660,
    longitude: -97.016320
}
export const Map = ({ annotations, center, circles, features, heatmap, isScrollEnabled, isRotationEnabled, isZoomEnabled, onAnnotationClick, overlays, region, style }) => {

    const mapRef = useRef(null);
    const [_overlays, _setOverlays] = useState([]);
    const [_annotations, _setAnnotations] = useState([]);

    const [hover, setHover] = useState(null);
    const [mapStyle, setMapStyle] = useState('standard');
    const [showUserLocation, setShowUserLocation] = useState(false);
    const [viewport, setViewport] = useState({
        zoom: 12,
        latitude: center ? center.latitude : GHSCoordinate.latitude,
        longitude: center ? center.longitude : GHSCoordinate.longitude
    });

    const setFeatureProps = () => {

        // prevent moving forward if no feature data was provided
        let { data, icons } = features || {};
        if(!data || !mapRef.current) {
            return
        }

        // load icon images if applicable
        if(icons) {
            let map = mapRef.current.getMap();
            icons.forEach(icon => {

                // request map to load image
                map.loadImage(icon.path, (error, image) => {
                    if(error) {
                        console.log(error.message);
                        return;
                    }
                    if(map.hasImage(icon.key) === false) {
                        map.addImage(icon.key, image, { sdf: icon.raster ? false : true });
                    }
                });
            });
        }
    }

    const onHoverChange = evt => {

        let { srcEvent: { offsetX, offsetY } } = evt;
        let selected = evt.features && evt.features[0];

        // prevent moving forward if nothing was selectred or if no features were provided
        if(!selected || !features) {
            setHover(null);
            return;
        }

        // loop through layer targets and look for matching source
        let targets = features.layers || [features.layer];
        let target = targets.find(target => target.id === selected.layer.id);
        if(target && typeof(target.onHover) === 'function') {
            setHover({
                ...target.onHover(selected.properties),
                x: offsetX + 8,
                y: offsetY + 8
            });
            return;
        }

        // reset hover component
        setHover(null);
    }

    const onMapClick = evt => {

        let { srcEvent: { offsetX, offsetY } } = evt;
        let selected = evt.features && evt.features[0];

        // prevent moving forward if nothing was selectred or if no features were provided
        if(!selected || !features) {
            return;
        }

        // loop through layer targets and look for matching source
        let targets = features.layers || [features.layer];
        let target = targets.find(target => target.id === selected.layer.id);
        if(target && typeof(target.onClick) === 'function') {
            target.onClick(selected.properties);
        }
    }

    const onUpdateRegion = () => {

        // // fit map bounds using supplied region if applicable
        if(region) {
            let interval = setInterval(() => {

                // prevent moving forward if a map ref has not posted
                if(!mapRef.current) {
                    console.warn('waiting on map ref...');
                    return;
                }

                // clear interval and generate region props
                clearInterval(interval);
                let { longitude, latitude, zoom } = getRegion(region);

                // update viewport with new region
                setViewport(viewport => update(viewport, {
                    ...getViewportAnimations,
                    longitude: {
                        $set: isNaN(longitude) ? viewport.longitude : longitude
                    },
                    latitude: {
                        $set: isNaN(latitude) ? viewport.latitude : latitude
                    },
                    zoom: {
                        $set: Math.abs(zoom)
                    }
                }));
            }, 250);
        }
    }

    const onZoom = amount => {
        let zoom = viewport.zoom + amount;
        setViewport(viewport => update(viewport, {
            ...getViewportAnimations,
            zoom: {
                $set: parseInt(zoom <= 0 ? 0 : (zoom > 25 ? 25 : zoom))
            }
        }));
    }

    const getAnnotations = () => {
        return (
            <Annotations
            data={_annotations}
            onClick={onAnnotationClick} />
        )
    }

    const getCircles = () => {
        return circles && circles.map((circle, index) => {
            let sourceID = `circle-${index}`;
            return (
                <Source
                data={circle}
                id={sourceID}
                key={index}
                type={'geojson'}>
                    <Layer
                    id={`circle-${index}-layer`}
                    type={'fill'}
                    source={sourceID}
                    paint={{
                        'fill-color': Appearance.colors.primary(),
                        'fill-opacity': 0.25
                    }} />
                </Source>
            )
        })
    }

    const getFeatureCollections = () => {
        if(!features || !features.data) {
            return null;
        }
        let targets = features.layers || [features.layer];
        return (
            <Source
            data={features.data}
            key={features.id}
            id={features.id}
            type={'geojson'}>
                {targets.map(target => (
                    <Layer 
                    key={target.id}
                    {...target} />
                ))}
            </Source>
        )
    }

    const getHeatMapLayer = () => {
        if(!heatmap) {
            return null;
        }
        let { data, layer } = heatmap;
        if(!data || !layer) {
            return null;
        }
        return (
            <Source
            type={'geojson'}
            data={data}>
                <Layer {...layer} />
            </Source>
        )
    }

    const getHover = () => {
        if(!hover || !hover.title) {
            return null;
        }
        return (
            <div style={{
                left: hover.x,
                minWidth: 200,
                position: 'absolute',
                top: hover.y,
                ...Appearance.styles.unstyledPanel()
            }}>
                {Views.entry({
                    ...hover,
                    bottomBorder: false,
                    hideIcon: hover.icon ? false : true
                })}
            </div>
        )
    }

    const getMapStyle = () => {
        if(mapStyle === 'satelite') {
            return 'mapbox://styles/ghs-rida/ckn68tiga03h717pniqo43wgl';
        }
        if(window.theme === 'dark') {
            return 'mapbox://styles/ghs-rida/ckjbwtz4v6aec19s88fy06q1q';
        }
        return 'mapbox://styles/ghs-rida/ckjbwtaqn4zz419o0gbi06thl';
    }

    const getOverlays = () => {
        return _overlays.map(polyline => {
            return (
                <Source
                key={polyline.key}
                type={'geojson'}
                data={polyline.coordinates}>
                    <Layer
                    id={polyline.key}
                    type={'line'}
                    source={'route'}
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round'
                    }}
                    paint={{
                        'line-color': polyline.color ? Appearance.colors.blue : Appearance.colors.grey(),
                        'line-width': 3
                    }}/>
                </Source>
            )
        })
    }

    const getRegion = region => {
        try {

            // prepare props for viewport
            // occasionally viewport height and width have not posted organically and need to be manually set
            // this can happen if the data for the map is ready immediately but the map is not ready
            let props = { 
                ...viewport,
                height: viewport.height || mapRef.current && mapRef.current._height,
                width: viewport.width || mapRef.current && mapRef.current._width
            }
            return new WebMercatorViewport(props).fitBounds(region, { padding: 50 });
            
        } catch(e) {
            // error may be thrown if padding exceeds viewport size
            return new WebMercatorViewport(viewport).fitBounds(region);
        }
    };

    const getViewportAnimations = {
        transitionDuration: {
            $set: 750
        },
        transitionInterpolator: {
            $set: new FlyToInterpolator()
        },
        transitionEasing: {
            $set: easeCubic
        }
    }

    useEffect(() => {
        setFeatureProps();
    }, [features, mapStyle]);

    useEffect(() => {
        onUpdateRegion();
    }, [region]);

    useEffect(() => {
        _setOverlays(overlays && Array.isArray(overlays) ? overlays.map(overlay => {
            return {
                ...overlay,
                coordinates: lineString(overlay.coordinates.map(o => [ o[1], o[0] ]))
            }
        }) : []);
    }, [overlays]);

    useEffect(() => {

        // fit into viewport
        if(_overlays.length > 0) {
            let region = Utils.getRegionFromAnnotations(overlays.reduce((array, overlay) => {
                return array.concat(overlay.coordinates)
            }, []));

            const { longitude, latitude, zoom } = getRegion(region);
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: isNaN(longitude) ? viewport.longitude : longitude
                },
                latitude: {
                    $set: isNaN(latitude) ? viewport.latitude : latitude
                },
                zoom: {
                    $set: zoom
                }
            }));
        }

    }, [_overlays]);

    useEffect(() => {
        if(circles && circles.length > 0) {

            // prepare collection of coordinates using circles geometry
            let region = Utils.getRegionFromAnnotations(circles.reduce((array, circle) => {
                circle.geometry.coordinates[0].forEach(coordinate => {
                    array.push([coordinate[1], coordinate[0]]);
                })
                return array;
            }, []));

            // calculate coordinate and zoom for map using generated region
            const { longitude, latitude, zoom } = getRegion(region);
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: isNaN(longitude) ? viewport.longitude : longitude
                },
                latitude: {
                    $set: isNaN(latitude) ? viewport.latitude : latitude
                },
                zoom: {
                    $set: zoom
                }
            }));
        }
    }, [circles]);

    // Annotations
    useEffect(() => {
        if(!annotations && !Array.isArray(annotations)) {
            return;
        }

        let newAnnotations = annotations.filter(a => a.location && a.location.latitude && a.location.longitude);
        if(showUserLocation && window.userLocation) {
            newAnnotations.push({
                id: 'current-location',
                title: 'Current Location',
                location: {
                    latitude: window.userLocation.latitude,
                    longitude: window.userLocation.longitude
                }
            });
        }
        _setAnnotations(newAnnotations);

    }, [annotations, showUserLocation]);

    useEffect(() => {

        if(_annotations.length === 0) {
            return;
        }

        // single annotation
        if(_annotations.length === 1) {
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: _annotations[0].location.longitude
                },
                latitude: {
                    $set: _annotations[0].location.latitude
                },
                zoom: {
                    $set: 16
                }
            }));
            return;
        }

        // multiple annotations
        const { longitude, latitude, zoom } = getRegion(Utils.getRegionFromAnnotations(_annotations));
        setViewport(viewport => update(viewport, {
            ...getViewportAnimations,
            longitude: {
                $set: isNaN(longitude) ? viewport.longitude : longitude
            },
            latitude: {
                $set: isNaN(latitude) ? viewport.latitude : latitude
            },
            zoom: {
                $set: _annotations.length === 1 || zoom >= 20 ? 18 : zoom
            }
        }));

    }, [_annotations, annotations ]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: 10,
            ...style
        }}>
            <ReactMapGL
            ref={mapRef}
            {...viewport}
            scrollZoom={false}
            dragPan={isScrollEnabled || false}
            dragRotate={isRotationEnabled || false}
            doubleClickZoom={isZoomEnabled || false}
            touchRotate={isRotationEnabled || false}
            touchZoom={isZoomEnabled || false}
            attributionControl={false}
            mapStyle={getMapStyle()}
            onClick={onMapClick}
            onHover={onHoverChange}
            mapboxApiAccessToken={API.mapbox}
            onViewportChange={setViewport}
            width={style && style.width ? style.width : '100%'}
            height={style && style.height ? style.height : '100%'}>

                {getAnnotations()}
                {getOverlays()}
                {getHeatMapLayer()}
                {getFeatureCollections()}
                {getCircles()}
                {getHover()}

            </ReactMapGL>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                bottom: 8,
                right: 8
            }}>
                <div style={{
                    borderRadius: 5,
                    border: `1px solid ${Appearance.colors.divider()}`,
                    backgroundColor: window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'white',
                }}>
                    <span
                    className={'text-button'}
                    onClick={() => setMapStyle(style => style === 'satelite' ? 'standard' : 'satelite')}
                    style={{
                        ...Appearance.textStyles.subTitle(),
                        padding: '8px 12px 8px 12px',
                        borderRight: `1px solid ${Appearance.colors.divider()}`
                    }}>{mapStyle === 'satelite' ? 'Standard' : 'Satelite'}</span>
                    <img
                    className={'text-button'}
                    src={'images/minus-icon-grey-small.png'}
                    onClick={onZoom.bind(this, -1)}
                    style={{
                        padding: 8,
                        width: 25,
                        height: 25,
                        borderRight: '1px solid rgba(174,174,174,0.15)',
                        objectFit: 'contain',
                        opacity: 0.75
                    }} />
                    <img
                    className={'text-button'}
                    src={'images/plus-icon-grey-small.png'}
                    onClick={onZoom.bind(this, 1)}
                    style={{
                        padding: 8,
                        width: 25,
                        height: 25,
                        objectFit: 'contain',
                        opacity: 0.75
                    }} />
                </div>
            </div>
        </div>
    );
}

class Annotations extends PureComponent {

    state = {
        hover: false
    }

    getMarkerIcon = annotation => {

        let { icon } = annotation;
        let { onClick } = this.props;

        if(icon && icon.component) {
            return (
                <div
                className={onClick ? 'image-button' : ''}
                onMouseEnter={() => this.setState({ hover: annotation.id })}
                onMouseLeave={() => this.setState({ hover: null })}
                onClick={onClick ? onClick.bind(this, annotation.id) : null}>
                    {icon.component}
                </div>
            );
        }

        return (
            <img
            className={onClick ? 'image-button' : ''}
            onMouseEnter={() => this.setState({ hover: annotation.id })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={onClick ? onClick.bind(this, annotation.id) : null}
            src={icon && icon.path ? icon.path : 'images/location-icon-grey.png'}
            style={{
                width: 35,
                height: 35,
                objectFit: 'contain',
                ...(icon ? icon.style : null)
            }} />
        )
    }

    render() {

        const { data } = this.props;
        if(!data) {
            return null;
        }
        return data.map((annotation, index) => {

            if(!annotation.location) {
                return null;
            }
            if(annotation.id === 'current-location') {
                return (
                    <Marker
                    key={index}
                    longitude={annotation.location.longitude}
                    latitude={annotation.location.latitude}
                    offsetTop={-9}
                    offsetLeft={-9}>
                        <div
                        className={'current-location'}
                        style={{
                            width: 18,
                            height: 18
                        }} />
                    </Marker>
                )
            }
            return (
                <Marker
                key={index}
                captureClick={false}
                offsetTop={-35}
                offsetLeft={-17.5}
                {...annotation.location}
                {...annotation.icon && annotation.icon.style ? annotation.icon.style.offset : null}>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {this.getMarkerIcon(annotation)}
                        {this.state.hover === annotation.id && typeof(annotation.title) === 'string' && (
                            <div style={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                top: 40,
                                backgroundColor: Appearance.colors.layerBackground(),
                                border: `1px solid ${Appearance.colors.softBorder()}`,
                                padding: '6px 12px 6px 12px',
                                borderRadius: 10,
                                overflow: 'hidden',
                                textAlign: 'center',
                                zIndex: 4500
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    marginBottom: 2
                                }}>{annotation.title}</span>
                                {typeof(annotation.subTitle) === 'string' && (
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        marginBottom: 2
                                    }}>{annotation.subTitle}</span>
                                )}
                            </div>
                        )}
                    </div>
                </Marker>
            )
        })
    }
}
