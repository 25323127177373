import Utils from 'files/Utils.js';

const Appearance = {
    colors: {
        primary: () => {
            return '#4A90E2';
        },
        secondary: () => {
            return '#1C59A1';
        },
        tertiary: () => {
            return '#75AEF0';
        },
        soft: () => {
            return window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        grey: () => {
            return window.theme === 'dark' ? '#606060' : '#9B9B9B';
        },
        altGrey: () => {
            return window.theme === 'dark' ? '#707070' : '#B3B3B3';
        },
        text: () => {
            return window.theme === 'dark' ? 'white' : '#323232';
        },
        subText: () => {
            return window.theme === 'dark' ? 'rgba(200,200,200,1)' : 'rgba(150,150,150,1)';
        },
        background: () => {
            return window.theme === 'dark' ? 'rgba(25,25,25,1)' : '#F0F1F5';
        },
        componentBackground: () => {
            return window.theme === 'dark' ? 'rgba(55,55,55,1)' : 'rgba(255,255,255,1)';
        },
        loginBackground: () => {
            return window.theme === 'dark' ? Appearance.colors.background() : Appearance.colors.primary();
        },
        panelBackground: () => {
            return window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(250,250,250,1)';
        },
        panelMobileTabBackground: () => {
            return window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'rgba(235,235,235,1)';
        },
        layerBackground: () => {
            return window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(250,250,250,1)';
        },
        alert: () => {
            return window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        sheet: () => {
            return window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        dropDown: () => {
            return window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'white';
        },
        textField: () => {
            return window.theme === 'dark' ? 'rgba(55,55,55,1)' : 'rgba(245,245,245,1)';
        },
        divider: () => {
            return 'rgba(150,150,150,0.15)';
        },
        darkDivider: () => {
            return 'rgba(150,150,150,0.35)';
        },
        softBorder: () => {
            return window.theme === 'dark' ? 'rgba(85,85,85,1)' : 'rgba(220,220,220,1)';
        },
        icon: () => {
            return window.theme === 'dark' ? '#FFFFFF' : 'rgba(200,200,200,1)';
        },
        timeOfDay: key => {
            switch(key) {
                case 'morning':
                return '#D5AC6B';

                case 'noon':
                return '#A48F74';

                case 'afternoon':
                return '#7F6453';

                case 'evening':
                return '#4C4C69';

                default:
                return '#4A4A4A';
            }
        },
        softGradient: color => {
            return `linear-gradient(45deg, ${Utils.hexToRGBA(color, 0.85)}, ${Utils.hexToRGBA(color, 0.7)})`
        },
        sensor: {
            green: '#95D78F',
            yellow: '#F0DD6B',
            red: '#F4745F'
        },
        red: '#F45D4C',
        darkRed: '#982F23',
        orange: '#EE8A2F',
        yellow: '#E6C02A',
        blue: '#4A90E2',
        green: '#6CBF66',
        darkGreen: '#5E905C',
        teal: '#5AD8BC',
        darkBlue: '#153B67',
        purple: '#7A5EB3',
        brown: '#674429',
        lightGrey: '#9B9B9B',
        lighterGrey: '#BDBDBD',
        darkGrey: '#4A4A4A',
        darkerGrey: '#232323',
        dim: 'rgba(0,0,0,0.45)',
        transparent: 'rgba(0,0,0,0)',
        omnishieldPrimary: '#043963',
        omnishieldSecondary: '#2E3F4D',
        omnishieldTertiary: '#22547D'
    },
    panelOptionsButton: () => {
        return {
            backgroundColor: window.theme === 'dark' ? 'rgba(140,140,140,1)' : Appearance.colors.lightGrey
        }
    },
    sidebar: {
        icons: {
            color: () => {
                return window.theme === 'dark' ? 'rgba(200,200,200,0.5)' : 'rgba(127,127,127,0.5)'
            }
        }
    },
    mapStyle: () => {
        return {
            border: '1px solid ' + (window.theme === 'dark' ? 'rgba(150,150,150,0.25)' : 'rgba(0,0,0,0)'),
            borderRadius: '7px'
        }
    },

    textStyles: {
        standard: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 600,
                fontSize: 13,
                ...props
            }
        },
        supporting: props => {
            return {
                color: Appearance.colors.subText(),
                fontWeight: 500,
                fontSize: 12,
                ...props
            }
        },
        heavyHeader: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 700,
                fontSize: 20,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        header: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 700,
                fontSize: 16,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        subHeader: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 700,
                fontSize: 14,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        layerItemTitle: props => { 
            return {
                color: Appearance.colors.text(),
                display: 'block',
                fontWeight: 700,
                fontSize: 16,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        title: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 700,
                fontSize: 14,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        subTitle: props => {
            return {
                color: Appearance.colors.subText(),
                fontWeight: 600,
                fontSize: 12,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        key: props => {
            return {
                color: Appearance.colors.text(),
                fontWeight: 600,
                fontSize: 12,
                minWidth: 100,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props
            }
        },
        value: props => {
            return {
                color: Appearance.colors.subText(),
                fontWeight: 500,
                fontSize: 12,
                textAlign: 'right',
                whiteSpace: 'break-spaces',
                ...props
            }
        },
    },

    styles: {
        panel: () => {
            return {
                borderRadius: 15,
                backgroundColor: Appearance.colors.panelBackground(),
                boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25), -5px -5px 15px rgba(85,85,85,0.25)' : '5px 5px 15px rgba(175,175,174,0.25), -5px -5px 15px rgba(255,255,255,0.75)',
                borderWidth: 4,
                borderStyle: 'solid',
                borderColor: window.theme === 'dark' ? '#191919' : '#FFFFFF'
            }
        },
        unstyledPanel: () => {
            return {
                overflow: 'hidden',
                backgroundColor: window.theme === 'dark' ? 'rgba(60,60,60,1)' : 'rgba(252,252,252,1)',
                borderRadius: 10,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: Appearance.colors.divider()
            }
        },
        header: () => {
            return {
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: Utils.isMobile() ? '8px 10px 8px 10px' : 10,
                borderBottom: `1px solid ${window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'rgba(235,235,235,1)'}`
            }
        }
    },
    icons: {
        standard: () => {
            return {
                width: 30,
                height: 30,
                borderRadius: 17.5,
                objectFit: 'cover',
                backgroundColor: Appearance.colors.primary(),
                boxShadow: window.theme === 'dark' ? '3px 3px 7px rgba(0,0,0,0.25), -3px -3px 7px rgba(174,174,174,0.1)' : '3px 3px 7px #c9c8ca, -3px -3px 7px #ffffff'
            }
        }
    },
    boxShadow: props => {

        let light = props ? props.light : null;
        let dark = props ? props.dark : null;

        return `${dark && dark.offset ? dark.offset.top : 2.5} ${dark && dark.offset ? dark.offset.left : 2.5} ${dark ? dark.radius : 7.5} ${window.theme === 'dark' ? `rgba(0,0,0,0.075)`:'rgba(0,0,0,0.35)'}, ${light && light.offset ? light.offset.top : -2.5} ${light && light.offset ? light.offset.left : -2.5} ${light ? light.radius : 5} ${window.theme === 'dark' ? 'rgba(85,85,85,0.075)' : 'rgba(85,85,85,0.35)'}`;
    }
}
export default Appearance;
