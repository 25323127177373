import React, { useRef, useState, useEffect } from 'react';

import { AddEditUser, UserDetails } from 'managers/Users.js';
import Appearance from 'styles/Appearance.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Abstract from 'classes/Abstract.js';
import Content from 'managers/Content.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import SystemEventsList from 'views/SystemEventsList.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

export const SectorList = ({ id, sector_type, title }, { index, options, utils }) => {

    const limit = 10;
    const offset = useRef(0);

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sectors, setSectors] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [sorting, setSorting] = useState(null);

    const onChangeActiveStatus = async (sector, evt) => {
        try {
            evt.stopPropagation();
            let next_status = !sector.active;
            await Request.post(utils, '/sectors/', {
                type: 'set_status',
                id: sector.id,
                active: next_status,
                sector_type: sector_type
            });

            sector.active = next_status;
            utils.content.update({
                type: 'sector',
                object: sector
            })

            setSectors(sectors => {
                return sectors.map(prev_sector => {
                    if(prev_sector.id === sector.id) {
                        prev_sector.active = next_status;
                    }
                    return prev_sector;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this ${title}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewSectorClick = () => {

        // prevent moving forward if user is not an aministrator
        if(utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Your account is not eligible for creating new sectors. Please contact Home Office if you need a new sector created.'
            });
            return;
        }

        // show editing layer for sector
        utils.layer.open({
            id: 'new_sector',
            abstract: Abstract.create({
                type: 'sector',
                object: Sector.new()
            }),
            Component: AddEditSector.bind(this, {
                isNewTarget: true,
                sector_type: sector_type
            })
        })
    }

    const onPrintSectors = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { sectors } = await Request.get(utils, '/sectors/', {
                    type: 'all_admin',
                    offset: offset.current,
                    limit: limit,
                    search_text: searchText,
                    sector_type: sector_type,
                    ...props
                });

                setLoading(false);
                resolve(sectors.map(sector => Sector.create(sector)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onSectorClick = sector => {
        utils.layer.open({
            id: `sector_details_${sector.id}`,
            abstract: Abstract.create({
                type: 'sector',
                object: sector
            }),
            Component: SectorDetails
        })
    }

    const onUpdateSector = data => {
        try {
            setSectors(sectors => {
                return sectors.map(sector => {
                    return sector.id === data.sector.id ? Sector.create(data.sector) : sector;
                })
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    const getActiveStatus = sector => {
        if(!sector) {
            return;
        }
        let color = sector.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, sector)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{sector.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getButtons = () => {

        let buttons = [{
            key: 'new',
            title: `New ${title}`,
            style: 'default',
            onClick: onNewSectorClick
        }]
        return buttons.concat([{
            key: 'active',
            title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
            style: showInactive ? 'default' : 'grey',
            onClick: () => setShowInactive(status => !status)
        }])
    }

    const getContent = () => {
        if(sectors.length === 0) {
            return (
                Views.entry({
                    title: `No ${title}s Found`,
                    subTitle: `No ${title.toLowerCase()}s were found in the system`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return sectors.map((sector, index) => {
                return getFields(sector, index)
            });
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sectors.map((sector, index) => {
                        return getFields(sector, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (sector, index) => {

        let target = sector || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: sector.name,
                    subTitle: sector.director ? sector.director.full_name : 'Director not available',
                    icon: {
                        path: sector.director.avatar
                    },
                    badge: {
                        text: sector.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    firstItem: index === 0,
                    singleItem: sectors.length === 1,
                    lastItem: index === sectors.length - 1,
                    bottomBorder: index !== sectors.length - 1,
                    onClick: onSectorClick.bind(this, sector)
                })
            )
        }

        let fields = [{
            key: 'name',
            title: 'Name',
            padding: false,
            value: (
                Views.entry({
                    key: index,
                    title: target.name,
                    subTitle: target.date ? Utils.formatDate(target.date) : null,
                    icon: {
                        path: target.director ? target.director.avatar : null
                    },
                    bottomBorder: false,
                    style: {
                        padding: '8px 12px 8px 12px',
                        textAlign: 'left'
                    }
                })
            )
        },{
            key: 'director',
            title: 'Director',
            value: target.director ? target.director.full_name : null
        },{
            key: 'active',
            sortable: false,
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sector) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'name',
                [Content.sorting.type.ascending]: 'name',
                [Content.sorting.type.descending]: 'name'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === sectors.length - 1}
            onClick={onSectorClick.bind(this, sector)} />
        )
    }

    const getPrintProps = () => {
        let headers = [{
            key: 'name',
            title: 'Name'
        },{
            key: 'director',
            title: 'Director'
        },{
            key: 'status',
            title: 'Status'
        }];
        return {
            headers: headers,
            onFetch: onPrintSectors,
            onRenderItem: (item, index, items) => ({
                name: item.name,
                director: item.director ? item.director.full_name : null,
                status: item.active ? 'Active' : 'Not Active'
            })
        }
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'sectors', 'on_new_sector', fetchSectors);
            await utils.sockets.on('aft', 'sectors', 'on_update_sector', onUpdateSector);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'sectors', 'on_new_sector', fetchSectors);
            await utils.sockets.off('aft', 'sectors', 'on_update_sector', onUpdateSector);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchSectors = async () => {
        try {
            if(utils.fetching.get(id) === true) {
                return;
            }
            utils.fetching.set(id, true);
            let { paging, sectors } = await Request.get(utils, '/sectors/', {
                type: 'all_admin',
                offset: offset.current,
                limit: limit,
                search_text: searchText,
                sector_type: sector_type,
                show_inactive: showInactive,
                ...sorting
            });

            setLoading(false);
            utils.fetching.set(id, false);

            setPaging(paging);
            setSectors(sectors.map(sector => Sector.create(sector)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(id, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the ${title.toLowerCase()} list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSectors();
    }, [searchText, showInactive, sorting]);

    useEffect(() => {

        connectToSockets();
        utils.content.subscribe(id, 'sector', {
            onFetch: fetchSectors,
            onUpdate: abstract => {
                setSectors(sectors => {
                    if(!sectors) {
                        return sectors;
                    }
                    return sectors.map(sector => {
                        return sector.id === abstract.getID() && sector.type === abstract.object.type ? abstract.object : sector;
                    })
                })
            }
        });

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(id, 'sector');
        }
    }, []);

    return (
        <Panel
        panelID={id}
        name={`${title}s`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchSectors();
                }
            },
            search: {
                placeholder: `Search by id, ${title.toLowerCase()} name, or director name...`,
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const VestedDirectorsList = ({ index, options, utils }) => {

    const panelID = 'vested_directors_list';
    const limit = 10;
    const offset = useRef(0);

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [showInactive, setShowInactive] = useState(false);
    const [sorting, setSorting] = useState(null);
    const [users, setUsers] = useState([]);

    const onNewUserClick = () => {
        let user = User.new();
        user.level = User.levels.get().vested_director;
        utils.layer.open({
            id: 'new_user',
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: AddEditUser.bind(this, {
                isNewTarget: true
            })
        })
    }

    const onPrintUsers = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { users } = await Request.get(utils, '/sectors/', {
                    type: 'vested_directors',
                    search_text: searchText,
                    show_inactive: showInactive,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(users.map(user => User.create(user)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onUserClick = user => {
        utils.layer.open({
            id: `user_details_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: UserDetails
        })
    }

    const getButtons = () => {

        let buttons = [{
            key: 'new',
            title: 'New Vested Director',
            style: 'default',
            onClick: onNewUserClick
        }]
        return buttons.concat([{
            key: 'active',
            title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
            style: showInactive ? 'default' : 'grey',
            onClick: () => setShowInactive(status => !status)
        }])
    }

    const getContent = () => {
        if(users.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: `No vested directors were found in the system`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return users.map((user, index) => {
                return getFields(user, index)
            });
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {users.map((user, index) => {
                        return getFields(user, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (user, index) => {

        let target = user || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.full_name,
                    subTitle: target.email_address || 'Email address not available',
                    icon: {
                        path: target.avatar
                    },
                    badge: {
                        text: target.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    firstItem: index === 0,
                    singleItem: users.length === 1,
                    lastItem: index === users.length - 1,
                    bottomBorder: index !== users.length - 1,
                    onClick: onUserClick.bind(this, user)
                })
            )
        }

        let fields = [{
            key: 'full_name',
            title: 'Full Name',
            padding: false,
            value: (
                Views.entry({
                    key: index,
                    title: target.full_name,
                    subTitle: target.email_address || 'Email address not available',
                    icon: {
                        path: target.avatar
                    },
                    bottomBorder: false,
                    style: {
                        padding: '8px 12px 8px 12px',
                        textAlign: 'left'
                    }
                })
            )
        },{
            key: 'dealership',
            title: 'Dealership',
            value: target.dealership ? target.dealership.name : null
        },{
            key: 'created',
            title: 'Vesting Date',
            value: target.created ? Utils.formatDate(target.created) : null
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!user) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'name',
                [Content.sorting.type.ascending]: 'created',
                [Content.sorting.type.descending]: 'created'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === users.length - 1}
            onClick={onUserClick.bind(this, user)} />
        )
    }

    const getPrintProps = () => {
        let headers = [{
            key: 'name',
            title: 'Name'
        },{
            key: 'dealership',
            title: 'Dealership'
        },{
            key: 'date',
            title: 'Vested Date'
        }];
        return {
            headers: headers,
            onFetch: onPrintUsers,
            onRenderItem: (item, index, items) => ({
                full_name: item.full_name,
                dealership: item.dealership ? item.dealership.name : null,
                date: item.date ? Utils.formatDate(item.date) : null
            })
        }
    }

    const fetchUsers = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            utils.fetching.set(panelID, true);
            let { paging, users } = await Request.get(utils, '/sectors/', {
                type: 'vested_directors',
                offset: offset.current,
                limit: limit,
                search_text: searchText,
                show_inactive: showInactive,
                ...sorting
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the vested directors list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [searchText, showInactive, sorting]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'user', {
            onFetch: fetchUsers,
            onUpdate: abstract => {
                setUsers(users => {
                    if(!users) {
                        return users;
                    }
                    return users.map(user => {
                        return user.user_id === abstract.getID() ? abstract.object : user;
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Vested Directors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchUsers();
                }
            },
            search: {
                placeholder: `Search by first and last name...`,
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditSector = ({ isNewTarget, sector_type }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_sector' : `edit_sector_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [sector, setSector] = useState(null);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            // confirm director account type for new director
            if(isNewTarget) {
                utils.alert.show({
                    title: 'Confirm Director',
                    message: `Are you sure that you want to set ${sector.director.full_name} as the Director for this ${Sector.typeToText(sector.type)}? This will change ${sector.director.first_name}'s account to a Director and allow ${sector.director.first_name} to view all Dealerships in this ${Sector.typeToText(sector.type)}'s downline.`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onDoneConfirm();
                            return;
                        }
                        setLoading(false);
                    }
                })
                return;
            }

            // confirm director change
            if(sector.director.user_id !== abstract.object.director.user_id) {
                utils.alert.show({
                    title: 'Confirm Director Change',
                    message: `Are you sure that you want to change the Director for this ${Sector.typeToText(sector.type)} from ${abstract.object.director.full_name} to ${sector.director.full_name}? This will change ${sector.director.full_name}'s account to a Director and change ${abstract.object.director.full_name}'s account back to a Dealer.`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onDoneConfirm();
                            return;
                        }
                        setLoading(false);
                    }
                })
                return;
            }

            // update without changing directors
            onDoneConfirm();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${Sector.typeToText(abstract.object.type).toLowerCase()}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDoneConfirm = async () => {
        try {

            setLoading('done');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            if(isNewTarget) {
                await abstract.object.submit(utils);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `${abstract.object.name} has been added as a new ${Sector.typeToText(abstract.object.type)}`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${abstract.object.name} ${Sector.typeToText(abstract.object.type)} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${Sector.typeToText(abstract.object.type).toLowerCase()}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setSector(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the information for this team. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getFields = () => {
        if(!sector) {
            return [];
        }
        return [{
            key: 'details',
            title: `About this ${Sector.typeToText(sector.type || sector_type)}`,
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name is used when identifying this team across the Global Health and Safety network.',
                value: sector.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text }),
                props: {
                    icon: 'title'
                }
            },{
                key: 'director',
                title: 'Director',
                description: `The Director is the Dealer who oversees this ${Sector.typeToText(sector.type || sector_type)}. This user will have access to all the Dealerships within this ${Sector.typeToText(sector.type || sector_type)}'s downline`,
                value: sector.director,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ director: user }),
                props: {
                    restrictToDealership: false,
                    levels: [
                        User.levels.get().admin,
                        User.levels.get().region_director,
                        User.levels.get().division_director,
                        User.levels.get().area_director,
                        User.levels.get().dealer,
                        User.levels.get().safety_associate
                    ]
                }
            }]
        }]
    }

    const setupTarget = async () => {
        try {

            let edits = await abstract.object.open();
            if(isNewTarget) {
                edits = await abstract.object.set({ type: sector_type });
            }
            setSector(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the ${isNewTarget ? 'new team' : 'editing'} process. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${Sector.typeToText(sector && sector.type ? sector.type : sector_type)}` : `Editing ${abstract.getTitle()}`}
        abstract={abstract}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            loading: loading === 'done',
            color: 'primary',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const SectorDetails = ({ abstract, index, options, utils }) => {

    const layerID = `sector_details_${abstract.getID()}`;
    const [director, setDirector] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sector, setSector] = useState(abstract.object);

    const onDelete = async () => {
        try {

            setLoading('options');
            await Utils.sleep(1);

            let { users } = await Request.get(utils, '/sectors/', {
                type: 'delete_preflight',
                id: sector.id,
                sector_type: sector.type
            });

            utils.alert.show({
                title: `Delete ${Sector.typeToText(sector.type)}`,
                message: `Are you sure that you want to delete this ${Sector.typeToText(sector.type)}. This will demote the current Director, ${sector.director.full_name}, to a Dealer and revoke any access that ${sector.director.first_name} had to other Dealerships. ${users > 0 ? `It will also remove ${users} ${users === 1 ? 'user' : 'users'} from the ${sector.name} ${Sector.typeToText(sector.type)}` : ''}.`,
                buttons: [{
                    key: 'confirm',
                    title: 'Delete',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Do Not Delete',
                    style: 'default'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onDeleteConfirm();
                        return;
                    }
                    setLoading(false);
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the preflight response. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDeleteConfirm = async () => {
        try {

            await Utils.sleep(1);
            await Request.post(utils, '/sectors/', {
                type: 'delete',
                id: sector.id,
                sector_type: sector.type
            });

            setLoading(false);
            utils.content.fetch('sector');
            utils.content.fetch('user');
            utils.alert.show({
                title: 'All Done!',
                message: `The ${sector.name} ${Sector.typeToText(sector.type)} has been deleted.`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${Sector.typeToText(sector.type)}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDirectorClick = async user => {
        try {
            let next = await User.get(utils, user.user_id);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: next
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {

        // prevent moving forward if user is not an aministrator
        if(utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Your account is not eligible for editing sectors. Please contact Home Office if you need this sector updated.'
            });
            return;
        }

        // show editing layer for existing sector
        utils.layer.open({
            abstract: abstract,
            Component: AddEditSector.bind(this, {
                isNewTarget: false,
                sector: abstract.object.type
            }),
            id: `edit_sector_${abstract.getID()}`
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'status',
                title: `Set as ${sector.active ? 'Inactive' : 'Active'}`,
                style: sector.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'status') {
                onSetStatus();
                return;
            }
            if(key === 'delete') {
                onDelete();
                return;
            }
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `Set as ${sector.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set ${sector.name} as ${sector.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: sector.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: sector.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            let status = !abstract.object.active;
            await Request.post(utils, '/sectors/', {
                type: 'set_status',
                id: sector.id,
                sector_type: sector.type,
                status: status
            });

            setLoading(false);
            abstract.object.active = status;
            setSector(abstract.object);
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `The ${abstract.object.name} ${Sector.typeToText(abstract.object.type)} has been set as ${status ? 'active' : 'inactive'}. The Director of this ${Sector.typeToText(abstract.object.type)}, ${abstract.object.director.full_name}, has been notified of this change.`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this ${Sector.typeToText(abstract.object.type)}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateSector = data => {
        try {
            if(abstract.getID() === data.sector.id) {
                abstract.object = Sector.create(data.sector);
                setSector(abstract.object);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            loading: loading === 'options',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getDirector = () => {
        if(!director) {
            return null;
        }
        return (
            <LayerItem title={'Director'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        title: director.full_name,
                        subTitle: director.email_address || director.phone_number,
                        icon: {
                            path: director.avatar
                        },
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: onDirectorClick.bind(this, director)
                    })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {

        let target = sector || abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: target.id
            },{
                key: 'name',
                title: 'Name',
                value: target.name
            },{
                key: 'status',
                title: 'Status',
                value: target.active ? 'Active' : 'Not Active'
            }]
        }];
        return items;
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'sectors', 'on_update_sector', onUpdateSector);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'sectors', 'on_update_sector', onUpdateSector);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDirector = async () => {
        try {
            if(abstract.object.director) {
                setDirector(abstract.object.director);
                return;
            }
            let director = await User.get(utils, abstract.object.director_user_id);
            setDirector(director);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for the director. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        connectToSockets();
        fetchDirector();
        return disconnectFromSockets;
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.getTitle()} Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getDirector()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <SystemEventsList
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}
