import React, { useEffect, useState } from 'react';

import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import PageControl from 'views/PageControl.js';

const MultipleListField = ({ icon, items, onChange, placeholder, rightContent, style, utils, value }) => {

    const limit = 5
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [values, setValues] = useState(value || []);

    const onChangeItem = item => {
        let next = update(values, { $push: [item] });
        setValues(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveItemClick = (index, evt) => {
        evt.stopPropagation();
        let next = update(values, { $splice: [[ index, 1 ]] });
        setValues(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    useEffect(() => {
        setPaging(values && {
            total: values.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: values.length > limit ? Math.ceil(values.length / limit) : 1
        })
    }, [offset, values]);
    
    useEffect(() => {
        setValues(value || []);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <ListField
                icon={icon}
                items={items}
                onChange={onChangeItem}
                placeholder={placeholder || 'Choose an item from the list...'}
                preserveResult={false}
                utils={utils} />
                {rightContent}
            </div>
            {values.length > 0 && (
                <div 
                className={'custom-scrollbars'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {values.filter((_, index) => {
                        if(!paging) {
                            return true;
                        }
                        return index >= offset && index < (offset + limit);
                    }).map((value, index, values) => {
                        return (
                            <div
                            key={index}
                            style={{
                                alignItems: 'center',
                                borderBottom: index !== values.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '6px 10px 6px 10px',
                                width: '100%',
                            }}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                flexGrow: 1,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{value.title}</span>
                                <img
                                className={'text-button'}
                                name={'Remove Item'}
                                onClick={onRemoveItemClick.bind(this, index)}
                                src={'images/red-x-icon.png'}
                                style={{
                                    height: 20,
                                    objectFit: 'contain',
                                    marginLeft: 8,
                                    width: 20
                                }} />
                            </div>
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={setOffset} />
                    )}
                </div>
            )}
        </div>
    )
}

export default MultipleListField;
