import React, { useEffect, useRef, useState } from 'react';

import lz from 'lzutf8';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const WorkflowLandingTemplateLookupField = ({ containerStyle, inline = true, insetLabel, onChange, onRenderResult, placeholder, preserveResult, showValidation, utils, value }) => {

    const containerRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offsetY, setOffsetY] = useState(0);
    const [results, setResults] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selected, setSelected] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onArrowClick = () => {
        setExpanded(!expanded);
    }

    const onRemoveSelectedTemplate = () => {
        setText('');
        setSelected(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onResultClick = template => {

        // hide results list, update selected template, and update text field label
        setResults([]);
        setSelected(template);
        setExpanded(false);
        setText(template.title);

        // remove result text from text field and update selected value if applicable
        if(preserveResult === false) {
            setText('');
            setSelected(null);
        }

        // send selected template back to subscribers
        if(typeof(onChange) === 'function') {
            onChange({
                ...template,
                content: {
                    ...template.content,
                    layout: lz.decompress(lz.decodeBase64(template.content.layout))
                }
            });
        }
    }

    const onTextChange = text => {

        // clear pending search text timeout if applicable
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        // update textfield label, remove previously selected result, and begin request loading
        setText(text);
        setLoading(true);
        setSelected(null);
        setExpanded(false);

        // prepare next search text timeout
        setTextTimeout(async () => {
            try {

                // prevent moving forward if search text length is less than three characters
                await Utils.sleep(0.25);
                if(!text || (isNaN(text) && text.length < 3)) {
                    setResults([]);
                    setLoading(false);
                    return;
                }

                // send auto complete request to server
                let { templates } = await Request.get(utils, '/support/', {
                    type: 'workflow_landing_templates',
                    limit: 5,
                    search_text: text
                });
                setLoading(false);
                setResults(templates);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
                });
            }

        });
    }

    const onValidate = () => {
        return selected ? true : false
    }

    const getAppendContent = () => {
        if((text && text.length > 0) || templates.length === 0 && selected === null) {
            return null;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                right: 12,
                height: '100%',
                paddingLeft: 30,
                background: `linear-gradient(90deg, ${Appearance.colors.textField(0)}, ${Appearance.colors.textField(0.9)}, ${Appearance.colors.textField()})`
            }}>
                {templates.length > 0 && (
                    <img
                    className={'cursor-pointer'}
                    onClick={onArrowClick}
                    src={expanded ? 'images/up-arrow-grey-small.png' : 'images/down-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }} />
                )}
                {selected && Views.icon.remove({ onClick: onRemoveSelectedTemplate })}
            </div>
        )
    }

    const getContent = () => {
        if(results.length === 0 && (expanded === false || templates.length === 0)) {
            return null;
        }
        let targets = expanded === true ? templates : results;
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8,
                ...insetLabel && {
                    borderRadius: 8
                }
            }}>
                {targets.map((template, index) => {
                    if(typeof(onRenderResult) === 'function') {
                        return onRenderResult(template, index, results);
                    }
                    return (
                        Views.entry({
                            key: index,
                            title: template.title,
                            //subTitle: getSubTitle(template),
                            bottomBorder: index !== results.length - 1,
                            hideIcon: true,
                            onClick: onResultClick.bind(this, template)
                        })
                    )
                })}
            </div>
        )
    }

    const fetchTemplates = async () => {
        try {
            setLoading(true);
            let { templates } = await Request.get(utils, '/support/', {
                type: 'workflow_landing_templates',
                limit: 100
            });
            setLoading(false);
            setTemplates(templates);
        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    useEffect(() => {
        setSelected(value);
        setText(value ? value.getDescription().title : null);
    }, [value]);

    useEffect(() => {

        fetchTemplates();
        let id = `workflow_landing_lookup_field-${moment().unix()}`;
        utils.content.subscribe(id, ['workflow_landing_templates'], {
            onFetch: fetchTemplates
        });
        return () => {
            utils.content.unsubscribe(id);
        }
    }, []);

    return (
        <div
        ref={containerRef}
        style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <TextField
                appendComponent={getAppendContent()}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                loading={loading}
                onChange={onTextChange}
                onValidate={showValidation ? onValidate : null}
                placeholder={placeholder || 'Search by template id or name...'}
                useDelay={true}
                value={text}
                containerStyle={{
                    flexGrow: 1
                }}/>
            </div>
            {getContent()}
        </div>
    )
}
export default WorkflowLandingTemplateLookupField;
