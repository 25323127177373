import React, { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Utils from 'files/Utils.js';

const Alert = ({ id, buttons, content, icon, index, message, onCancel, onClick, onClose, textFields, title, utils }) => {

    const [alertButtons, setAlertButtons] = useState([]);
    const [alertTextFields, setAlertTextFields] = useState(false);
    const [animations, animationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 22 },
        opacity: 0,
        transform: 'scale(0)'
    }));
    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    const onButtonClick = key => {
        onHideAlert(() => {
            if(alertTextFields) {
                onParseTextFields(key);
                return;
            }
            if(key === 'cancel') {
                if(typeof(onCancel) === 'function') {
                    onCancel();
                    return;
                }
            }
            if(typeof(onClick) === 'function') {
                onClick(key);
            }
        });
    }

    const onCloseAlertEvent = ({ detail }) => {
        console.log(detail);
        if(detail.id === id) {
            onHideAlert();
        }
    }

    const onGeneralKeyDown = evt => {
        if(evt.keyCode === 27) {
            onHideAlert();
        }
    }

    const onTextFieldKeyDown = evt => {
        if(evt.keyCode === 13) {
            evt.preventDefault();
            onHideAlert(() => {
                onParseTextFields()
            });
        }
    }

    const onHideAlert = callback => {
        animationsApi.start({
            to: async next => {

                // animate components out of view
                await next({
                    opacity: 0,
                    transform: 'scale(0)'
                });

                // hide alert and notify subscribers
                setVisible(false);
                if(typeof(onClose) === 'function') {
                    onClose(id);
                }
                if(typeof(callback) === 'function') {
                    setTimeout(callback, 250);
                }
            }
        });
    }

    const onParseTextFields = key => {
        if(typeof(onClose) === 'function') {
            onClose(id);
        }
        if(key === 'cancel') {
            if(typeof(onCancel) === 'function') {
                onCancel();
            }
            return;
        }
        if(typeof(onClick) === 'function') {
            let textFieldValues = {};
            alertTextFields.forEach(t => {
                textFieldValues[t.field.key] = t.value || t.field.value;
            })
            onClick({
                ...textFieldValues,
                key: key
            });
        }
    }

    const onWindowSizeChange = (e) => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const getWidth = () => {
        if(content) {
            return size.width > 400 ? 400 : (size.width - 30);
        }
        if(alertTextFields && alertTextFields.length > 0) {
            return size.width > 400 ? 400 : (size.width - 30);
        }
        return size.width > 325 ? 325 : (size.width - 30);
    }

    useEffect(() => {
        setAlertTextFields(textFields ? textFields.map(t => {
            return {
                value: null,
                field: t
            }
        }) : null);
    }, [textFields]);

    useEffect(() => {
        setVisible(true);
        setTimeout(() => {
            animationsApi.start({
                opacity: index === 0 ? 1 : 0,
                transform: `scale(${index > 3 ? 0 : 1 - (index / 4)})`
            });
        }, 250)
    }, [index]);

    useEffect(() => {
        // add keydown listener if textfields are present
        // this allows the user to press enter to confirm the data from the textfield
        if(alertTextFields) {
            document.addEventListener('keydown', onTextFieldKeyDown);
            return () => {
                document.removeEventListener('keydown', onTextFieldKeyDown);
            }
        }
    }, [alertTextFields]);

    useEffect(() => {

        // add at least one buttons if no buttons are provided
        let next_buttons = buttons || [{
            key: 'cancel',
            title: 'Okay',
            style: 'cancel'
        }];
        setAlertButtons(next_buttons.filter(button => {
            return button.visible !== false;
        }));

        // subscribe to event listener
        utils.events.on(`alert-${id}`, 'close_alert', onCloseAlertEvent);

        // add resize listener
        // add keydown listener for escape key to close alert if there is a dismiss/cancel button
        window.addEventListener('resize', onWindowSizeChange);
        if(next_buttons.find(button => button.key === 'cancel')) {
            document.addEventListener('keydown', onGeneralKeyDown);
        }

        return () => {
            utils.events.off(`alert-${id}`, 'close_alert');
            window.removeEventListener('resize', onWindowSizeChange);
            document.removeEventListener('keydown', onGeneralKeyDown);
        }
    }, []);

    return visible ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <animated.div style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.alert(),
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: size.height - 30,
                overflow: 'hidden',
                textAlign: 'center',
                width: getWidth(),
                zIndex: 9999,
                ...animations
            }}>
                {icon && typeof(icon) === 'object' && (
                    <div style={{
                        marginTop: 15,
                        borderRadius: 25,
                        backgroundColor: icon.background_color || Appearance.colors.primary(),
                        overflow: 'hidden',
                        width: 50,
                        height: 50,
                        border: '3px solid white',
                        boxShadow: window.theme === 'dark' ? '3px 3px 15px rgba(0,0,0,0.25), -3px -3px 15px rgba(255,255,255,0.25)' : '3px 3px 15px #c9c8ca, -3px -3px 15px #ffffff',
                        ...icon.style
                    }}>
                        <img
                        src={icon.path}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'cover'
                        }}/>
                    </div>
                )}
                <span style={{
                    display: 'block',
                    color: Appearance.colors.text(),
                    fontSize: 18,
                    fontWeight: '800',
                    lineHeight: 1.5,
                    marginBottom: 8,
                    paddingTop: icon ? 8:15,
                    paddingLeft: 15,
                    paddingRight: 15,
                }}>{title}</span>

                <div style={{
                    marginBottom: 24,
                    maxHeight: 500,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    maxWidth: '100%'
                }}>
                    <span style={{
                        display: 'block',
                        maxWidth: '100%',
                        color: Appearance.colors.subText(),
                        fontSize: 13,
                        fontWeight: '500',
                        paddingLeft: 15,
                        paddingRight: 15,
                        wordWrap: 'break-word'
                    }}>{message}</span>
                </div>

                {content}
                {alertTextFields && (
                    <div style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        width: '100%',
                        marginBottom: 15
                    }}>
                        {alertTextFields.map((item, index) => {
                            if(item.field.type === 'address_lookup') {
                                return (
                                    <AddressLookupField
                                    key={index}
                                    utils={utils}
                                    useDelay={false}
                                    geocode={true}
                                    onChange={place => {
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: place
                                                }
                                            }
                                        }))
                                    }} />
                                )
                            }
                            if(item.field.type === 'date_picker') {
                                return (
                                    <DateDurationPickerField
                                    key={index}
                                    utils={utils}
                                    overrideAlerts={true}
                                    placeholder={'Choose a date...'}
                                    onChange={date => {
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: date
                                                }
                                            }
                                        }))
                                    }} />
                                )
                            }
                            if(item.field.type === 'list') {
                                return (
                                    <select
                                    key={index}
                                    className={`custom-select ${window.theme}`}
                                    onChange={e => {
                                        let id = Utils.attributeForKey.select(e, 'id');
                                        let selected = item.field.items.find(prevItem => prevItem.key === parseInt(id));
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: selected
                                                }
                                            }
                                        }))
                                    }}>
                                        <option>{'Choose from the list below'}</option>
                                        {item.field.items.map((item, index) => {
                                            return (
                                                <option key={index} id={item.key}>{item.title}</option>
                                            )
                                        })}
                                    </select>
                                )
                            }
                            if(item.field.type === 'textview') {
                                return (
                                    <TextView
                                    key={index}
                                    value={item.field.value}
                                    placeholder={item.field.placeholder}
                                    onChange={text => {
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: text
                                                }
                                            }
                                        }))
                                    }} />
                                )
                            }
                            return (
                                <TextField
                                key={index}
                                value={item.field.value}
                                useDelay={false}
                                isSecure={item.field.secure}
                                placeholder={item.field.placeholder}
                                fieldStyle={{
                                    textAlign: 'center'
                                }}
                                onChange={text => {
                                    setAlertTextFields(alertTextFields => update(alertTextFields, {
                                        [index]: {
                                            value: {
                                                $set: text
                                            }
                                        }
                                    }))
                                }} />
                            )
                        })}
                    </div>
                )}

                {alertButtons.map(item => {
                    return (
                        <div
                        key={item.key}
                        className={`alert-item ${window.theme}`}
                        onClick={onButtonClick.bind(this, item.key)}
                        style={{
                            height: 50,
                            width: '100%'
                        }}>
                            <div style={{
                                height: 50
                            }}>
                                <div style={{
                                    backgroundColor: Appearance.colors.divider(),
                                    height: 1,
                                    width: '100%'
                                }}/>
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: 50
                                }}>
                                    <span style={{
                                        color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary(),
                                        fontSize: 13,
                                        fontWeight: '400',
                                        textAlign:'center'
                                    }}>{item.title}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </animated.div>
        </div>
    ) : null
}

export default Alert;
