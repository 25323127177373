import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import { CollapseArrow } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';
import Utils from 'files/Utils.js';

const AddressLookupField = ({ appendContent, containerStyle, icon, onChange, placeholder, preserveResult, shouldShowAddLocation, shouldShowCustomFields, showValidation, utils, value }) => {

    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [session, setSession] = useState(null);
    const [showCustomFields, setShowCustomFields] = useState(false);
    const [text, setText] = useState('');
    const [timezoneCategory, setTimezoneCategory] = useState('usa');

    const onAddCustomLocation = () => {

        // clear results and prepare an empty object for the custom location
        setLocation(null);
        setResults([]);
        setSelected({});

        // set custom fields flag to true
        setShowCustomFields(true);

        // determine timezone category for field components
        let timezone = moment.tz.guess();
        if(timezone.includes('Australia')) {
            setTimezoneCategory('australia');
        }
        if(timezone.includes('Canada')) {
            setTimezoneCategory('canada');
        }
        if(timezone.includes('Australia')) {
            setTimezoneCategory('australia');
        }
    }

    const onClick = async place => {

        // clear results, set selected place, and update text input value
        setLocation(null);
        setResults([]);
        setSelected(place);

        // update label text accordingly
        setText(preserveResult === false ? null : place.address);

        // refresh session for next search
        setSession(Utils.randomString());

        // geocode address string into address components
        try {

            // send request to server
            setLoading(true);
            let result = await Utils.geocode(utils, place);

            // update state value for location
            setLocation(result && result.location);

            // prepare selected object result and update local state
            let next = { 
                ...place, 
                ...result,
                name: place.name || result.name 
            };
            setSelected(next);

            // notify subscribers of data change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange(next);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the information for this address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCustomInputChange = (key, text) => {

        // declare next object with updated text for key
        let next = {
            ...selected,
            location,
            [key]: text
        };

        // remove location coordinate if any value other than street_address_2 is changed
        // it is assumed that other changes will almost certainly change the physical location of the place
        if(key !== 'street_address_2') {
            next.location = null;
        }

        // update state and notify subscribers
        setSelected(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onPrimaryFieldChange = async text => {
        try {

            // prevent moving forward with search request if custom fields flag is enabled
            if(showCustomFields === true) {
                onCustomInputChange('street_address_1', text);
                return;
            }

            // update text field with new value, start loading, and remove previously selected location/place if applicable
            setLoading(true);
            setLocation(null);
            setSelected(null);
            setText(text);

            // send request to server
            let { results } = await Utils.addressLookup(utils, text, { session });
            setLoading(false);

            // update state with results and provide an option to create a custom location
            setLocation(null);
            if(shouldShowAddLocation === false) {
                setResults(results);
            } else {
                setResults([{ id: 'custom_location' }].concat(results));
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue searching for your location. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onValidate = () => {
        return selected ? true : false
    }

    const getAppendContent = () => {
        return (
            <>
            {appendContent}
            {shouldShowCustomFields !== false && (
                <CollapseArrow
                collapsed={!showCustomFields}
                onClick={() => setShowCustomFields(val => !val)}
                style={{
                    marginLeft: 8
                }}/>
            )}
            </>
        )
    }

    const getCustomAddressComponents = () => {
        return showCustomFields && (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                {getFieldComponentProps('street_address_2')}
                {getFieldComponentProps('locality')}
                {getFieldComponentProps('administrative_area_level_1')}
                {getFieldComponentProps('postal_code')}
                {getFieldComponentProps('country')}
            </div>
        )
    }

    const getFieldComponentProps = key => {

        // declare base props for field
        let props = {
            onChange: text => onCustomInputChange(key, text),
            placeholder: getPlaceholder(key),
            value: selected && selected[key],
            containerStyle: {
                marginTop: 8
            }
        }

        // determine if a list of states should be provided for american users
        if(timezoneCategory === 'usa' && key === 'administrative_area_level_1') {
            return (
                <ListField
                {...props}
                items={getStates()}
                onChange={item => onCustomInputChange(key, item && item.id)}
                placeholder={getPlaceholder(key)}
                style={{ marginTop: 8 }}
                utils={utils}
                value={getSelectedState()}/>
            )
        }

        // fallback to a text field for all other address components
        return (
            <TextField 
            {...props} 
            useDelay={true} />
        )
    }

    const getPlaceholder = key => {

        // determine if a custom placeholder needs to be returned based on the region
        switch(key) {
            case 'administrative_area_level_1':
            switch(timezoneCategory) {
                case 'canada':
                return 'Province';

                case 'usa':
                return 'State';
            }
            break;

            case 'locality':
            switch(timezoneCategory) {
                case 'canada':
                return 'Municipality';

                case 'usa':
                return 'City';
            }
            break;

            case 'postal_code':
            switch(timezoneCategory) {
                case 'canada':
                return 'Postal Code';

                case 'usa':
                return 'Zipcode';
            }
            break;
        }

        // loop through generic keys that do not change based on the region
        switch(key) {
            case 'country':
            return 'Country';

            case 'street_address_1':
            return 'Street Address';

            case 'street_address_2':
            return 'Apartment, Office, or Suite';

            default:
            return 'Unknown address component';
        }
    }

    const getPrimaryFieldProps = () => {
        if(showCustomFields === false) {
            return {
                icon: icon || 'house',
                placeholder,
                value: text,
            };
        }
        return {
            placeholder: 'Street Address',
            value: selected && selected.street_address_1 || ''
        };
    }

    const getSelectedState = () => {
        if(!selected || !selected.administrative_area_level_1) {
            return null;
        }
        let state = getStates().find(state => state.id === selected.administrative_area_level_1);
        return state && state.title;
    }

    const getStates = () => {
        return [{
            title: 'Alabama',
            id: 'AL'
        },{
            title: 'Alaska',
            id: 'AK'
        },{
            title: 'American Samoa',
            id: 'AS'
        },{
            title: 'Arizona',
            id: 'AZ'
        },{
            title: 'Arkansas',
            id: 'AR'
        },{
            title: 'California',
            id: 'CA'
        },{
            title: 'Colorado',
            id: 'CO'
        },{
            title: 'Connecticut',
            id: 'CT'
        },{
            title: 'Delaware',
            id: 'DE'
        },{
            title: 'District Of Columbia',
            id: 'DC'
        },{
            title: 'Federated States Of Micronesia',
            id: 'FM'
        },{
            title: 'Florida',
            id: 'FL'
        },{
            title: 'Georgia',
            id: 'GA'
        },{
            title: 'Guam',
            id: 'GU'
        },{
            title: 'Hawaii',
            id: 'HI'
        },{
            title: 'Idaho',
            id: 'ID'
        },{
            title: 'Illinois',
            id: 'IL'
        },{
            title: 'Indiana',
            id: 'IN'
        },{
            title: 'Iowa',
            id: 'IA'
        },{
            title: 'Kansas',
            id: 'KS',
        },{
            title: 'Kentucky',
            id: 'KY'
        },{
            title: 'Louisiana',
            id: 'LA'
        },{
            title: 'Maine',
            id: 'ME'
        },{
            title: 'Marshall Islands',
            id: 'MH'
        },{
            title: 'Maryland',
            id: 'MD'
        },{
            title: 'Massachusetts',
            id: 'MA'
        },{
            title: 'Michigan',
            id: 'MI'
        },{
            title: 'Minnesota',
            id: 'MN'
        },{
            title: 'Mississippi',
            id: 'MS'
        },{
            title: 'Missouri',
            id: 'MO'
        },{
            title: 'Montana',
            id: 'MT'
        },{
            title: 'Nebraska',
            id: 'NE'
        },{
            title: 'Nevada',
            id: 'NV'
        },{
            title: 'New Hampshire',
            id: 'NH'
        },{
            title: 'New Jersey',
            id: 'NJ'
        },{
            title: 'New Mexico',
            id: 'NM'
        },{
            title: 'New York',
            id: 'NY'
        },{
            title: 'North Carolina',
            id: 'NC'
        },{
            title: 'North Dakota',
            id: 'ND'
        },{
            title: 'Northern Mariana Islands',
            id: 'MP'
        },{
            title: 'Ohio',
            id: 'OH'
        },{
            title: 'Oklahoma',
            id: 'OK'
        },{
            title: 'Oregon',
            id: 'OR'
        },{
            title: 'Palau',
            id: 'PW'
        },{
            title: 'Pennsylvania',
            id: 'PA'
        },{
            title: 'Puerto Rico',
            id: 'PR'
        },{
            title: 'Rhode Island',
            id: 'RI'
        },{
            title: 'South Carolina',
            id: 'SC'
        },{
            title: 'South Dakota',
            id: 'SD'
        },{
            title: 'Tennessee',
            id: 'TN'
        },{
            title: 'Texas',
            id: 'TX'
        },{
            title: 'Utah',
            id: 'UT'
        },{
            title: 'Vermont',
            id: 'VT'
        },{
            title: 'Virgin Islands',
            id: 'VI'
        },{
            title: 'Virginia',
            id: 'VA'
        },{
            title: 'Washington',
            id: 'WA'
        },{
            title: 'West Virginia',
            id: 'WV'
        },{
            title: 'Wisconsin',
            id: 'WI'
        },{
            title: 'Wyoming',
            id: 'WY'
        }];
    }

    const onUpdateSelectedValue = () => {
        try {

            // update local state for location coordinate and place address
            setLocation(value && value.location);
            setSelected(value && value.address);
            
            // update label text accordingly
            setText(preserveResult === false ? null : (value && Utils.formatAddress(value.address)));
            
        } catch(e) {
            console.error(`[address-lookup-field]: ${e.message}`);
        }
    }

    useEffect(() => {
        if(showCustomFields) {
            setResults([]);
        }
    }, [showCustomFields]);

    useEffect(() => {
        onUpdateSelectedValue();
    }, [value]);

    useEffect(() => {
        setSession(Utils.randomString());
    }, []);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            {...getPrimaryFieldProps()}
            appendContent={getAppendContent()}
            autoCapitalize={'sentences'}
            autoComplete={false}
            autoCorrect={false}
            loading={loading}
            onChange={onPrimaryFieldChange}
            onValidate={showValidation ? onValidate : null}
            useDelay={true}
            value={text}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }} />
            {getCustomAddressComponents()}
            {results.length > 0 && (
                <div style={{
                    backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                    borderColor: Appearance.colors.softBorder(),
                    borderRadius: 12,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    marginBottom: 12,
                    marginTop: 8,
                    overflow: 'hidden',
                    padding: 0,
                    textAlign: 'left'
                }}>
                    {results.map((location, index) => {
                        if(location.id === 'custom_location') {
                            return (
                                Views.entry({
                                    bottomBorder: index !== results.length - 1,
                                    hideIcon: true,
                                    key: index,
                                    onClick: onAddCustomLocation,
                                    style: { padding: '6px 12px 6px 12px' },
                                    subTitle: 'Click to manually add a new location...',
                                    title: 'Add a Custom Location',
                                    style: {
                                        title: {
                                            color: Appearance.colors.primary()
                                        }
                                    }
                                })
                            )
                        }
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onClick.bind(this, location),
                                style: { padding: '6px 12px 6px 12px' },
                                subTitle: location.address,
                                title: location.name
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default AddressLookupField;
