import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import FieldMapper from 'views/FieldMapper';
import Layer, { LayerItem } from 'structure/Layer';
import { Line } from 'react-chartjs-2';
import LottieView from 'views/Lottie.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils';

// Panels
export const AWSMetrics = ({ index, options, utils }) => {

    const panelID = 'aws_metrics';
    const [loading, setLoading] = useState(null);
    const [metrics, setMetrics] = useState([]);
    const [dates, setDates] = useState({ end: Utils.conformDate(moment.utc(), 5), start: Utils.conformDate(moment.utc().subtract(6, 'hours'), 5) });

    const onSelectDates = () => {
        utils.datePicker.showDual({
            endDate: dates.end,
            startDate: dates.start,
            onDateChange: setDates
        });
    }

    const getButtons = () => {
        return [{
            key: 'dates',
            onClick: onSelectDates,
            style: 'primary',
            title: 'Change Dates'
        }];
    }

    const getContent = () => {

        // return loader if no metrics have been fetched
        if(loading === true && metrics.length === 0) {
            return (
                <Panel
                index={index}
                name={'Server and Infrastructure Metrics'}
                panelID={panelID}
                utils={utils}
                options={options}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 100,
                        width: '100%'
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            height: 40,
                            width: 40
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            marginTop: 12
                        }}>{'Retrieving Metrics'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{'It may take a moment to fetch all the performance data from AWS'}</span>
                    </div>
                </Panel>
            )
        }

        // prevent moving forward if no metrics are returned, this can be a secruity group issues with the requesting ip
        if(metrics.length === 0) {
            return (
                <Panel
                index={index}
                name={'Server and Infrastructure Metrics'}
                panelID={panelID}
                utils={utils}
                options={options}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 100,
                        width: '100%'
                    }}>
                        <img
                        src={'images/red-x-icon.png'}
                        style={{
                            height: 40,
                            width: 40
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            marginTop: 12
                        }}>{'Metrics Not Available'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{'Performance metrics for the Graci server stack are not available at this time. Please check back later.'}</span>
                    </div>
                </Panel>
            )
        }

        // loop through metrics and render a panel for each entry
        return metrics.map((metric, index) => {
            return (
                <Panel
                column={'col-12 col-lg-6'}
                index={index}
                name={metric.name}
                panelID={panelID}
                utils={utils}
                options={{
                    ...options,
                    buttons: getButtons(),
                    loading: loading,
                    subTitle: `${dates.start.local().format('MMM Do [at] h:mma')} to ${dates.end.local().format('MMM Do [at] h:mma')}`
                }}>
                    {getGraphContent(metric)}
                </Panel>
            )
        })
    }

    const getGraphContent = metric => {

        // determine if at least one valid dataset was returned
        let hasValidDataset = metric.datasets.find(dataset => dataset.data.length > 1) ? true : false;

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
                width: '100%'
            }}>
                <div style={{
                    height: '100%',
                    opacity: hasValidDataset ? 1 : 0,
                    width: '100%'
                }}>
                    <Line
                    data={{
                        labels: metric.labels.map(date => moment.utc(date).local().format('h:mma')),
                        datasets: metric.datasets.map(dataset => ({
                            borderColor: dataset.color,
                            borderWidth: 2,
                            data: dataset.data,
                            fill: false,
                            label: dataset.key,
                            pointBackgroundColor: '#FFFFFF',
                            pointBorderColor: dataset.color,
                            pointBorderWidth: 2,
                            pointRadius: 2,
                            pointStyle: 'circle'
                        }))
                    }}
                    height={150}
                    key={index}
                    width={400}
                    options={{
                        legend: { display: false },
                        maintainAspectRatio: true,
                        responsive: true,
                        title: { display: false },
                        tooltips: {
                            enabled: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)',
                                    display: true,
                                    zeroLineColor: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)'
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)',
                                    display: true,
                                    zeroLineColor: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)'
                                },
                                ticks: {
                                    beginAtZero: true,
                                }
                            }]
                        }
                    }} />
                </div>
                {hasValidDataset === false && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%'
                    }}>
                        <img
                        src={'images/graph-icon-light-grey.png'}
                        style={{
                            height: 50,
                            width: 50
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            marginTop: 8
                        }}>{'No Information Available'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{'There is not enough data to prepare a graph for this metric'}</span>
                    </div>
                )}
            </div>
        )
    }

    const fetchMetrics = async () => {
        try {
            setLoading(true);
            let { metrics } = await Request.get(utils, '/resources/', {
                end_date: dates.end.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
                start_date: dates.start.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
                type: 'load_balancer_metrics'
            });

            setLoading(false);
            setMetrics(metrics);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the server metrics data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
       fetchMetrics();
    }, [dates]);

    return getContent();
}

export const OmniShieldSensorEvents = ({ index, options, utils }) => {

    const panelID = 'omnishield_sensor_events';

    const [dates, setDates] = useState({ end: Utils.conformDate(moment.utc(), 5), start: Utils.conformDate(moment.utc().subtract(24, 'hours'), 5) });
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState([]);
    
    const onSelectDates = () => {
        utils.datePicker.showDual({
            endDate: dates.end,
            startDate: dates.start,
            onDateChange: setDates
        });
    }

    const getButtons = () => {
        return [{
            key: 'dates',
            onClick: onSelectDates,
            style: 'primary',
            title: 'Change Dates'
        }];
    }

    const getContent = () => {

        // return loader if no metrics have been fetched
        if(loading === true && metrics.length === 0) {
            return null;
        }

        // loop through metrics and render a panel for each entry
        return metrics.map((metric, index) => {
            return (
                <Panel
                column={'col-12 col-lg-6'}
                index={index}
                key={index}
                name={metric.name}
                panelID={panelID}
                utils={utils}
                options={{
                    ...options,
                    buttons: getButtons(),
                    loading: loading,
                    subTitle: `${dates.start.local().format('MMM Do [at] h:mma')} to ${dates.end.local().format('MMM Do [at] h:mma')}`
                }}>
                    {getGraphContent(metric)}
                </Panel>
            )
        })
    }

    const getGraphContent = metric => {

        // determine if at least one valid dataset was returned
        let hasValidDataset = metric.datasets.find(dataset => dataset.data.length > 1) ? true : false;

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
                width: '100%'
            }}>
                <div style={{
                    height: '100%',
                    opacity: hasValidDataset ? 1 : 0,
                    width: '100%'
                }}>
                    <Line
                    data={{
                        labels: metric.labels.map(date => moment.utc(date).local().format('h:mma')),
                        datasets: metric.datasets.map(dataset => ({
                            borderColor: dataset.color,
                            borderWidth: 2,
                            data: dataset.data,
                            fill: false,
                            label: dataset.key,
                            pointBackgroundColor: '#FFFFFF',
                            pointBorderColor: dataset.color,
                            pointBorderWidth: 2,
                            pointRadius: 2,
                            pointStyle: 'circle'
                        }))
                    }}
                    height={150}
                    key={index}
                    width={400}
                    options={{
                        legend: { display: false },
                        maintainAspectRatio: true,
                        responsive: true,
                        title: { display: false },
                        tooltips: {
                            enabled: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)',
                                    display: true,
                                    zeroLineColor: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)'
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)',
                                    display: true,
                                    zeroLineColor: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(235,235,235,1)'
                                },
                                ticks: {
                                    beginAtZero: true,
                                }
                            }]
                        }
                    }} />
                </div>
                {hasValidDataset === false && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%'
                    }}>
                        <img
                        src={'images/graph-icon-light-grey.png'}
                        style={{
                            height: 50,
                            width: 50
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            marginTop: 8
                        }}>{'No Information Available'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{'There is not enough data to prepare a graph for this metric'}</span>
                    </div>
                )}
            </div>
        )
    }

    const fetchMetrics = async () => {
        try {
            setLoading(true);
            let { metrics } = await Request.homesafealerts.get(utils, '/health/', {
                end_date: dates.end.utc().unix(),
                start_date: dates.start.utc().unix(),
                type: 'omnishield_system_metrics'
            });

            setLoading(false);
            setMetrics(metrics);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the omnishield metrics data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
       fetchMetrics();
    }, [dates]);

    return getContent()
}

export const OmniShieldSystemStatus = ({ index, options, utils }) => {

    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    
    const getContent = () => {
        let isLoading = loading === true && stats.length === 0 ? true : false;
        if(isLoading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    marginTop: 12,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginTop: 12
                    }}>{'Retrieving System Status'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{'It may take a moment to fetch the system status overview...'}</span>
                </div>
            )
        }

        return (
            <div 
            className={'row m-0'}
            style={{
                padding: 8,
                width: '100%'
            }}>
                {stats.map((entry, index) => {
                    let healthy = entry.status_code < 300 ? true : false;
                    return (
                        <div 
                        key={index}
                        className={'col-12 col-lg-4 col-xl-2 p-2'}>
                            <div 
                            className={'p-3'}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.panelBackground(),
                                borderRadius: 12,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                minWidth: 0,
                                width: '100%'
                            }}>
                                <img 
                                src={healthy ? 'images/online-icon.png' : 'images/offline-icon.png'}
                                style={{
                                    height: 60,
                                    marginBottom: 8,
                                    objectFit: 'contain',
                                    width: 60
                                }} />
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    maxWidth: '100%'
                                }}>{entry.name}</span>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: healthy ? Appearance.colors.green : Appearance.colors.red,
                                    maxWidth: '100%'
                                }}>{healthy ? 'Online' : 'Offline'}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const fetchStatus = async () => {
        try {
            setLoading(true);
            let { status } = await Request.homesafealerts.get(utils, '/health/', {
                type: 'omnishield_system_status'
            });

            // prepare array of entries using status object
            let results = Object.keys(status).map(key => ({
                ...status[key],
                key: key
            }))
       
            // end loading and update stats state
            setLoading(false);
            setStats(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the omnishield system status data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <Panel
        index={index}
        name={'API Service Status'}
        panelID={'system_stats'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true
        }}>
            {getContent()}
        </Panel>
    )
}

export const ExceptionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `exception_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState(null);

    const onMarkAsResolved = () => {
        utils.alert.show({
            title: 'Mark as Resolved',
            message: 'Are you sure that you want to mark this exception as resolved? This exception will no longer be available for viewing after it is resolved.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Resolve',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onMarkAsResolvedConfirm();
                    return;
                }
            }
        });
    }

    const onMarkAsResolvedConfirm = async () => {
        try {
             setLoading(true);
             await Utils.sleep(0.5);
             await Request.post(utils, '/resources/', {
                active: false,
                id: abstract.getID(),
                type: 'set_omnishield_exception_active_status',
             });

             setLoading(false);
             utils.content.fetch('exceptions');
             utils.alert.show({
                title: 'All Done!',
                message: 'This exception has been marked as resolved.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue marking this exception as resolved. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primiary',
            key: 'active',
            onClick: onMarkAsResolved,
            text: 'Mark as Resolved'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'action',
                title: 'Action',
                value: abstract.object.action
            },{
                color: Appearance.colors.red,
                key: 'code',
                title: 'HTTP Status Code',
                value: abstract.object.code
            },{
                key: 'comm_link_serial_number',
                title: 'Comm Link Serial Number',
                value: abstract.object.comm_link_serial_number,
                visible: abstract.object.comm_link_serial_number ? true : false
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(moment.utc(abstract.object.date).local())
            },{
                key: 'id',
                title: 'ID',
                value: abstract.object.id
            },{
                key: 'namespace',
                title: 'Namespace',
                value: getNamespace()
            }]
        }];
    }

    const getMessage = () => {
        return abstract.object.data && (
            <LayerItem 
            collapsed={false}
            title={'Message'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        whiteSpace: 'normal'
                    }}>{abstract.object.data.message}</span>
                </div>
            </LayerItem>
        )
    }

    const getNamespace = () => {
        let namespace = abstract.object.namespace.replace('_', ' ');
        namespace = namespace.split(' ');
        return namespace.map(name => Utils.ucFirst(name)).join(' ');
    }

    const getPayload = () => {
        return (
            <LayerItem 
            collapsed={false}
            title={'Payload'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        whiteSpace: 'normal'
                    }}>{payload}</span>
                </div>
            </LayerItem>
        )
    }

    const getTrace = () => {
        return abstract.object.data && (
            <LayerItem 
            collapsed={false}
            title={'System Trace'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        whiteSpace: 'normal'
                    }}>{abstract.object.data.trace}</span>
                </div>
            </LayerItem>
        )
    }

    useEffect(() => {
        setPayload(abstract.object.data && JSON.stringify(abstract.object.data.payload, null, 2));
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for Exception ${abstract.getID()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading
        }}>

            {getMessage()}
            {getPayload()}
            {getTrace()}
            
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}
