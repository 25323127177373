import React, { useEffect, useState } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { LayerItem } from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import ProgressBar from 'views/ProgressBar.js';
import Request from 'files/Request.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails, getSystemEventBadges, getSystemEventProps } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const SystemEventsList = ({ abstract, limit = 5, utils }) => {

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        })
    }

    const onUpdateSystemEvent = data => {
        try {
            setEvents(events => events.map(evt => {
                return evt.id === data.evt.id ? SystemEvent.create(data.evt) : evt;
            }))

        } catch(e) {
            console.log(e.message);
        }
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'System Events'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    position: 'relative'
                }}>
                    {loading && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: 2,
                            overflow: 'hidden',
                            borderRadius: 2,
                        }}>
                            <ProgressBar/>
                        </div>
                    )}
                    {events.map((evt, index) => {
                        let { values } = getSystemEventProps(evt);
                        if(!values) {
                            return null;
                        }
                        return (
                            Views.entry({
                                badge: [{
                                    text: Utils.formatDate(evt.date),
                                    color: Appearance.colors.grey()
                                }],
                                bottomBorder: index !== events.length - 1,
                                icon: { path: evt.user.avatar },
                                key: index,
                                onClick: onSystemEventClick.bind(this, evt),
                                subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change' : 'changes'}` : ''}` : 'No overview available',
                                title: evt.alt_title
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={next => setOffset(next)} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.on('aft', 'system', 'on_update_event', onUpdateSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.off('aft', 'system', 'on_update_event', onUpdateSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type,
                type: 'system_events'
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(fetchEvents, 250);
    }, [offset]);

    useEffect(() => {
        connectToSockets();
        return disconnectFromSockets;
    }, []);

    return getSystemEvents();
}

export default SystemEventsList;
