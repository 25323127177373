import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Appearance from 'styles/Appearance.js';
import Slider from '@material-ui/core/Slider';

const CustomSlider = withStyles({
    root: {
        color: Appearance.colors.primary(),
        height: 2,
        padding: '2px 0',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        marginTop: -8,
        marginLeft: -10,
        border: `2px solid ${Appearance.colors.softBorder()}`,
        '&:focus, &:hover, &$active': {
            boxShadow: 'none'
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        height: 3,
        opacity: 1,
        backgroundColor: Appearance.colors.softBorder(),
    },
    mark: {
        backgroundColor: Appearance.colors.primary(),
        height: 8,
        width: 1
    },
    markActive: {
        opacity: 1,
        backgroundColor: Appearance.colors.green,
    }
})(Slider);

export default CustomSlider;
