import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance.js';
import AssetPickerField from 'views/AssetPickerField.js';
import BoolToggle from 'views/BoolToggle.js';
import ColorPickerField from 'views/ColorPickerField.js';
import DatePickerField from 'views/DatePickerField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DealershipLookupField from 'views/DealershipLookupField.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import DurationPickerField from 'views/DurationPickerField.js';
import FilePickerField from 'views/FilePickerField.js';
import GlobalDataSurveyLookupField from 'views/GlobalDataSurveyLookupField.js';
import GooglePlaceLookupField from 'views/GooglePlaceLookupField.js';
import IconPickerField from 'views/IconPickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import KBArticleLookup from 'views/KBArticleLookup.js';
import { LayerItem } from 'structure/Layer.js';
import LeadLookupField from 'views/LeadLookupField.js';
import ListField from 'views/ListField.js';
import LocationRadiusSelector from 'views/LocationRadiusSelector.js';
import MonthYearPicker from 'views/MonthYearPicker.js';
import MultipleLinkField from 'views/MultipleLinkField.js';
import MultipleListField from 'views/MultipleListField.js';
import MultipleTextField from 'views/MultipleTextField.js';
import MultipleTimezonePickerField from 'views/MultipleTimezonePickerField.js';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import ObjectEditorField from 'views/ObjectEditorField.js';
import PickerField from 'views/PickerField.js';
import SectorLookupField from 'views/SectorLookupField.js';
import TextField from 'views/TextField.js';
import TimePickerField from 'views/TimePickerField.js';
import TimezonePickerField from 'views/TimezonePickerField.js';
import UserLookupField from 'views/UserLookupField.js';
import WorkflowLandingLookupField from 'views/WorkflowLandingLookupField.js';
import WorkflowLandingTemplateLookupField from 'views/WorkflowLandingTemplateLookupField.js';
import WorkflowRuleLookupField from 'views/WorkflowRuleLookupField.js';

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

export const AltFieldItem = ({ item, lastItem = true, utils }) => {

    const hoverEl = useRef(null);
    const [hover, setHover] = useState(null);
    const [hoverSize, setHoverSize] = useState({
        height: 0,
        width: 0
    });

    const onManageLocationRadiusSelections = item => {
        utils.layer.open({
            id: 'location_radius_selector',
            Component: LocationRadiusSelector.bind(this, {
                locations: item.value || [],
                onChange: item.onChange
            })
        });
    }

    const onMouseEnter = evt => {
        setHover({
            left: evt.target.offsetLeft,
            top: evt.target.offsetTop
        });
    }

    const onMouseLeave = () => {
        setHover(null);
        setHoverSize({
            height: 0,
            width: 0
        });
    }

    const getComponent = item => {

        let { address, component, icon, items, loading, onChange, onClick, onValidate, props = {}, value } = item;
        switch(component) {

            case 'address_lookup':
            return (
                <AddressLookupField
                utils={utils}
                icon={icon}
                value={value}
                geocode={true}
                address={address}
                loading={loading}
                onChange={place => {
                    console.log(place);
                    if(typeof(onChange) === 'function') {
                        onChange({
                            address: place && {
                                administrative_area_level_1: place.administrative_area_level_1,
                                country: place.country,
                                id: place.place_id,
                                locality: place.locality,
                                name: place.name,
                                postal_code: place.postal_code,
                                street_address_1: place.street_address_1,
                                street_address_2: place.street_address_2
                            },
                            location: place && place.location
                        });
                    }
                }}
                {...props} />
            )

            case 'asset_picker':
            return (
                <AssetPickerField
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            )
            
            case 'bool_list':
            return (
                <ListField
                items={[{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]}
                onChange={item => onChange(item ? item.id === 'yes' : null)}
                value={typeof(value) === 'boolean' ? (value ? 'Yes' : 'No') : null}
                {...props} />
            );

            case 'bool_toggle':
            return (
                <BoolToggle
                enabled={'Yes'}
                disabled={'No'}
                isEnabled={value}
                onChange={onChange}
                {...props}
                style={{
                    margin: null
                }} />
            );

            case 'color_picker':
            return (
                <ColorPickerField
                loading={loading}
                onChange={onChange}
                utils={utils}
                value={value}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            case 'date_duration_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                selected={value}
                placeholder={'Date'}
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'date_picker':
            return (
                <DatePickerField
                utils={utils}
                icon={icon}
                value={value}
                selected={value}
                onValidate={onValidate}
                onDateChange={onChange}
                {...props} />
            )

            case 'date_time_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                icon={icon || 'calendar'}
                selected={value}
                onChange={onChange}
                {...props} />
            )

            case 'dealership_lookup':
            return (
                <DealershipLookupField
                utils={utils}
                inline={true}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'dual_date_picker':
            return (
                <DualDatePickerField
                utils={utils}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'duration_picker':
            return (
                <DurationPickerField
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'file_picker':
            return (
                <FilePickerField
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            )

            case 'global_data_survey_lookup_field':
            return (
                <GlobalDataSurveyLookupField
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            )

            case 'google_place_lookup':
            return (
                <GooglePlaceLookupField
                icon={icon}
                loading={loading}
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            )

            case 'icon_picker':
            return (
                <IconPickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'image_picker':
            return (
                <ImagePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'kb_article_lookup':
            return (
                <KBArticleLookup
                inline={true}
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )

            case 'lead_lookup':
            return (
                <LeadLookupField
                onChange={onChange}
                utils={utils}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'list':
            return (
                <ListField
                items={items}
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'location_radius_picker':
            return (
                <TextField
                fieldStyle={{ color: value ? Appearance.colors.text() : Appearance.colors.subText() }}
                onClick={onManageLocationRadiusSelections.bind(this, item)}
                value={value ? `${value.length} ${value.length === 1 ? 'location' : 'locations'}` : 'No locations selected'}
                {...props} />
            )

            case 'month_year_picker':
            return (
                <MonthYearPicker
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'multiple_file_picker':
            return (
                <FilePickerField
                multiple={true}
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            )

            case 'multiple_list':
            return (
                <MultipleListField
                icon={icon}
                items={items}
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'multiple_link_field':
            return (
                <MultipleLinkField
                onChange={onChange}
                utils={utils}
                value={value}
                {...props} />
            ) 

            case 'multiple_textfield':
            return (
                <MultipleTextField
                utils={utils}
                icon={icon}
                loading={loading}
                onChange={onChange}
                onValidate={onValidate}
                useDelay={false}
                value={value}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            case 'multiple_timezone_picker':
            return (
                <MultipleTimezonePickerField
                onChange={onChange}
                timezones={value}
                utils={utils}
                {...props} />
            )

            case 'multiple_user_lookup':
            return (
                <MultipleUserLookupField
                onChange={onChange}
                users={value}
                utils={utils}
                {...props} />
            )

            case 'object_editor':
            return (
                <ObjectEditorField
                onChange={onChange}
                objects={value}
                title={item.title}
                style={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            case 'picker':
            return (
                <PickerField
                icon={icon}
                items={items}
                value={value}
                disableScroll={true}
                onChange={onChange}
                {...props} />
            )

            case 'sector_lookup':
            return (
                <SectorLookupField
                icon={icon}
                utils={utils}
                value={value}
                loading={loading}
                onChange={onChange}
                {...props} />
            )

            case 'textfield':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                onValidate={onValidate}
                onChange={onChange}
                onClick={onClick}
                {...props} />
            )

            case 'textview':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                expandWithText={true}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            case 'time_picker':
            return (
                <TimePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'timezone_picker':
            return (
                <TimezonePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                style={{
                    width: '100%'
                }}
                {...props} />
            )

            case 'user_lookup':
            return (
                <UserLookupField
                icon={icon}
                utils={utils}
                user={value}
                loading={loading}
                placeholder={'Search by first or last name...'}
                onChange={onChange}
                {...props} />
            )

            case 'workflow_landing_lookup':
            return (
                <WorkflowLandingLookupField
                loading={loading}
                onChange={onChange}
                utils={utils}
                value={value}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            case 'workflow_landing_template_lookup':
            return (
                <WorkflowLandingTemplateLookupField
                loading={loading}
                onChange={onChange}
                utils={utils}
                value={value}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            case 'workflow_rule_lookup':
            return (
                <WorkflowRuleLookupField
                loading={loading}
                onChange={onChange}
                utils={utils}
                value={value}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
            )

            default:
            return null;
        }
    }
    
    useEffect(() => {
        if(hoverEl.current) {
            setHoverSize(props => {
                return update(props, {
                    width: {
                        $set: hoverEl.current.clientWidth
                    },
                    height: {
                        $set: hoverEl.current.clientHeight
                    }
                });
            });
        }
    }, [hover, hoverEl.current])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: lastItem === false ? 12 : 0,
            minWidth: 0,
            width: '100%',
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                minWidth: 0
            }}>
                {item.required !== false && (item.value === null || item.value === undefined) && (
                    <div style={{
                        backgroundColor: Appearance.colors.red,
                        borderRadius: 2.5,
                        height: 5,
                        overflow: 'hidden',
                        marginRight: 8,
                        width: 5
                    }} />
                )}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 4
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader()
                    }}>{item.title}</span>
                    {typeof(item.subTitle) === 'string' && (
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{item.subTitle}</span>
                    )}
                </div>
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                width: '100%'
            }}>
                {getComponent(item)}
                {item.description && (
                    <img
                    className={'text-button'}
                    src={'images/help-button-grey.png'}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        width: 20,
                        height: 20,
                        minWidth: 20,
                        minHeight: 20,
                        marginLeft: 12,
                        objectFit: 'contain'
                    }} />
                )}
                {hover && (
                    <div
                    ref={hoverEl}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25), -5px -5px 15px rgba(85,85,85,0.25)' : '5px 5px 15px rgba(175,175,174,0.25), -5px -5px 15px rgba(255,255,255,0.75)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        left: hoverSize.width ? (hover.left - hoverSize.width - 10) : 0,
                        maxWidth: 250,
                        opacity: hoverSize.width && hoverSize.height ? 1 : 0,
                        padding: '8px 12px 8px 12px',
                        position: 'absolute',
                        textAlign: 'right',
                        top: hoverSize.height ? (hover.top + 10 - (hoverSize.height / 2)) : 0,
                        width: 'auto',
                        zIndex: 9999
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'wrap'
                        }}>{item.description}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

const AltFieldMapper = ({ fields, utils }) => {
    return fields.filter(section => {
        return section.visible !== false
    }).map((section, index, sections) => {
        return (
            <LayerItem
            collapsed={section.collapsed !== undefined ? section.collapsed : false}
            key={index}
            lastItem={typeof(section.lastItem) === 'boolean' ? section.lastItem : index === sections.length - 1}
            rightContent={section.rightContent}
            title={section.title}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    overflow: 'visible',
                    padding: 12,
                    width: '100%'
                }}>
                    {section.items && section.items.filter(item => {
                        return item.visible !== false;
                    }).map((item, index, items) => {
                        return (
                            <AltFieldItem 
                            key={index} 
                            item={item}
                            lastItem={index === items.length - 1}
                            utils={utils} />
                        )
                    })}
                </div>
            </LayerItem>
        )
    });
}

export default AltFieldMapper;
