import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class ProductClass {

    id = null;
    key = null;
    omnishield_id = null;
    dealership = null;
    name = null;
    date = null;
    icon = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.key = props.key;
        this.omnishield_id = props.omnishield_id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.name = props.name;
        this.date = props.date ? moment(props.date) : null;
        this.icon = props.icon;
        this.active = props.active;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                name: this.name,
                icon: this.icon,
                dealership: this.dealership
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                name: props.name || this.edits.name,
                icon: props.icon || this.edits.icon,
                dealership: props.dealership || this.edits.dealership
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.name = this.edits.name || this.name;
            this.icon = this.edits.icon || this.icon;
            this.dealership = this.edits.dealership || this.dealership;
            resolve();
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/products/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                await this.close();
                utils.content.fetch('product');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/products/', {
                    type: 'update',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.update({
                    type: 'product',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            name: target.name,
            icon: target.icon ? target.icon.id : null,
            dealership_id: target.dealership ? target.dealership.id : null
        }
    }
}

export const fetchProduct = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { product } = await Request.get(utils, '/products/', {
                type: 'details',
                id: id
            });
            resolve(new ProductClass().create(product));
        } catch(e){
            reject(e);
        }
    })
}

export default {
    new: () => new ProductClass(),
    get: (utils, id) => fetchProduct(utils, id),
    create: props => new ProductClass().create(props)
}
