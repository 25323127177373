import React, { useEffect, useRef, useState } from 'react';
import Utils from 'files/Utils.js';
import moment from 'moment-timezone';

const CountableLabel = ({ date, ellipsizeMode, numberOfLines, placeholder, style }) => {

    const interval = useRef(null);
    const [value, setValue] = useState(0);

    const onSetInterval = () => {

        // check if an existing interval is present and clear if found
        if(interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }

        // calculate duration between supplied date and current date
        let duration = getDuration();

        // update duration value and determine the milliseconds needed to be set for the interval
        setValue(duration);
        let milliseconds = duration < 60 ? 1000 : 60000;

        // set an interval that fires every second then update vslue
        interval.current = setInterval(() => {
            let duration = getDuration();
            setValue(duration);
        }, milliseconds);
    }

    const getDuration = () => {
        return moment.utc().unix() - moment.utc(date).unix();
    }

    useEffect(() => {
        onSetInterval();
        return () => {
            if(interval.current) {
                clearInterval(interval.current);
            }
        }
    }, [date]);

    return (
        <span style={style}>{value ? `${Utils.parseDuration(value, true)} ago` : placeholder}</span>
    )
}
export default CountableLabel;