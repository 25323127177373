import React, { useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import { AddEditUser } from 'managers/Users.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Dealership from 'classes/Dealership.js';
import { DealershipDetails, DealershipSelector } from 'managers/Dealerships.js';
import ImagePickerField from 'views/ImagePickerField.js';
import LottieView from 'views/Lottie.js';
import ProgressBar from 'views/ProgressBar.js';
import Request from 'files/Request.js';
import { Spring } from '@react-spring/web';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const AFT_COMMUNICATION = 1;
const OMNISHIELD_COMMUNICATION = 2;

const Sidebar = ({ active, activeDealership, content, onLogoutClick, onMobileClose, onClick, user, utils }) => {

    const firstMenuItemsRef = useRef({});
    const [loading, setLoading] = useState(false);
    const [openMenus, setOpenMenus] = useState([]);
    const [springToggle, setSpringToggle] = useState(null);
    const [tasks, setTasks] = useState([]);

    const onCancelTask = task => {
        utils.alert.show({
            title: 'Cancel Task',
            message: 'Are you sure that you want to cancel this task? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelTaskConfirm(task);
                    return;
                }
            }
        });
    }

    const onCancelTaskConfirm = async task => {
        try {
            setLoading(task.id);
            await Utils.sleep(1);
            let { status } = await Request.post(utils, '/resources/', {
                type: 'cancel_task',
                id: task.id
            });

            // end loading and remove task from list of tasks
            setLoading(false);
            setTasks(tasks => {
                return tasks.filter(prev_task => prev_task.id !== task.id);
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this task. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChooseAvatar = async () => {
        let file = null;
        utils.alert.show({
            title: 'Change Profile Photo',
            message: 'Click the browse button to choose a file from your computer.',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <ImagePickerField
                    utils={utils}
                    onChange={result => file = result} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async key => {
                try {
                    if(!file || key !== 'confirm') {
                        return;
                    }

                    await Utils.sleep(1);
                    let user = utils.user.get();
                    let { url } = await Request.post(utils, '/users/', {
                        type: 'update_avatar',
                        user_id: user.user_id,
                        image_data: file
                    });

                    user.avatar = url;
                    utils.content.update({
                        type: 'user',
                        object: user
                    });
                    utils.alert.show({
                        title: 'All Done!',
                        message: `Your profile photo has been updated`
                    });

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue updating your profile photo. ${e.message || 'An unknown error occurred'}`
                    });
                }
            }
        });
    }

    const onDealershipClick = async () => {
        try {
            
            setLoading(true);
            let dealership = await Dealership.get(utils, user.dealership.id);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${dealership.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving your dealership details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewTask = ({ detail }) => {
        setTasks(tasks => {
            let index = tasks.find(task => task.id === detail.task.id);
            if(index >= 0) {
                tasks[index] = detail;
                return tasks;
            }
            return update(tasks, { 
                $push: [detail.task] 
            });
        });
    }

    const onProfileClick = () => {
        utils.layer.open({
            id: `edit_user_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: AddEditUser.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onRemoveTask = index => {
        setTasks(tasks => update(tasks, {
            $splice: [[ index, 1 ]]
        }));
    }

    const onResetDealership = () => {
        utils.dealership.set(utils.user.get().dealership);
    }

    const onSelectDealership = () => {
        utils.layer.open({
            id: 'dealership_selector',
            Component: DealershipSelector
        });
    }

    const onToggleMenu = key => {

        // close menu if menu is already open
        if(openMenus.includes(key) === true) {
            setOpenMenus(openMenus => {
                return update(openMenus, {
                    $splice: [
                        [openMenus.indexOf(key), 1]
                    ]
                });
            });
            return;
        }

        // open menu if mene is currently closed
        setOpenMenus(openMenus => {
            return update(openMenus, {
                $push: [key]
            });
        });

        // scroll menu into view if a ref is available and if the item is outside of the window view
        if(firstMenuItemsRef.current[key]) {
            setTimeout(() => {
                let { y } = firstMenuItemsRef.current[key].getBoundingClientRect();
                if(y > window.innerHeight) {
                    firstMenuItemsRef.current[key].scrollIntoView({ 
                        behavior: 'smooth', 
                        block: 'start', 
                        inline: 'nearest' 
                    });
                }
            }, 100);
        }
    }

    const onUserComponentClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'avatar',
                title: 'Change Profile Photo',
                style: 'default'
            },{
                key: 'dealership',
                title: 'My Dealership',
                style: 'default'
            },{
                key: 'profile',
                title: 'My Profile',
                style: 'default'
            },{
                key: 'logout',
                title: 'Logout',
                style: 'default'
            }],
            position: 'bottom',
            sort: false,
            target: evt.target
        }, key => {
            switch(key) {

                case 'avatar':
                onChooseAvatar();
                break;

                case 'dealership':
                onDealershipClick();
                break;

                case 'logout':
                onLogoutClick();
                break;

                case 'profile':
                onProfileClick();
                break;
            }
        })
    }

    const onUserTaskProgressChange = data => {
        try {
            console.log(data);
            setTasks(tasks => {
                if(!tasks.find(task => task.id === data.id)) {
                    return update(tasks, {$push: [data]});
                }
                return tasks.map(task => {
                    return task.id === data.id ? data : task;
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const isItemRestricted = item => {
        return utils.user.get().level > item.level;
    }

    const getContent = () => {
        return user && (
            <div
            key={'sidebar'}
            style={{
                position: 'relative',
                zIndex: 1100,
                backgroundColor: Utils.isMobile() ? Appearance.colors.background() : null
            }}>
                <nav className={'main-navbar navbar align-items-stretch navbar-light flex-md-nowrap p-0 d-lg-none d-md-none d-sm-none d-flex text-left'}>
                    <a className={'toggle-sidebar d-sm-inline d-md-none d-lg-none'}>
                        <img
                        src={`images/close-button-${window.theme}-small.png`}
                        onClick={onMobileClose}
                        style={{
                            width: 30,
                            height: 30,
                            objectFit: 'contain'
                        }} />
                    </a>
                </nav>
                <div
                className={`nav-wrapper ${window.theme}`}
                style={{
                    backgroundColor: Appearance.colors.transparent
                }}>
                    <ul className={'nav flex-column py-md-0 d-block'}>
                        <div
                        className={'pt-0 pt-md-3'}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingBottom: 25,
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 25,
                            textAlign: 'center'
                        }}>
                            <LottieView
                            loop={true}
                            autoPlay={true}
                            source={require('files/lottie/logo-desktop.json')}
                            style={{
                                width: 175,
                                height: 175
                            }}/>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 18,
                                fontWeight: 800,
                                lineHeight: 1.25
                            }}>{getTitle()}</span>
                            <span style={{
                                color: Appearance.colors.subText(),
                                fontSize: 14,
                                fontWeight: 600
                            }}>{getSubTitle()}</span>
                            {getTaskProgressComponents()}
                            {getUserComponent()}
                            {getSelector()}
                        </div>
    
                        {Object.values(content).map(item => {

                            // non-visible layers should not be rendered
                            if(item.visible === false) {
                                return null;
                            }
                            
                            if(item.subViews) {
    
                                // Show dropdown if more than 1 visible option
                                let items = Object.values(item.subViews);
                                if(items.length >= 1) {
                                    return getItem({
                                        ...item,
                                        isActive: active && item.key === active.view,
                                        items: items.map(subView => {
                                            return {
                                                ...subView,
                                                isSubviewActive: active && item.key === active.view && active.subView === subView.key,
                                                onClick: onClick
                                            }
                                        })
                                    })
                                }
    
                                // Hide dropdown if only one option
                                return items[0].panels.length > 0 && getItem({
                                    icon: item.icon,
                                    isActive: active && active.view === item.key,
                                    key: items[0].key,
                                    onClick: onClick.bind(this, {
                                        view: item.key,
                                        subView: items[0].key,
                                        action: item.action
                                    }),
                                    title: item.title
                                });
                            }
    
                            if(item.panels.length > 0) {
                                return getItem({
                                    icon: item.icon,
                                    isActive: active && active.view === item.key,
                                    key: item.key,
                                    onClick: onClick.bind(this, {
                                        action: item.action,
                                        view: item.key
                                    }),
                                    title: item.title,
                                })
                            }
                            return null;
                        })}
                    </ul>
                    <div style={{
                        textAlign: 'center',
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getItem = ({ icon, isActive, items, key, onClick, title }) => {
        return (
            <div
            key={key}
            onClick={items ? onToggleMenu.bind(this, key) : onClick}
            onMouseEnter={() => setSpringToggle(key)}
            onMouseLeave={() => setSpringToggle(null)}
            className={'cursor-pointer'}
            style={{
                marginBottom: 12,
                paddingLeft: 20,
                paddingRight: Utils.isMobile() ? 20 : 0
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Spring
                    toggle={springToggle === key ? true : false}
                    config={{ mass: 1, tension: 180, friction: 8 }}
                    from={{ transform: 'scale(1)' }}
                    to={{ transform: springToggle === key ? 'scale(1.15)' : 'scale(1.0)' }}>
                    {props => (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            minHeight: 40,
                            padding: 10,
                            borderRadius: 20,
                            overflow: 'hidden',
                            marginRight: 12,
                            backgroundColor: isActive ? Appearance.colors.primary() : Appearance.colors.layerBackground(),
                            boxShadow: '-10px 10px 15px rgba(0,0,0,0.05)',
                            ...props
                        }}>
                            <img
                            src={`images/${icon}${isActive || window.theme === 'dark' ? '-white' : ''}.png`}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                    )}
                    </Spring>

                    <span style={{
                        flexGrow: 1,
                        width: '100%',
                        fontWeight: 500,
                        fontSize: 14,
                        color: isActive ? Appearance.colors.primary() : Appearance.colors.text(),
                        paddingRight: 12,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{title}</span>
                    <div style={{
                        width: 3,
                        height: 25,
                        borderRadius: 1.5,
                        backgroundColor: isActive ? Appearance.colors.primary() : Appearance.colors.transparent,
                        overflow: 'hidden'
                    }} />
                </div>
                {items && (
                    <div style={{
                        backgroundColor: Appearance.colors.layerBackground(),
                        borderRadius: 10,
                        boxShadow: '-10px 10px 15px rgba(0,0,0,0.025)',
                        display: openMenus.includes(key) ? 'block' : 'none',
                        marginLeft: 30,
                        marginTop: 8,
                        overflow: 'hidden'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <div
                                key={item.key}
                                ref={ref => {
                                    if(index === 0) {
                                        firstMenuItemsRef.current[key] = ref
                                    }
                                }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <a
                                    className={`dropdown-item ${window.theme}`}
                                    onClick={() => {
                                        setOpenMenus([]);
                                        item.onClick({
                                            action: item.action,
                                            level: item.level,
                                            subView: item.key,
                                            view: key
                                        });
                                    }}
                                    style={{
                                        borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                        fontSize: 13,
                                        ...getLabelStyles(item)
                                    }}>{item.title}</a>
                                    {isItemRestricted(item) && (
                                        <img
                                        src={'images/lock-icon-small-red.png'}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            objectFit: 'contain',
                                            marginRight: 12
                                        }} />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getLabelStyles = item => {
        if(isItemRestricted(item)) {
            return {
                fontWeight: 600,
                color: Appearance.colors.red
            };
        }
        return {
            fontWeight: 400,
            color: item.isSubviewActive ? Appearance.colors.primary() : Appearance.colors.text()
        }
    }

    const getSelector = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {getSelectorButtons()}
                <div style={{
                    width: 50,
                    height: 1,
                    marginTop: 20,
                    backgroundColor: Appearance.colors.softBorder()
                }} />
            </div>
        )
    }

    const getSelectorButtons = () => {

        // dealership selection buttons are not relevant for the follow account types
        if(user.level === User.levels.get().exigent_admin || user.level >= User.levels.get().dealer) {
            return null;
        }

        // prepare buttons and determine if currently selected dealership is the home dealership for the current user
        let hasChangedDealership = activeDealership && activeDealership.id !== utils.user.get().dealership.id;
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginTop: 12,
                width: '100%'
            }}>
                {hasChangedDealership && (
                    <div style={{
                        marginRight: 4
                    }}>
                        <Button
                        color={'dark'}
                        label={'Home Dealership'}
                        onClick={onResetDealership} 
                        type={'large'}/>
                    </div>
                )}
                {user.level < User.levels.get().dealer && (
                    <div style={{
                        marginLeft: 4
                    }}>
                        <Button
                        color={'primary'}
                        label={'Change Dealership'}
                        onClick={onSelectDealership} 
                        type={'large'}/>
                    </div>
                )}
            </div>
        )
    }

    const getSubTitle = () => {
        if(activeDealership) {
            return activeDealership.address ? (activeDealership.address.street_address_1 || activeDealership.address.address) : 'Address Not Available';
        }
    }

    const getTaskIcon = task => {
        switch(task.category) {
            case AFT_COMMUNICATION:
            return task.complete ? 'images/checkmark-button-green-small.png' : 'images/aft-mobile-app-icon.jpg';

            case OMNISHIELD_COMMUNICATION:
            return task.complete ? 'images/checkmark-button-green-small.png' : 'images/omnishield-mobile-app-icon.jpg';

            default:
            return task.complete ? 'images/checkmark-button-green-small.png' : 'images/task-progress-light-grey.png'
        }
    }

    const getTaskModifierButton = (task, index) => {
        if(task.complete) {
            return (
                <img
                className={'text-button'}
                src={'images/close-button-light-small.png'}
                onClick={onRemoveTask.bind(this, index)}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain',
                    marginLeft: 8
                }}/>
            )
        }
        return (
            <img
            className={'text-button'}
            src={'images/red-x-icon.png'}
            onClick={onCancelTask.bind(this, task)}
            style={{
                width: 20,
                height: 20,
                objectFit: 'contain',
                marginLeft: 8
            }}/>
        )
    }

    const getTaskProgressValue = task => {
        switch(task.category) {
            case AFT_COMMUNICATION:
            case OMNISHIELD_COMMUNICATION:
            if(task.complete) {
                return `${Utils.softNumberFormat(task.total)} ${task.total === 1 ? 'message' : 'messages'} sent`
            }
            return task.progress > 0 ? `${Utils.softNumberFormat(task.progress)} ${task.progress === 1 ? 'message' : 'messages'} sent...` : `Preparing messages...`;

            default:
            return 'Unknown progress value';
        }
    }

    const getTaskProgressBar = task => {
        return task.progress > 0 && task.total > 0 && (
            <ProgressBar 
            variant={'determinate'}
            value={(task.progress / task.total) * 100} />
        )
    }

    const getTaskProgressComponents = () => {
        if(tasks.length === 0) {
            return null;
        }
        return (
            <div style={{
                marginTop: 20,
                paddingLeft: 15,
                paddingRight: 15,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    border: `1px solid ${Appearance.colors.softBorder()}`,
                    opacity: 0.99,
                    textAlign: 'left',
                    width: '100%'
                }}>
                    {tasks.map((task, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== tasks.length - 1,
                                bottomContent: getTaskProgressBar(task),
                                icon: {
                                    className: task.complete ? null : 'rotate',
                                    path: getTaskIcon(task)
                                },
                                key: index,
                                loading: loading === task.id,
                                rightContent: getTaskModifierButton(task, index),
                                subTitle: getTaskProgressValue(task),
                                title: getTaskTitle(task)
                            })
                        )
                    })}
                </div>
            </div>
        )
    }

    const getTaskTitle = task => {
        switch(task.category) {
            case AFT_COMMUNICATION:
            return `GHS Communication ${task.complete ? 'Complete' : 'In Progress'}`;

            case OMNISHIELD_COMMUNICATION:
            return `Omnishield Communication ${task.complete ? 'Complete' : 'In Progress'}`;

            default:
            return 'Unknown Task';
        }
    }

    const getTitle = () => {
        if(activeDealership) {
            return activeDealership.name;
        }
    }

    const getUserComponent = () => {
        if(!user) {
            return null;
        }
        return (
            <div style={{
                padding: 15,
                paddingBottom: 0,
                textAlign: 'left',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    {Views.entry({
                        title: user.full_name,
                        subTitle: User.levels.toText(user.level),
                        icon: {
                            path: user.avatar
                        },
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: onUserComponentClick
                    })}
                </div>
            </div>
        )
    }

    const onRemoveSocketListeners = async () => {
        try {
            await utils.sockets.off('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetupSocketListeners = async () => {
        try {
            await utils.sockets.persistOn('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(user) {
            setTasks(user.tasks || []);
            onSetupSocketListeners();
            return onRemoveSocketListeners;
        }
    }, [user]);

    useEffect(() => {
        utils.events.on('sidebar', 'new_task', onNewTask);
        return () => {
            utils.events.off('sidebar', 'new_task', onNewTask);
        }
    }, []);

    return getContent();
}
export default Sidebar;
