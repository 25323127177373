import React, { useState, useEffect } from 'react';
import Utils from 'files/Utils.js';

const ListField = ({ defaultValue, disablePlaceholder, items, onAddNew, onChange, placeholder, placeholderItem, preserveResult, returnKey, style, value }) => {

    const [selected, setSelected] = useState(value);

    const onListChange = evt => {

        // declare id for selected item
        let id = Utils.attributeForKey.select(evt, 'id');

        // look for an id match in the list of items and update selected statae
        let match = items.find(item => {
            let keys = Object.keys(item);
            if(keys.includes('code') === true) {
                return item.code == id;
            }
            if(keys.includes('id') === true) {
                return item.id == id;
            }
            return false;
        });
        setSelected(preserveResult !== false && match ? (match.title || match.text) : null);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            if(!match) {
                onChange(null); // used for set edits
                return;
            }
            onChange(returnKey === false ? (match.title || match.text) : match);
        }
    }

    const getAccessoryButton = () => {
        if(typeof(onAddNew) === 'function') {
            return (
                <div style={{
                    height: 25,
                    marginLeft: 12,
                    minHeight: 25,
                    minWidth: 25,
                    width: 25,
                }}>
                    <img
                    className={'text-button'}
                    src={'images/plus-button-blue-small.png'}
                    onClick={onAddNew}
                    style={{
                        height: '100%',
                        objectFit: 'contain',
                        width: '100%'
                    }} />
                </div>
            )
        }
        return null;
    }

    const getItems = () => {
        if(!items || !Array.isArray(items)) {
            return null;
        }
        return items.map((item, index) => {
            return (
                <option
                disabled={item.disabled || false}
                id={item.id || item.code}
                key={index}>{item.title || item.text}</option>
            )
        })
    }

    useEffect(() => {
        setSelected(value && typeof(value) === 'object' ? value.title : value);
    }, [value]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...style
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={defaultValue}
            onChange={onListChange}
            value={selected || placeholder || ''}
            style={{
                flexGrow: 1,
                height: 35
            }}>
                {placeholderItem !== false && (
                    <option id={'__placeholder'} disabled={disablePlaceholder}>{placeholder || 'Choose from the list below...'}</option>
                )}
                {getItems()}
            </select>
            {getAccessoryButton()}
        </div>
    )
}

export default ListField;
