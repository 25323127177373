import React from 'react';
import moment from 'moment';

import Request from 'files/Request.js';

// this class does not contain all the data points present in global data
// view or editing this class will redirect to global data website

class LeadClass {

    id = null;
    first_name = null;
    last_name = null;
    full_name = null;
    phone_number = null;
    email_address = null;
    address = null;
    location = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.first_name = props.first_name;
        this.last_name = props.last_name;
        this.full_name = props.full_name;
        this.phone_number = props.phone_number;
        this.email_address = props.email_address;
        this.address = props.address;
        this.location = props.location && {
            latitude: props.location.lat || props.location.latitude,
            longitude: props.location.long || props.location.longitude
        };

        return this;
    }
}

const fetchLead = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { lead } = await Request.gdl.get(utils, '/leads/', {
                type: 'details',
                id: id
            });
            let obj = new LeadClass().create(lead);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new LeadClass(),
    get: (utils, id) => fetchLead(utils, id),
    create: props => new LeadClass().create(props)
}
