import moment from 'moment';

import CommLink from 'classes/CommLink.js';
import Customer from 'classes/Customer.js';
import Dealership from 'classes/Dealership.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class CardClass {

    comm_link = null;
    comments = null;
    created = null;
    customer = null;
    date = null;
    dealership = null;
    full_protection = null;
    id = null;
    lead = null;
    serial_number = null;
    signature = null;
    sold_by = null;
    tags = [];
    total_units = null;
    units = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.comm_link = props.comm_link && CommLink.create(props.comm_link);
        this.comments = props.comments;
        this.created = props.created && moment.utc(props.created).local();
        this.customer = props.customer && Customer.create(props.customer);
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.full_protection = props.full_protection;
        this.id = props.id;
        this.lead = props.lead && Lead.create(props.lead);
        this.serial_number = props.serial_number;
        this.signature = props.signature;
        this.sold_by = props.sold_by && User.create(props.sold_by);
        this.tags = props.tags;
        this.total_units = props.total_units || 0;
        this.units = props.units;
        return this;
    }

    getCustomerNames = () => {
        if(!this.customer) {
            return 'Name not available';
        }
        if(!this.customer.spouse) {
            return this.customer.full_name;
        }
        return `${this.customer.first_name} and ${this.customer.spouse} ${this.customer.last_name}`;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                comm_link: this.comm_link,
                comments: this.comments,
                customer: this.customer ? this.customer.toJSON() : {},
                date: this.date,
                dealership: this.dealership,
                full_protection: this.full_protection,
                lead: this.lead,
                serial_number: this.serial_number,
                sold_by: this.sold_by,
                tags: this.tags,
                units: {}

            }
            // convert array of units to associative object if applicable
            if(this.units && Array.isArray(this.units)) {
                this.edits.units = this.units.reduce((object, unit) => {
                    object[unit.key] = unit.total;
                    return object;
                }, {})
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                comments: props.comments || this.edits.comments,
                comm_link: props.comm_link !== undefined ? props.comm_link : this.edits.comm_link,
                customer: props.customer || this.edits.customer,
                date: props.date || this.edits.date,
                dealership: props.dealership || this.edits.dealership,
                full_protection: props.full_protection !== undefined ? props.full_protection : this.edits.full_protection,
                lead: props.lead || this.edits.lead,
                serial_number: props.serial_number || this.edits.serial_number,
                sold_by: props.sold_by || this.edits.sold_by,
                tags: props.tags || this.edits.tags,
                units: props.units || this.edits.units,
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.comm_link = this.edits.comm_link !== undefined ? this.edits.comm_link : this.comm_link;
            this.comments = this.edits.comments || this.comments;
            this.customer = this.edits.customer || this.customer;
            this.date = this.edits.date || this.date;
            this.dealership = this.edits.dealership || this.dealership;
            this.full_protection = this.edits.full_protection !== undefined ? this.edits.full_protection : this.full_protection;
            this.lead = this.edits.lead || this.lead;
            this.serial_number = this.edits.serial_number || this.serial_number;
            this.sold_by = this.edits.sold_by || this.sold_by;
            this.tags = this.edits.tags || this.tags;
            this.units = this.edits.units || this.units;
            resolve();
        })
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/cards/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                })

                this.id = id;
                await this.close();
                utils.content.fetch('card');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { units } = await Request.post(utils, '/cards/', {
                    type: 'update',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                this.units = units;
                this.customer = Customer.create(this.edits.customer);

                utils.content.update({
                    type: 'card',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            comm_link: target.comm_link && {
                serial_number: target.comm_link.serial_number,
                security_key: target.comm_link.security_key
            },
            comments: target.comments,
            customer: target.customer,
            date: target.date && moment(target.date).format('YYYY-MM-DD HH:mm:ss'),
            dealership_id: target.dealership && target.dealership.id,
            full_protection: target.full_protection,
            id: this.id,
            lead_id: target.lead && target.lead.id,
            serial_number: target.serial_number,
            sold_by_user_id: target.sold_by && target.sold_by.user_id,
            tags: target.tags && target.tags.map(tag => tag.id),
            units: target.units
        }
    }
}

export const fetchCard = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { card } = await Request.get(utils, '/cards/', {
                type: 'details',
                id: id
            });
            resolve(new CardClass().create(card));
        } catch(e){
            reject(e);
        }
    })
}

export default {
    new: () => new CardClass(),
    get: (utils, id) => fetchCard(utils, id),
    create: props => new CardClass().create(props),
    filters: {
        locality: 1,
        administrative_area_level_1: 2,
        sold_by_user: 3,
        total_units: 4,
        full_protection: 5,
        date: 6
    }
}
