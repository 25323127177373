import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';

const TimePickerField = ({ appendComponent, minuteInterval = 1, onChange, style, value }) => {

    const selected = useRef(null);
    const [hour, setHour] = useState(null);
    const [hours, setHours] = useState([]);
    const [meridiem, setMeridiem] = useState(null);
    const [meridiems, setMeridiems] = useState([]);
    const [minute, setMinute] = useState(null);
    const [minutes, setMinutes] = useState([]);

    const onParseDate = () => {
        if(!hour || !meridiem || !minute) {
            return;
        }
        let next = moment(`${hour.id}:${minute.id}:00 ${meridiem.id}`, 'HH:mm:ss');
        if(selected.current && selected.current.format('HH:mm') === next.format('HH:mm')) {
            return;
        }
        if(!next.isValid()) {
            if(typeof(onChange) === 'function') {
                onChange(null);
            }
            return;
        }

        selected.current = next;
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const getFormattedHour = date => {
        let hour = parseInt(date.format('H'));
        return hour > 12 ? (hour - 12).toString() : hour.toString();
    }

    const setupTarget = () => {

        // setup list items
        setHours([ ...Array(12) ].map((_, index) => {
            let val = moment().startOf('day').add(index + 1, 'hours');
            return {
                id: val.format('HH'),
                title: val.format('H')
            }
        }));
        setMinutes([ ...Array(60 / minuteInterval) ].map((_, index) => {
            let val = moment().startOf('day').add(index * minuteInterval, 'minutes');
            return {
                id: val.format('mm'),
                title: val.format('mm')
            }
        }));
        setMeridiems([{
            id: 'am',
            title: 'AM'
        },{
            id: 'pm',
            title: 'PM'
        }]);

        // setup default hour, minute, and meridiem
        if(!value) {
            return;
        }

        setHour({
            id: value.format('HH'),
            title: getFormattedHour(value)
        });
        setMeridiem({
            id: value.format('a'),
            title: value.format('a').toUpperCase()
        });
        setMinute({
            id: value.format('mm'),
            title: value.format('mm')
        });
    }

    useEffect(() => {
        onParseDate();
    }, [hour, meridiem, minute]);

    useEffect(() => {
        setupTarget();
    }, [value]);

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            <div
            className={'row p-0 m-0'}
            style={{
                width: '100%'
            }}>
                <div
                className={'col-4 p-0 pl-0 pr-2 animate-500ms'}
                style={{
                    borderRight: `1px solid ${Appearance.colors.layerBackground()}`
                }}>
                    <ListField
                    items={hours}
                    onChange={item => setHour(item)}
                    value={hour && hour.title} />
                </div>
                <div
                className={'col-4 p-0 animate-500ms'}
                style={{
                    borderRight: `1px solid ${Appearance.colors.background()}`
                }}>
                    <ListField
                    items={minutes}
                    onChange={item => setMinute(item)}
                    value={minute && minute.title} />
                </div>
                <div className={'col-4 p-0 pl-2 pr-0'}>
                    <ListField
                    items={meridiems}
                    onChange={item => setMeridiem(item)}
                    value={meridiem && meridiem.title} />
                    {appendComponent}
                </div>
            </div>
        </div>
    )
}
export default TimePickerField;
