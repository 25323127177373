import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Support from 'classes/Support.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const KBArticleLookup = ({ containerStyle, onChange, placeholder, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [text, setText] = useState(null);

    const onArticleClick = async article => {

        // clear results, update text field value, and set selected article
        setResults([]);
        setText(article && article.name);
        console.log(article);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(article);
        }
    }

    const onTextChange = async text => {
        try {

            // update text state with new value
            setText(text);

            // prevent moving forward with search if less than 3 characters were provided
            if(text.length < 3) {

                // clear results, end loading, and remove current selection
                setResults([]);
                setLoading(false);

                // notify subscribers of data change
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // submit request to server 
            let { articles } = await Request.get(utils, '/support/', {
                search_text: text,
                type: 'all_kb_articles',
            });

            setLoading(false);
            setResults(articles.map(article => Support.Article.create(article)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setText(value && value.name);
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <TextField
                autoCapitalize={'sentences'}
                autoComplete={false}
                autoCorrect={false}
                loading={loading}
                onChange={onTextChange}
                placeholder={placeholder || 'Search by knowledge base article name or id...'}
                useDelay={true}
                value={text}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
            </div>
            {results.length > 0 && (
                <div
                className={`dummy-field ${window.theme}`}
                style={{
                    backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 12,
                    marginBottom: 12,
                    maxHeight: 500,
                    marginTop: 8,
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    padding: 0,
                    textAlign: 'left'
                }}>
                    {results.map((article, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                key: index,
                                onClick: onArticleClick.bind(this, article),
                                ...article.getDescription()
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default KBArticleLookup;
