import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

class BackupClass {

    archive_id = null;
    category = null;
    date = null;
    destinations = [];
    encrypted = false;
    id = null;
    manifest = null;
    size = 0;
    tags = null;
    title = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.archive_id = props.archive_id;
        this.category = props.category;
        this.date = props.date && moment.utc(props.date).local();
        this.destinations = props.destinations;
        this.encrypted = props.encrypted;
        this.id = props.id;
        this.manifest = props.manifest;
        this.size = props.size;
        this.tags = props.tags;
        this.title = props.title;
        return this;
    }

    formatDestinations = () => {
        let destinations = this.destinations.map(dest => {
            switch(dest) {
                case 'aws.s3':
                return 'Amazon S3 Storage';

                case 'ghs.nas':
                return 'GHS Home Office NAS';

                default:
                return 'Unknown destination';
            }
        });
        return Utils.oxfordImplode(destinations);
    }

    getColor = () => {
        switch(this.category) {
            case 'aft':
            return '#4B90E2';

            case 'global_data':
            return '#2F343A';

            case 'omnishield':
            return Appearance.colors.omnishieldPrimary;

            default:
            return Appearance.colors.grey();
        }
    }

    getIcon = () => {
        switch(this.category) {
            case 'aft':
            return 'images/aft-mobile-app-icon.jpg';

            case 'global_data':
            return 'images/global-data-mobile-app-icon.jpg';

            case 'omnishield':
            return 'images/omnishield-mobile-app-icon.jpg';

            default:
            return null;
        }
    }
}

export default {
    new: () => new BackupClass(),
    create: props => new BackupClass().create(props)
}
