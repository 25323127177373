import React from 'react'
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

class CustomerClass {

    user_id = null;
    first_name = null;
    last_name = null;
    full_name = null;
    spouse = null;
    address = null;
    location = null;
    email_address = null;
    phone_number = null;

    constructor() {
        return this;
    }

	create = (props = {}) => {

        this.first_name = props.first_name;
        this.last_name = props.last_name;
        this.full_name = props.full_name;
        this.spouse = props.spouse;
        this.email_address = props.email_address;
        this.phone_number = props.phone_number;
        this.address = props.address;
        this.location = props.location ? {
            latitude: props.location.lat || props.location.latitude,
            longitude: props.location.long || props.location.longitude
        } : null;

        return this;
	}

    toJSON = () => {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            full_name: this.full_name,
            spouse: this.spouse,
            address: this.address,
            location: this.location,
            email_address: this.email_address,
            phone_number: this.phone_number
        }
    }
}

export default {
    new: () => new CustomerClass(),
    create: props => new CustomerClass().create(props),
};
