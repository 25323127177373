import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import { CollapseArrow } from 'structure/Layer.js';

export const TableListHeader = ({ fields, flexible = true, onChange, style, value }) => {

    const [directions, setDirections] = useState({});
    const [selected, setSelected] = useState(null);

    const onHeaderClick = key => {

        let direction = directions[key] === Content.sorting.type.ascending ? Content.sorting.type.descending : Content.sorting.type.ascending;
        if(typeof(onChange) === 'function') {
            onChange({
                sort_key: key,
                sort_type: direction
            });
        }

        setSelected(key);
        setDirections(directions => {
            directions[key] = direction;
            return { ...directions }
        });
    }

    const getColor = key => {
        return selected === key ? Appearance.colors.primary() : Appearance.colors.text();
    }

    useEffect(() => {

        // update field sort values from external sorting resources (panel sorting)
        setSelected(value ? (value.key || value.sort_key) : null);
        if(value) {
            setDirections(directions => {
                directions[value.key] = value.direction || value.sort_type;
                return { ...directions }
            })
        }
    }, [value]);

    useEffect(() => {
        
        // setup associative object with default sorts
        // new fields can be introduced but original field values will not be overwritten by new default value
        let object = fields.reduce((obj, field) => {
            obj[field.key] = directions[field.key] || Content.sorting.type.descending;
            return obj;
        }, {});
        setDirections(object);
    }, []);

    return (
        <tr style={{
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }}>
            {fields.map((field, index) => {
                if(field.visible === false) {
                    return null
                }
                if(field.sortable === false) {
                    return (
                        <td
                        key={index}
                        className={`px-3 py-2 ${flexible ? 'flexible-table-column' : ''}`}
                        style={{
                            minWidth: flexible ? 0 : 125
                        }}>
                            <div style={{
                                display: 'inline-block',
                                ...style
                            }}>
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'hidden'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        fontWeight: Appearance.textStyles.subTitle().fontWeight + 100,
                                        marginRight: 8,
                                        marginTop: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                    }}>{field.title.toUpperCase()}</span>
                                </div>
                            </div>
                        </td>
                    )
                }
                return (
                    <td
                    key={index}
                    className={`px-3 py-2 ${flexible ? 'flexible-table-column' : ''}`}
                    style={{
                        minWidth: flexible ? 0 : 250
                    }}>
                        <div style={{
                            display: 'inline-block',
                            ...style
                        }}>
                            <div
                            className={'text-button'}
                            onClick={onHeaderClick.bind(this, field.key)}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                overflow: 'hidden'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: getColor(field.key),
                                    fontWeight: Appearance.textStyles.subTitle().fontWeight + 100,
                                    marginRight: 8,
                                    marginTop: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                }}>{field.title.toUpperCase()}</span>

                                <CollapseArrow
                                collapsed={directions[field.key] === Content.sorting.type.descending}
                                color={getColor(field.key)} />
                            </div>
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}

export const TableListRow = ({ lastItem, onClick, values }) => {

    return (
        <tr
        className={`view-entry ${window.theme}`}
        style={{
            borderBottom: lastItem !== true ? `1px solid ${Appearance.colors.divider()}` : null
        }}>
            {values.filter(entry => {
                return entry.visible !== false;
            }).map((entry, index) => {
                return (
                    <td
                    key={index}
                    className={`${entry.padding === false ? 'p-0' : 'px-3 py-2'} flexible-table-column`}
                    onClick={onClick}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            {typeof(entry.icon) === 'string' && (
                                <img
                                src={entry.icon}
                                style={{
                                    width: 25,
                                    height: 25,
                                    minWidth: 25,
                                    minHeight: 25,
                                    objectFit: 'cover',
                                    borderRadius: 12.5,
                                    overflow: 'hidden',
                                    marginRight: 8
                                }}/>
                            )}
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                ...entry.color && {
                                    color: entry.color
                                }
                            }}>{entry.value}</span>
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}
