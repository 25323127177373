import React, { useRef, useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const AssetPickerField = ({ onChange, preserveResult, style, types = [], utils, value }) => {

    const containerRef = useRef(null);
    const fileInputRef = useRef(null);

    const [asset, setAsset] = useState(null);
    const [assets, setAssets] = useState(utils.support.assets.get());
    const [uploading, setUploading] = useState(false);

    const mimeTypes = [{
        type: fileTypes.doc,
        mime: 'application/msword'
    },{
        type: fileTypes.docx,
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },{
        type: fileTypes.jpg,
        mime: 'image/jpeg'
    },{
        type: fileTypes.json,
        mime: 'application/json'
    },{
        type: fileTypes.m4v,
        mime: 'video/m4v'
    },{
        type: fileTypes.mov,
        mime: 'video/mov'
    },{
        type: fileTypes.mp4,
        mime: 'video/mp4',
    },{
        type: fileTypes.pdf,
        mime: 'com.adobe.pdf',
    },{
        type: fileTypes.pdf,
        mime: 'application/pdf',
    },{
        type: fileTypes.png,
        mime: 'image/png'
    },{
        type: fileTypes.txt,
        mime: 'text/plain'
    },{
        type: fileTypes.txt,
        mime: 'public.plain-text'
    },{
        type: fileTypes.xls,
        mime: 'application/vnd.ms-excel'
    },{
        type: fileTypes.xls,
        mime: 'com.microsoft.excel.xls'
    },{
        type: fileTypes.xlsx,
        mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },{
        type: fileTypes.gltf,
        mime: 'model/gltf+json'
    },{
        type: fileTypes.gltf,
        mime: 'model/gltf-binary'
    }];

    const onFileChange = evt => {
        try {
            // prevent moving forward if no file is available
            let { files } = evt.target;
            if(files && files.length === 0) {
                return;
            }

            // prevent moving forward if file exceeds max file upload size of 512mb
            if(files[0].size > 512000000) {
                throw new Error('The requested file is too large. Please select a file that is 512mb or less to continue.');
            }
            onSubmitAsset(files[0]);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your asset. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPresentFilePicker = () => {
        fileInputRef.current.click();
    }

    const onSelectAsset = asset => {
        if(preserveResult !== false) {
            setAsset(asset);
        }
        if(typeof(onChange) === 'function') {
            onChange(asset);
        }
    }

    const onSetSelectedAsset = () => {
        setAsset(assets.find(asset => value && value === asset.id));
    }

    const onShowAssetManager = () => {
        utils.layer.open({
            id: 'asset_manager',
            Component: AssetManager.bind(this, {
                assets: assets,
                onSelectAsset: onSelectAsset
            })
        });
    }

    const onSubmitAsset = async file => {
        try {
            setUploading(true);
            let { asset } = await Request.upload(utils, '/support/', {
                type: 'new_workflow_asset'
            }, {
                file: {
                    key: 'asset',
                    data: file
                },
                onProgressChange: ({ percent }) => {
                    setUploading(percent);
                }
            });

            // update assets with new addition
            let next = assets.concat([asset]).sort((a,b) => a.name.localeCompare(b.name));
            utils.support.assets.set(next);

            // set selected assets and commit newly added asset changes
            setUploading(false);
            onSelectAsset(asset);
            setAssets(next);

        } catch(e) {
            setUploading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue uploading your asset. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAcceptedFileTypes = () => {
        return mimeTypes.filter(entry => {
            return types ? types.includes(entry.type) : true;
        }).map(entry => {
            return entry.mime;
        }).join(',');
    }

    const getFirstParentDiv = target => {
        if(!target.parentNode) {
            return null;
        }
        if(target.parentNode.tagName.toLowerCase() !== 'div') {
            return getFirstParentDiv(target.parentNode);
        }
        return target.parentNode;
    }

    useEffect(() => {
        onSetSelectedAsset();
    }, [assets, value]);

    return (
        <div
        ref={containerRef}
        style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            minWidth: 0,
            position: 'relative',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            <input
            ref={fileInputRef}
            type={'file'}
            accept={getAcceptedFileTypes()}
            onChange={onFileChange}
            style={{
                display: 'none'
            }}/>
            <div
            className={'list-field-menu-icon-container'}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                minWidth: 0,
                width: '100%'
            }}>
                <TextField
                className={'list-field-menu-textfield'}
                loading={uploading}
                onClick={onPresentFilePicker}
                placeholder={'No asset selected'}
                value={asset ? asset.name : null} />
                {uploading === false && (
                    <>
                    <img
                    className={'text-button list-field-menu-clear-icon'}
                    onClick={onPresentFilePicker}
                    src={'images/new-button-clear-small.png'}
                    style={{
                        backgroundColor: Appearance.colors.primary(),
                        borderRadius: 10,
                        height: 20,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 20
                    }} />
                    <img
                    className={'text-button list-field-menu-fullscreen-icon'}
                    onClick={onShowAssetManager}
                    src={'images/fullscreen-icon-grey.png'}
                    style={{
                        height: 20,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 20
                    }} />
                    </>
                )}
            </div>
        </div>
    )
}

export default AssetPickerField;

const AssetManager = ({ onSelectAsset }, { index, options, utils }) => {

    const layerID = 'asset_manager';
    const [assets, setAssets] = useState(utils.support.assets.get());
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);

    const onAssetClick = asset => {
        setLayerState('close');
        if(typeof(onSelectAsset) === 'function') {
            onSelectAsset(asset);
        }
    }

    const onRemoveAsset = (index, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Remove Asset',
            message: 'Are you sure that you want to remove this asset? This will remove the asset from all presentations where this asset is used.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveAssetConfirm(index);
                    return;
                }
            }
        });
    }

    const onRemoveAssetConfirm = async index => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/support/', {
                type: 'remove_workflow_asset',
                id: assets[index].id
            });

            setLoading(false);
            let next = assets.filter((_, i) => i !== index);
            utils.support.assets.set(next);
            setAssets(next);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this asset. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAssets = () => {
        return assets.filter(asset => {
            return searchText ? asset.name.toLowerCase().includes(searchText) : true;
        });
    }

    const getAssetPreview = asset => {
        switch(asset.type.code) {
            case fileTypes.json:
            return {
                icon: () => (
                    <LottieView
                    autoPlay={false}
                    loop={false}
                    playOnMouseOver={true}
                    source={asset.content}
                    style={{
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: 8,
                        boxShadow: window.theme === 'dark' ? '3px 3px 7px rgba(0,0,0,0.25), -3px -3px 7px rgba(174,174,174,0.1)' : '3px 3px 7px #c9c8ca, -3px -3px 7px #ffffff',
                        height: 30,
                        minHeight: 30,
                        minWidth: 30,
                        width: 30
                    }} />
                )
            }

            default:
            return {
                icon: {
                    path: asset.url,
                    imageStyle: {
                        backgroundColor: Appearance.colors.grey(),
                        borderRadius: 8
                    }
                }
            }
        }
    }

    return (
        <Layer
        id={layerID}
        title={'Asset Manager'}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium',
            removePadding: true
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 8
            }}>
                <TextField
                placeholder={'Search by asset name...'}
                value={searchText}
                onChange={text => setSearchText(text.toLowerCase())} />
            </div>
            {getAssets().length === 0 &&  (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There are no assets available to view',
                    title: 'No Assets Found'
                })
            )}
            {getAssets().map((asset, index) => {
                return (
                    Views.entry({
                        ...getAssetPreview(asset),
                        badge: {
                            text: `${Utils.convertBytes(asset.size)}`,
                            color: Appearance.colors.grey()
                        },
                        bottomBorder: index !== assets.length - 1,
                        key: index,
                        onClick: onAssetClick.bind(this, asset),
                        rightContent: (
                            <img
                            className={'text-button'}
                            src={'images/trash-icon-red.png'}
                            onClick={onRemoveAsset.bind(this, index)}
                            style={{
                                height: 20,
                                marginLeft: 8,
                                objectFit: 'contain',
                                width: 20
                            }} />
                        ),
                        subTitle: `Uploaded: ${Utils.formatDate(asset.date)}`,
                        title: asset.name
                    })
                )
            })}
        </Layer>
    )
}

export const fileTypes = {
    doc: 1,
    docx: 2,
    jpg: 3,
    json: 4,
    m4v: 5,
    mov: 6,
    mp4: 7,
    pdf: 8,
    png: 9,
    txt: 10,
    xls: 11,
    xlsx: 12,
    gltf: 13
}
