import React from 'react';
import TextField from 'views/TextField.js';

const ObjectEditorField = ({ format, objects, onChange, reduced, style, title }) => {

    const onObjectChange = (key, text) => {
        let next = {
            ...objects,
            [key]: getFormattedValue(text)
        }
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const getFormattedValue = value => {
        switch(format) {
            case 'number':
            return parseFloat(value) || 0;

            default:
            return value;
        }
    }

    return (
        <div
        className={'row m-0'}
        style={{
            width: '100%',
            position: 'relative',
            ...style
        }}>
            {Object.keys(objects).map((key, index) => {
                return (
                    <div
                    key={index}
                    className={`col-12 pb-2 pt-0 px-0 ${reduced ? '' : 'col-lg pl-lg-0 pr-lg-2 py-lg-0'}`}>
                        <TextField
                        format={format}
                        insetLabel={`${title} ${key.capitalize()}`}
                        onChange={onObjectChange.bind(this, key)}
                        value={objects[key] || '0'} />
                    </div>
                )
            })}
        </div>
    )
}

export default ObjectEditorField;
