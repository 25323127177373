import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const SectorLookupField = ({ containerStyle, icon, inline, onChange, placeholder, type, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);

    const onValidate = () => {
        return selected ? true : false
    }

    const onClick = async sector => {

        setResults([]);
        setSelected(sector);
        setText(sector.name);

        if(typeof(onChange) === 'function') {
            onChange(sector);
        }
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {

            if(text.length < 3) {
                setResults([]);
                setLoading(false);
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            try {
                let { sectors } = await Request.get(utils, '/sectors/', {
                    type: 'lookup',
                    limit: 100,
                    search_text: text,
                    sector_type: type
                })
                setLoading(false);
                setResults(sectors.map(sector => Sector.create(sector)));

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
                });
            }

        }, 250));
    }

    useEffect(() => {
        setSelected(value);
        if(value) {
            setText(value.name);
        }

    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            value={text}
            icon={icon}
            loading={loading}
            placeholder={placeholder || 'Search by id, name, or director...'}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={'sentences'}
            onChange={onTextChange}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }}/>
            {results.length > 0 && (
                <div
                className={`dummy-field ${window.theme}`}
                style={{
                    padding: 0,
                    overflow: 'hidden',
                    marginTop: 8,
                    marginBottom: 12,
                    borderRadius: 12,
                    textAlign: 'left',
                    border: `1px solid ${Appearance.colors.divider()}`,
                    backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white'
                }}>
                    {results.map((sector, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: sector.name,
                                subTitle: sector.director ? sector.director.full_name : null,
                                hideIcon: true,
                                onClick: onClick.bind(this, sector),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                singleItem: results.length === 1,
                                firstItem: index === 0,
                                lastItem: index !== results.length - 1
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default SectorLookupField;
