import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Appearance from 'styles/Appearance.js';
import LinearProgress from '@material-ui/core/LinearProgress';

export default withStyles({
    colorPrimary: {
        backgroundColor: Appearance.colors.soft()
    },
    barColorPrimary: {
        backgroundColor: Appearance.colors.primary()
    }
})(LinearProgress);;
