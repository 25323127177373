import React, { useState, useEffect } from 'react';
import '@pathofdev/react-tag-input/build/index.css';
import ReactTagInput from '@pathofdev/react-tag-input';

const TagLookupField = ({ onChange, placeholder, style, value }) => {

    const [tags, setTags] = useState(value || []);

    const onTagsChange = next => {
        setTags(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    useEffect(() => {
        setTags(value || []);
    }, [value]);

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            <ReactTagInput
            onChange={onTagsChange}
            placeholder={placeholder || 'Type and press enter'}
            removeOnBackspace={true}
            tags={tags}
            validator={value => {
                return tags.find(tag => tag === value) ? false : true;
            }}/>
        </div>
    )
}
export default TagLookupField;
