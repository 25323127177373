import React, { useCallback, useState } from 'react';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'files/api.js';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Request from 'files/Request.js';
import User from 'classes/User.js';

const Utils = {
    addressLookup: async (utils, text, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { places } = await Request.get(utils, '/resources/', {
                    type: 'address_lookup',
                    search_text: text,
                    use_autocomplete: true,
                    ...props
                })
                resolve({
                    results: places.map((result, index) => {
                        return {
                            key: index,
                            place_id: result.place_id,
                            name: result.name || 'Name Unavailable',
                            address: result.address,
                            ...result.location && {
                                location: {
                                    latitude: result.location.lat,
                                    longitude: result.location.long
                                }
                            }
                        }
                    })
                })

            } catch(e) {
                reject(e);
            }
        })
    },
    adjustColor: (color, percent) => {
        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;
        G = (G<255)?G:255;
        B = (B<255)?B:255;

        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
    },
    apply: (target, object) => {
        let key = Object.keys(object).find(k => isNaN(k) ? k === target : parseFloat(k) === target);
        return key && typeof(object[key]) === 'function' ? object[key]() : (typeof(object.default) === 'function' ? object.default() : null);
    },
    attributeForKey: {
        select: (e, key) => {
            let optionElement = e.target.childNodes[e.target.selectedIndex];
            return optionElement.getAttribute(key);
        }
    },
    capitalize: text => {
        return text ? `${text.charAt(0).toUpperCase()}${text.substring(1)}` : '';
    },
    colorIsBright: color => {

        // declare rgb, hsp variables
        let r = null;
        let g = null;
        let b = null;
        let hsp = null;

        // check if value is structured as an rgb array instead of a hexidecimal value
        // convert to rgb if hexidecimal value is found
        if(color.match(/^rgb/)) {
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // generate hsp from rgb values
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // value is considered light if hsp is greater than 127.5
        return hsp > 127.5 ? true : false;
    },
    conformDate: (date, interval) => {
        let targetDate = date || moment();
        let minutes = parseInt(moment(targetDate).format('mm'));
        if(minutes % interval === 0) {
            return moment(targetDate);
        }

        let decimal = minutes % interval;
        let offset = interval - decimal;
        return moment(targetDate).add(offset, 'minutes');
    },
    convertBytes: (bytes, decimals) => {
        if(isNaN(bytes) || bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    copyText: val => {

        // create temporary text area and add to body 
        let textArea = document.createElement('textarea');
        textArea.value = val;
        textArea.style.opacity = 0;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);

        // focus and select textarea
        textArea.focus();
        textArea.select();

        // copy contents of textarea to clipboard
        navigator.clipboard.writeText(textArea.value);
        document.body.removeChild(textArea);
    },
    formatAddress: (props = {}) => {

        // return formatted address in accordance with google auto complete formatting
        let { administrative_area_level_1, country, locality, postal_code, street_address_1, street_address_2 } = props || {};
        if(administrative_area_level_1 && country && locality && postal_code && street_address_1) {
            return `${street_address_1}${street_address_2 ? `, ${street_address_2 }` : ''}. ${locality}, ${administrative_area_level_1} ${postal_code}, ${country}`;
        }
        // return ala-carte address components as they are found
        return [ street_address_1, street_address_2, locality, administrative_area_level_1, postal_code, country ].filter(val => {
            return val && val.toString().length > 1 ? true : false;
        }).join(', ');
    },
    formatBytes: (bytes, decimals = 2) => {
        if(isNaN(bytes) || bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatDate: (date, withoutTime = false) => {
        if(!date ) {
            return 'Unknown';
        }
        let next_date = moment(date);
        if(next_date.isValid() !== true) {
            return 'Date is not valid';
        }
        if(moment().isSame(next_date, 'day')) {
            return moment(date).format(withoutTime ? '[Today] MMMM Do' : '[Today at] h:mma');
        }
        if(moment().subtract(1, 'days').isSame(next_date, 'day')) {
            return moment(date).format(withoutTime ? '[Yesterday] MMMM Do' : '[Yesterday at] h:mma');
        }
        if(next_date > moment() && next_date <= moment().add(6, 'days')) {
            return moment(date).format(withoutTime ? 'dddd MMMM Do' : 'dddd [at] h:mma');
        }
        if(moment().isSame(next_date, 'year')) {
            return moment(date).format(withoutTime ? 'MMMM Do' : 'MMM Do [at] h:mma');
        }
        return moment(date).format(withoutTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY [at] h:mma');
    },
    formatDateDuration: date => {
        if(!date) {
            return 'Unknown';
        }
        let unix_a = moment(date).unix();
        let unix_b = moment().unix();
        if(unix_b - unix_a < 86400) {
            return Utils.parseDuration(unix_b - unix_a);
        }
        return Utils.formatDate(date);
    },
    formatDuration: (date, minimal = false) => {
        if(!date) {
            return 'Unknown';
        }
        let unix_a = moment.utc(date).unix();
        let unix_b = moment.utc().unix();
        if(unix_b - unix_a < 86400) {
            return `${Utils.parseDuration(unix_b - unix_a, minimal)} ago`;
        }
        return Utils.formatDate(date);
    },
    formatLocation: props => {
        const truncate = n => {
            return n > 0 ? Math.floor(n) : Math.ceil(n);
        }
        const getDMS = (dd, longOrLat) => {
            let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
            ? dd < 0
              ? "W"
              : "E"
            : dd < 0
              ? "S"
              : "N";

            const absDD = Math.abs(dd);
            const degrees = truncate(absDD);
            const minutes = truncate((absDD - degrees) * 60);
            const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

            let dmsArray = [degrees, minutes, seconds, hemisphere];
            return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
        }
        let lat = props.lat || props.latitude;
        let long = props.long || props.longitude;
        return `${getDMS(lat, 'lat')} by ${getDMS(long, 'long')}`
    },
    formatPhoneNumber: (phoneNumber, countryCode = 'US') => {
        try {
            let instance = PhoneNumberUtil.getInstance();
            let result = instance.parse(phoneNumber, countryCode);
            return instance.format(result, PhoneNumberFormat.INTERNATIONAL);
        } catch(e) {
            return phoneNumber;
        }
    },
    formatProtectionDate: date => {
        let next_date = date ? moment(date) : null;
        if(!next_date) {
            return 'Unknown';
        }

        // do not include time if time is midnight
        // this normally means that the date is a legacy date with an invalid time
        return Utils.formatDate(next_date, next_date.hours() === 0 && next_date.minutes() === 0);
    },
    geocode: async (utils, location) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { result } = await Request.get(utils, '/resources/', {
                    type: 'geocode_location',
                    ...location
                })
                resolve({
                    ...result,
                    ...result.location && {
                        location: {
                            latitude: result.location.lat,
                            longitude: result.location.long
                        }
                    }
                });

            } catch(e) {
                reject(e);
            }
        })
    },
    getCenterFromAnnotations: annotations => {

        if(annotations.length === 0) {
            return;
        }
        if(annotations.length === 1) {
            return [annotations[0].location.latitude, annotations[0].location.longitude];
        }

        var num_coords = annotations.length;

        var X = 0.0;
        var Y = 0.0;
        var Z = 0.0;

        for(var i = 0; i < annotations.length; i++) {
            var lat = annotations[i].location.latitude * Math.PI / 180;
            var lon = annotations[i].location.longitude * Math.PI / 180;

            var a = Math.cos(lat) * Math.cos(lon);
            var b = Math.cos(lat) * Math.sin(lon);
            var c = Math.sin(lat);

            X += a;
            Y += b;
            Z += c;
        }

        X /= num_coords;
        Y /= num_coords;
        Z /= num_coords;

        var newLon = Math.atan2(Y, X);
        var hyp = Math.sqrt(X * X + Y * Y);
        var newLat = Math.atan2(Z, hyp);

        var newX = (newLat * 180 / Math.PI);
        var newY = (newLon * 180 / Math.PI);

        return [newX, newY];
    },
    getPagingOffset: (offset, direction) => {

        var newOffset = 0;
        if(direction == 'next') {
            newOffset = offset + 5;
        } else if(direction == 'back') {
            newOffset = offset - 5;
        } else if(!isNaN(direction)) {
            newOffset = (direction - 1) * 5;
        }
        return newOffset < 0 ? 0 : newOffset;
    },
    getPlatformName: key => {
        switch(key) {
            case 'aft':
            return 'Applied Fire Technologies';

            case 'global_data':
            return 'Global Data';

            case 'graci':
            return 'Graci';

            case 'omnishield':
            return 'OmniShield';

            default:
            return 'Unknown platform';
        }
    },
    getRandomNumber: (min = 1, max = 100) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    },
    getRegionFromAnnotations: coordinates => {

        let northWest = {
            latitude: -90,
            longitude: 180
        };
        let southEast = {
            latitude: 90,
            longitude: -180
        };

        coordinates.forEach((coordinate) => {
            northWest.longitude = Math.min(northWest.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            northWest.latitude = Math.max(northWest.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);

            southEast.longitude = Math.max(southEast.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            southEast.latitude = Math.min(southEast.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);
        })
        return [
            [ northWest.longitude, northWest.latitude ],
            [ southEast.longitude, southEast.latitude ]
        ];
    },
    getRestrictToDealershipDefault: utils => {
        return ![
            User.levels.get().admin,
            User.levels.get().region_director,
            User.levels.get().divison_director,
            User.levels.get().area_director
        ].includes(utils.user.get().level);
    },
    getSpanFromAnnotations: annotations => {

        if(annotations.length === 1) {
            return {
                latitudeDelta: 0.1,
                longitudeDelta: 0.1
            }
        }

        let northWest = {
            latitude: -90,
            longitude: 180
        };
        let southEast = {
            latitude: 90,
            longitude: -180
        };

        annotations.forEach((annotation) => {
            northWest.longitude = Math.min(northWest.longitude, annotation.location.longitude);
            northWest.latitude = Math.max(northWest.latitude, annotation.location.latitude);

            southEast.longitude = Math.max(southEast.longitude, annotation.location.longitude);
            southEast.latitude = Math.min(southEast.latitude, annotation.location.latitude);
        })
        return {
            latitudeDelta: parseFloat(northWest.latitude - southEast.latitude) * 1.5,
            longitudeDelta: parseFloat(southEast.longitude - northWest.longitude) * 1.5
        };
    },
    hexToRGBA: (hex, alpha) => {

        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
    		return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    	}
    	return hex;
    },
    isMobile: () => {
        return window.innerWidth < 767.98;
    },
    lcFirst: text => {
        return text ? (text.charAt(0).toLowerCase() + text.substring(1)) : '';
    },
    linearDistance: (start, end) => {

        const deg2rad = deg => {
            return deg * (Math.PI / 180);
        }

        let lat1 = start.lat;
        let lat2 = end.lat;
        let lon1 = start.long;
        let lon2 = end.long;

        var dLat = deg2rad(lat2 - lat1);
        var dLon = deg2rad(lon2 - lon1);
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let km = 6371 * c;
        return {
            feet: km * 3281,
            kilometer: km,
            miles: km / 1.609
        };
    },
    numberFormat: value => {
        if(value > 1000000) {
            return `${(parseFloat(value) / 1000000).toFixed(2)} M`;
        }
        if(value > 1000) {
            return `${(parseFloat(value) / 1000).toFixed(2)} K`;
        }
        return value % 1 !== 0 ? parseFloat(value).toFixed(1) : value;
    },
    oxfordImplode: (items = []) => {
        if(items.length === 0) {
            return null;
        }
        if(items.length === 1) {
            return items[0];
        }
        if(items.length === 2) {
            return `${items[0]} and ${items[1]}`;
        }
        let string = '';
        for(var i in items) {
            if(i > 0) {
                string += parseInt(i) === items.length - 1 ? ', and ' : ', ';
            }
            string += items[i];
        }
        return string;
    },
    parseDuration: (duration, minimal = false) => {

        let d = parseInt(duration || 0);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hours = h > 0 ? h + (h === 1 ? (minimal ? ' hr' : ' hour') : (minimal ? ' hrs' : ' hours')) : null;
        let minutes = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : null;

        if(h >= 168) {
            let days = parseInt(Math.floor(h / 24));
            return  `${days} days`;
        }
        if(h >= 24) {
            let days = parseInt(Math.floor(h / 24));
            let _hours = h - (days * 24);
            let string = `${days} ${days === 1 ? 'day' : 'days'}`;
            if(_hours > 0 && minutes) {
                string += `, ${_hours} ${_hours === 1 ? (minimal ? 'hr' : 'hour') : (minimal ? 'hrs' : 'hours')}, and ${minutes}`;
            } else if(_hours) {
                string += ` and ${_hours} ${_hours === 1 ? (minimal ? 'hr' : 'hour') : (minimal ? 'hrs' : 'hours')}`;
            } else if(minutes) {
                string += ` and ${minutes}`;
            }
            return string;
        }
        if(hours && minutes) {
            return `${hours} and ${minutes}`;
        }
        if(hours) {
            return hours;
        }
        if(m >= 1) {
            return minutes;
        }
        return `${s} seconds`;
    },
    randomString: () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    sleep: async seconds => {
        return new Promise(resolve => {
            setTimeout(resolve, (seconds * 1000));
        })
    },
    softNumberFormat: (val, digits = 0) => {
        return isNaN(val) ? 0 : parseFloat(val).toLocaleString('en-US', { minimumFractionDigits: digits })
    },
    toCurrency: (value, currency) => {
        return parseFloat(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: currency || 'USD'
        })
    },
    ucFirst: text => {
        return text ? (text.charAt(0).toUpperCase() + text.substring(1)) : '';
    },
    validate: {
        date: date => {
            return date ? moment(date).isValid() : false;
        },
        email: text => {
            return text ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text) : false;
        },
        phone: text => {
            return text ? /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(text) : false;
        }
    },
    ytd: {
        end_date: () => {
            if(API.dev_env) {
                //return moment('2020-03-31');
            }
            let today = moment();
            let year = parseInt(today.format('YYYY'));
            return today.unix() > moment(`${year}-04-01`).unix() ? moment(`${year + 1}-03-31`) : moment(`${year}-03-31`)
        },
        start_date: () => {
            if(API.dev_env) {
                //return moment('2019-04-01');
            }
            let today = moment();
            let year = parseInt(today.format('YYYY'));
            return today.unix() > moment(`${year}-04-01`).unix() ? moment(`${year}-04-01`) : moment(`${year - 1}-04-01`)
        }
    }
}
export default Utils;

export const loadingInitValue = 'init';

export const useLoading = initVal => {
    const [state, setState] = useState(initVal || loadingInitValue);
    const onSetState = useCallback((val, shouldSet = true) => {
        if(shouldSet === false) {
            return;
        }
        setState(val);
    }, []);
    return [state, onSetState, {
        loading: state,
        initVal: initVal || loadingInitValue
    }]
}

export const useResultsManager = initialState => {

    const [state, setState] = useState({
        ...initialState,
        offset: 0
    });

    // state update accepts a props objects or a key, value pair
    const onSetState = useCallback((key, value) => {
        if(typeof(key) === 'string') {
            setState(props => update(props, {
                [key]: {
                    $set: value
                }
            }));
            return;
        }
        setState(key);
    }, []);

    // optional function to format results for query
    const formatResults = callback => {
        let results = {
            ...state,
            ...state.end_date && {
                end_date: moment(state.end_date).format('YYYY-MM-DD HH:mm:ss')
            },
            ...state.start_date && {
                start_date: moment(state.start_date).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        // check if callback was provided for specialty formatting
        if(typeof(callback) === 'function') {
            results = callback(results);
        }
        return results;
    }

    return [state, onSetState, formatResults]
}