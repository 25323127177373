import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import moment from 'moment';

import Appearance from 'styles/Appearance';
import BoolToggle from 'views/BoolToggle.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import Utils from 'files/Utils.js';

const ChallengerOmnisAlert = ({ onChooseReport, onClose, utils }) => {

    const ref = useRef(null);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: 0,
        transform: 'scale(0)',
        config: { mass: 1, tension: 180, friction: 16 }
    }));
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [visible, setVisible] = useState(false);
    const [ytd, setYTD] = useState(false);

    const onButtonClick = async key => {
        try {
            await onHideAlert(key);
            if(key === 'done' && typeof(onChooseReport) === 'function') {
                onChooseReport({
                    end_date: endDate,
                    start_date: startDate,
                    ytd: ytd
                });
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onHideAlert = async key => {
        return new Promise(async (resolve, reject) => {
            try {
                setAnimations({
                    opacity: 0,
                    transform: 'scale(0)'
                });
                await Utils.sleep(0.25);
                if(typeof(onClose) === 'function') {
                    onClose();
                }
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onShowAlert = () => {
        let interval = setInterval(() => {
            if(!ref.current) {
                return;
            }
            clearInterval(interval);
            setAnimations({
                opacity: 1,
                transform: `scale(1)`
            });
        }, 250);
    }

    const onUpdateDateRange = () => {
        if(ytd) {
            setStartDate(Utils.ytd.start_date());
            setEndDate(Utils.ytd.end_date());
            return;
        }
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
    }

    const getButtons = () => {
        return [{
            key: 'done',
            title: 'Done',
            style: 'default'
        },{
            key: 'cancel',
            title: 'Cancel',
            style: 'cancel'
        }];
    }

    const getButtonColor = style => {
        switch(style) {
            case 'default':
            return window.theme === 'dark' ? 'white' : Appearance.colors.primary()

            default:
            return Appearance.colors.text();
        }
    }

    const getContent = () => {
        if(visible === false) {
            return null;
        }
        return (
            <>
            <animated.div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: Appearance.colors.dim,
                zIndex: 9925,
                opacity: animations.opacity
            }} />
            <animated.div
            ref={ref}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: Appearance.colors.transparent,
                zIndex: 9950,
                opacity: animations.opacity
            }}>
                <animated.div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 15,
                    backgroundColor: Appearance.colors.alert(),
                    overflow:'hidden',
                    maxHeight: 'calc(100vh - 30px)',
                    maxWidth: 400,
                    width: '100%',
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 9999,
                    ...animations
                }}>
                    <span style={{
                        display: 'block',
                        color: Appearance.colors.text(),
                        fontSize: 14,
                        fontWeight: '500',
                        paddingTop: 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginBottom: 8
                    }}>{'Challenger Omnis'}</span>

                    <div style={{
                        marginBottom: 15,
                        maxHeight: 500,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        maxWidth: '100%'
                    }}>
                        <span style={{
                            display: 'block',
                            maxWidth: '100%',
                            color: Appearance.colors.subText(),
                            fontSize: 12,
                            fontWeight: '400',
                            paddingLeft: 15,
                            paddingRight: 15,
                            wordWrap: 'break-word'
                        }}>{'Please choose a start date, end date, and report type below'}</span>
                    </div>
                    <div style={{
                        padding: 12,
                        width: '100%'
                    }}>
                        <BoolToggle
                        color={Appearance.colors.grey()}
                        isEnabled={ytd}
                        enabled={'Year to Date'}
                        disabled={'Monthly'}
                        onChange={val => setYTD(val)}
                        style={{
                            marginBottom: 12
                        }}/>

                        <DualDatePickerField
                        utils={utils}
                        overrideAlerts={true}
                        selectedStartDate={startDate}
                        selectedEndDate={endDate}
                        onStartDateChange={setStartDate}
                        onEndDateChange={setEndDate}
                        style={{
                            width: '100%'
                        }} />
                    </div>
                    {getButtons().map((button, index) => {
                        return (
                            <div
                            key={index}
                            className={`alert-item ${window.theme}`}
                            onClick={onButtonClick.bind(this, button.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <div style={{
                                    height: 50
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <span style={{
                                            fontSize: 13,
                                            fontWeight: '400',
                                            textAlign: 'center',
                                            color: getButtonColor(button.style)
                                        }}>{button.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </animated.div>
            </animated.div>
            </>
        )
    }

    useEffect(() => {
        onUpdateDateRange();
    }, [ytd]);

    useEffect(() => {
        setVisible(true);
        onShowAlert();
    }, []);

    return getContent();
}

export default ChallengerOmnisAlert;
