class AbstractClass {

    type = null;
    object = null;

    constructor() {
        return this;
    }

    compare = (target, callback) => {

        // compare two astract objects if applicable
        if(typeof(target.getID) === 'function') {
            if(this.getTag() === target.getTag()) {
                if(typeof(callback) === 'function') {
                    callback();
                }
                return this.object;
            }
            return target.object;
        }

        // compare and abstract object and a standard javascript object
        if(this.getID() !== target.id) {
            return target;
        }
        if(typeof(callback) === 'function') {
            callback();
        }
        return this.object;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }

    isNewTarget = () => {
        return this.getID() ? false : true;
    }

    isSame = abstract => {
        return this.getTag() === abstract.getTag();
    }

    getType = () => {
        return this.type;
    };

    getTag = () => {
        return `${this.type}-${this.getID()}`;
    };

    getID = () => {
        return this.type === 'user' ? this.object.user_id : this.object.id
    };

    getTitle = () => {

        switch(this.type) {

            case 'card':
            return `Protection ${this.object.customer ? `for ${this.object.customer.full_name}` : `#${this.getID()}`}`;

            case 'comm_link':
            return `Comm Link${this.object.card ? ` for ${this.object.card.full_name}` : ''}`;

            case 'comm_link_contact':
            return this.object.full_name;

            case 'comm_link_sensor':
            return `${this.object.location} ${this.object.type.text}`;

            case 'dealership':
            return `${this.object.name} Dealership`;

            case 'flipchart_presentation':
            return `${this.object.title} FlipChart Presentation`;

            case 'kb_articles':
            return `"${this.object.name}" Knowledge Base Article`;

            case 'notification':
            return this.object.title;

            case 'product':
            return `${this.object.name} Product`;

            case 'sector':
            return this.object.name || 'Team';

            case 'support_requests':
            return `Support Request ${this.object.id}`;

            case 'support_workflows':
            return this.object.name || `Support Workflow #${this.object.id}`;

            case 'support_workflow_landings':
            return this.object.name ? `"${this.object.name}"` : `Support Workflow Bridge #${this.object.id}`;

            case 'support_workflow_rules':
            return this.object.name ? `"${this.object.name}"` : `Support Workflow Rule #${this.object.id}`;

            case 'support_workflow_rule_routes':
            return this.object.name ? `"${this.object.name}"` : `Support Workflow Rule Route ${this.object.id}`;

            case 'support_workflow_rule_tasks':
            return this.object.name ? `"${this.object.name}"` : `Support Workflow Rule Task ${this.object.id}`;

            case 'system_event':
            return `System Event #${this.object.id} from ${this.object.user.full_name}`;

            case 'user':
            return this.object.user_id ? (this.object.full_name || 'User Account') : 'New User';

            case 'user_group':
            return this.object.id ? `"${this.object.title}" User Group` : 'New User Group';

            default:
            console.log(this.type);
            return 'Unknown Object';
        }
    }
}
export default {
    create: props => new AbstractClass().create(props)
};

export const buildTag = props => {
    let so = new AbstractClass();
    so.type = props.type;
    so.object = props.object || { ...props.type !== 'users' ? { id: props.id } : { user_id: props.id } }
    return so.getTag();
}
