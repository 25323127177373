import React, { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

const AlertStack = ({ children }) => {

    const [animations, animationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 22 },
        opacity: 0
    }));
    const [visible, setVisible] = useState(false);

    const onAnimateComponents = async visible => {
        animationsApi.start({ opacity: visible ? 1 : 0 });
        if(visible === false) {
            await Utils.sleep(0.25);
        }
        setVisible(visible);
    }

    useEffect(() => {
        onAnimateComponents(children.length > 0);
    }, [children.length]);

    return (
        <div style={{
            alignItems: 'center',
            backgroundColor: Appearance.colors.transparent,
            bottom: 0,
            display: visible ? 'flex' : 'none',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: 9950
        }}>
            <animated.div style={{
                backgroundColor: Appearance.colors.dim,
                bottom: 0,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                ...animations
            }} />
            {children}
        </div>
    );
}
export default AlertStack;
