import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import TextField, { getIcon } from 'views/TextField.js';
import Utils from 'files/Utils.js';

const DateDurationPickerField = ({ fieldStyle, format, icon, maxDate, maxHour, minHour, onChange, placeholder, selected, style, utils }) => {

    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [timeOfDay, setTimeOfDay] = useState(null);
    const [times, setTimes] = useState([...new Array(144)].map((_, index) => moment().startOf('day').add(index * (1/12), 'hours').format('h:mm')));

    const setupTarget = () => {
        let target = selected ? moment(selected) : moment();
        let nextDate = Utils.conformDate(target, 5);
        setDate(nextDate.format('YYYY-MM-DD'));
        setTime(nextDate.format('h:mm'));
        setTimeOfDay(nextDate.format('a'));
    }

    const setRestrictions = () => {
        if(!minHour && !maxHour) {
            return;
        }

        let current = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let min = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let max = moment().startOf('day').add(maxHour || 23, 'hours').unix();
        setTimes(() => {
            let times = [];
            while(current <= max) {
                let next = moment.unix(current);
                if(next.format('a') === timeOfDay) {
                    times.push(next.format('h:mm'));
                }
                current = next.add(5, 'minutes').unix();
            }
            return times;
        })
    }

    const onFieldClick = () => {
        utils.datePicker.show({
            dateTime: false,
            date: date ? moment(date) : moment(),
            onDateChange: onDateChange
        })
    }

    const onDateChange = date => {
        if(maxDate && date > maxDate) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a date before ${Utils.formatDate(maxDate)}`,
                onClick: onSetMaxDate
            });
            return;
        }
        let formatted = date.format('YYYY-MM-DD');
        setDate(formatted);
    }

    const onSetMaxDate = () => {
        if(!maxDate) {
            return;
        }
        setDate(maxDate.format('YYYY-MM-DD'));
        setTime(maxDate.format('h:mm'));
        setTimeOfDay(maxDate.format('a'));
    }

    const onTimeChange = evt => {
        let index = Utils.attributeForKey.select(evt, 'id');
        setTime(times[index]);
    }

    const onTimeOfDayChange = evt => {
        let text = Utils.attributeForKey.select(evt, 'id');
        setTimeOfDay(text);
    }

    const parseDateTime = () => {
        if(!date || !time || !timeOfDay) {
            return;
        }

        let nextDate = moment(`${date} ${time}${timeOfDay}`, 'YYYY-MM-DD hh:mma');
        if(maxDate && nextDate > maxDate) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a date before ${Utils.formatDate(maxDate)}`,
                onClick: onSetMaxDate
            });
            return;
        }
        if(typeof(onChange) === 'function') {
            onChange(nextDate);
        }
    }

    const formatDate = () => {
        if(!date) {
            return placeholder || 'Select a date...';
        }
        return moment(date).format(format || 'MMMM Do, YYYY');
    }

    const getTextColor = () => {
        return date ? Appearance.colors.text() : Appearance.colors.subText();
    }

    useEffect(() => {
        parseDateTime();
    }, [date, time, timeOfDay]);

    useEffect(() => {
        setRestrictions();
    }, [timeOfDay])

    useEffect(() => {
        setupTarget();
    }, [selected]);

    return (
        <div
        className={'row p-0 m-0'}
        style={{
            width: '100%'
        }}>
            <div className={'col-12 col-md-6 p-0'}>
                <div
                onClick={onFieldClick}
                className={`dummy-field ${window.theme} cursor-pointer`}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexGrow: 1,
                    position: 'relative',
                    height: 35,
                    border: `1px solid ${Appearance.colors.divider()}`,
                    ...style
                }}>
                    {getIcon(icon) && (
                        <div style={{
                            paddingRight: 8,
                            marginLeft: 4
                        }}>
                            <i
                            className={getIcon(icon)}
                            style={{
                                marginRight: 8,
                                color: Appearance.colors.softBorder(),
                                fontSize: 13
                            }}/>
                        </div>
                    )}
                    <span style={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: getTextColor(),
                        width: '100%',
                        flexGrow: 1,
                        ...fieldStyle
                    }}>{formatDate()}</span>
                </div>
            </div>
            <div className={'col-12 col-md-3 p-0 px-md-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={time || ''}
                onChange={onTimeChange}
                style={{
                    marginRight: 8
                }}>
                    {times.map((time, index) => {
                        return <option key={index} id={index}>{time}</option>
                    })}
                </select>
            </div>
            <div className={'col-12 col-md-3 p-0'}>
                <select
                className={`custom-select ${window.theme}`}
                value={timeOfDay ? timeOfDay.toUpperCase() : 'AM'}
                onChange={onTimeOfDayChange}
                style={{
                    marginRight: 8
                }}>
                    {[ 'am', 'pm' ].map((text, index) => {
                        return <option key={index} id={text}>{text.toUpperCase()}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
export default DateDurationPickerField;
