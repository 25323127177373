import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';

const ColorPickerField = ( { colors = [], insetLabel, loading, onChange, style, value } ) => {

    const [expanded, setExpanded] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);

    const onChangeColor = color => {

        // prepare color value
        let next = color && !color.includes('#') && !color.includes('rgb') ? `#${color}` : color;
        if(!next || next.length === 0) {
            next = null;
        }

        // update state and close color list
        setSelectedColor(next);
        setExpanded(false);

        // notify subscribers of data changes
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onToggleMenuState = () => {
        setExpanded(val => !val);
    }

    const onUpdateSelectedColor = val => {
        if(val) {
            let color = getColors().find(c => c.id === val);
            setSelectedColor(color || { id: val, title: val });
        }
    }

    const getColors = () => {
        return colors.concat([{
            id: Appearance.colors.red,
            title: 'Red'
        },{
            id: Appearance.colors.darkRed,
            title: 'Dark Red'
        },{
            id: Appearance.colors.orange,
            title: 'Orange'
        },{
            id: Appearance.colors.yellow,
            title: 'Yellow'
        },{
            id: Appearance.colors.green,
            title: 'Green'
        },{
            id: Appearance.colors.darkGreen,
            title: 'Dark Green'
        },{
            id: Appearance.colors.teal,
            title: 'Teal'
        },{
            id: Appearance.colors.blue,
            title: 'Blue'
        },{
            id: Appearance.colors.omnishieldTertiary,
            title: 'OmniShield: Tertiary'
        },{
            id: Appearance.colors.darkBlue,
            title: 'Dark Blue'
        },{
            id: Appearance.colors.omnishieldPrimary,
            title: 'OmniShield: Primary'
        },{
            id: Appearance.colors.omnishieldSecondary,
            title: 'OmniShield: Secondary'
        },{
            id: Appearance.colors.purple,
            title: 'Purple'
        },{
            id: Appearance.colors.brown,
            title: 'Brown'
        },{
            id: Appearance.colors.lighterGrey,
            title: 'Lightest Grey'
        },{
            id: Appearance.colors.lightGrey,
            title: 'Light Grey'
        },{
            id: Appearance.colors.darkGrey,
            title: 'Dark Grey'
        },{
            id: Appearance.colors.darkerGrey,
            title: 'Darkest Grey'
        },{
            id: '#FFFFFF',
            title: 'White'
        },{
            id: '#000000',
            title: 'Black'
        }]);
    }

    const getItems = () => {
        if(expanded === false) {
            return null;
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginTop: 8,
                maxHeight: 250,
                overflowX: 'hidden',
                overflowY: 'scroll',
                ...insetLabel && {
                    borderRadius: 8
                }
            }}>
                {getColors().map((color, index, colors) => {
                    return (
                        <div
                        className={`view-entry ${window.theme}`}
                        key={index}
                        onClick={onChangeColor.bind(this, color.id)}
                        style={{
                            alignItems: 'center',
                            borderBottom: index !== colors.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 12px 8px 12px'
                        }}>
                            <div style={{
                                backgroundColor: color.id,
                                border: color.id === '#FFFFFF' && `2px solid ${Appearance.colors.divider()}`,
                                borderRadius: 7.5,
                                height: 15,
                                overflow: 'hidden',
                                marginRight: 8,
                                width: 15
                            }} />
                            <span style={{
                                ...Appearance.textStyles.key(),
                                minWidth: 0
                            }}>{color.title}</span>
                        </div>
                    )
                })}
            </div>
        )
    }

    useEffect(() => {
        onUpdateSelectedColor(value);
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                {selectedColor !== null && (
                    <div style={{
                        backgroundColor: selectedColor.id,
                        borderRadius: 12.5,
                        height: 25,
                        marginRight: 8,
                        minWidth: 25,
                        width: 25
                    }} />
                )}
                <TextField
                appendContent={(
                    <img
                    className={'cursor-pointer'}
                    onClick={onToggleMenuState}
                    src={expanded ? 'images/up-arrow-grey-small.png' : 'images/down-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }} />
                )}
                loading={loading}
                onChange={onChangeColor}
                prependComponent={selectedColor && (
                    <div style={{
                        backgroundColor: selectedColor.id,
                        borderRadius: 5,
                        height: 25,
                        marginRight: 12,
                        minHeight: 25,
                        minWidth: 25,
                        width: 25
                    }} />
                )}
                value={selectedColor && selectedColor.title} />
            </div>
            {getItems()}
        </div>
    )
};
export default ColorPickerField;
