import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Views from 'views/Main.js';
import TimezonePickerField from 'views/TimezonePickerField.js';

const MultipleTimezonePickerField = ({ onChange, timezones, utils }) => {

    const [selectedTimezones, setSelectedTimezones] = useState(timezones || []);

    const onAddTimezone = timezone => {
        console.log(timezone)
        if(!timezone) {
            return;
        }
        setSelectedTimezones(timezones => {
            return update(timezones, {
                $push: [timezone]
            });
        })
    }

    const onRemoveTimezone = (index, e) => {
        e.stopPropagation();
        setSelectedTimezones(timezones => {
            return update(timezones, {
                $splice: [[
                    index, 1
                ]]
            });
        })
    }

    useEffect(() => {
        if(typeof(onChange) === 'function') {
            onChange(selectedTimezones);
        }
    }, [selectedTimezones])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <TimezonePickerField
            containerStyle={{ width: '100%' }}
            onChange={onAddTimezone}
            preserveResult={false}
            utils={utils} />
            {selectedTimezones.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {selectedTimezones.sort((a,b) => {
                        if(a === b) {
                            return a.localeCompare(b);
                        }
                        return a.localeCompare(b);
                    }).map((timezone, index) => {
                        return (
                            <div
                            key={index}
                            style={{
                                alignItems: 'center',
                                borderBottom: index !== selectedTimezones.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '6px 10px 6px 10px',
                                width: '100%',
                            }}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                flexGrow: 1,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{timezone}</span>
                                <img
                                className={'text-button'}
                                name={'Remove Item'}
                                onClick={onRemoveTimezone.bind(this, index)}
                                src={'images/red-x-icon.png'}
                                style={{
                                    height: 20,
                                    objectFit: 'contain',
                                    marginLeft: 8,
                                    width: 20
                                }} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleTimezonePickerField;
