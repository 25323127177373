import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import PageControl from 'views/PageControl.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import UserLookupField from 'views/UserLookupField.js';
import Views from 'views/Main.js';

const MultipleUserLookupField = ({ onChange, restrictToDealership, restrictToGenealogy, rightContent, users, utils }) => {

    const limit = 5
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState(users || []);

    const onAddUser = user => {

        // prevent moving forward if no user was provided
        if(!user) {
            return;
        }

        // update state with new selections
        let next = update(selectedUsers, { $push: [user] });
        setSelectedUsers(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveUser = (index, e) => {

        // stop click event from propagating to parent
        e.stopPropagation();

        // update state with new selections
        let next = update(selectedUsers, {
            $splice: [[
                index, 1
            ]]
        });
        setSelectedUsers(next)

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onUserClick = async userID => {
        try {

            // fetch details for user, user object may be incomplete if passed to this component from elsewhere
            setLoading(userID);
            let user = await User.get(utils, userID);

            // show user details layer
            setLoading(false);
            utils.layer.open({
                id: `user_details_${userID}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getUsers = () => {
        return selectedUsers.filter((_, index) => {
            if(!paging) {
                return true;
            }
            return index >= offset && index < (offset + limit);
        }).sort((a,b) => {
            if(!a.last_name || !b.last_name) {
                return -1;
            }
            if(a.last_name === b.last_name) {
                return a.first_name.localeCompare(b.first_name);
            }
            return a.last_name.localeCompare(b.last_name);
        })
    }

    useEffect(() => {
        setPaging(selectedUsers && {
            total: selectedUsers.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: selectedUsers.length > limit ? Math.ceil(selectedUsers.length / limit) : 1
        });
    }, [offset, selectedUsers]);

    useEffect(() => {
        setSelectedUsers(users || []);
    }, [users]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <UserLookupField
                containerStyle={{ flexGrow: 1 }}
                icon={'users'}
                onChange={onAddUser}
                preserveResult={false}
                placeholder={'Search by first or last name...'}
                restrictToDealership={restrictToDealership}
                restrictToGenealogy={restrictToGenealogy}
                utils={utils} />
                {rightContent}
            </div>
            {selectedUsers.length > 0 && (
                <div 
                className={'custom-scrollbars'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8,
                    maxHeight: 750,
                    textAlign: 'left',
                    width: '100%'
                }}>
                    {getUsers().map((user, index, users) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                loading: loading === user.user_id,
                                onClick: onUserClick.bind(this, user.user_id),
                                firstItem: index === 0,
                                singleItem: users.length === 1,
                                lastItem: index === users.length - 1,
                                bottomBorder: index !== users.length - 1,
                                rightContent: (
                                    <img
                                    className={'text-button'}
                                    src={'images/red-x-icon.png'}
                                    onClick={onRemoveUser.bind(this, index)}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginLeft: 8
                                    }} />
                                )
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={setOffset} />
                    )}
                </div>
            )}
        </div>
    )
}

export default MultipleUserLookupField;
