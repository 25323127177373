import React, { useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';

const Button = ({ color, label, loading, onClick, style, transition, type }) => {

    const buttonRef = useRef(null);
    const [buttonAnimations, setButtonAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 20 },
        transform: 'scale(1)'
    }));
    const [hoverState, setHoverState] = useState(false);
    const [iconAnimations, setIconAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 20 },
        left: -250,
        opacity: 0
    }))
    const [labelAnimations, setLabelAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 20 },
        left: 0,
        opacity: 1
    }))

    const getStyles = () => {

        let styles = {
            ...style,
            backgroundColor: getColors(),
            color: 'white',
            fontSize: 10,
            fontWeight: 600,
            padding: '3px 8px 3px 8px',
            width: 'auto'
        };
        switch(type) {

            case 'transition':
            styles = {
                ...styles,
                fontSize: 13,
                fontWeight: 600,
                height: 30,
                padding: '8px 25px 8px 25px',
                width: '100%'
            }
            break;

            case 'large':
            styles = {
                ...styles,
                fontSize: 13,
                fontWeight: 600,
                height: 30,
                padding: '6px 12px 6px 12px',
                width: '100%'
            }
            break;

            case 'small':
            styles = {
                ...styles,
                height: 19,
                fontSize: 10,
                padding: '3px 8px 3px 8px',
                width: 'auto'
            }
            break;
        }
        return styles;
    }

    const getColors = () => {
        if(hoverState) {
            switch(color) {
                case 'secondary':
                return Utils.adjustColor(Appearance.colors.secondary(), -35);

                case 'dark':
                case 'grey':
                return Utils.adjustColor(Appearance.colors.darkGrey, -35);

                case 'danger':
                return Utils.adjustColor(Appearance.colors.red, -35);

                default:
                return Utils.adjustColor(Appearance.colors.primary(), -35);
            }
        }
        switch(color) {
            case 'secondary':
            return Appearance.colors.secondary();

            case 'dark':
            case 'grey':
            return Appearance.colors.darkGrey;

            case 'danger':
            return Appearance.colors.red;

            default:
            return Appearance.colors.primary();
        }
    }

    const getContent = () => {
        if(loading) {
            return (
                <LottieView
                loop={true}
                autoPlay={true}
                source={require('files/lottie/dots-white.json')}
                style={{
                    width: 25,
                    height: 25
                }}/>
            )
        }
        if(transition) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 30,
                    width: '100%',
                    textAlign: 'center',
                    position: 'relative'
                }}>
                    <animated.div style={{
                        position: 'absolute',
                        width: '100%',
                        ...iconAnimations
                    }}>
                        <img
                        src={`images/${transition.icon}`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }} />
                    </animated.div>
                    <animated.div style={{
                        position: 'relative',
                        width: '100%',
                        ...labelAnimations
                    }}>
                        <span style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{label}</span>
                    </animated.div>
                </div>
            )
        }
        return (
            <span style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>{label}</span>
        )
    }

    const onButtonClick = () => {
        onMouseLeave();
        if(typeof(onClick) === 'function') {
            onClick({ target: buttonRef.current });
        }
    }

    const onMouseEnter = () => {
        setHoverState(true);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1.1)'
            });
            setIconAnimations({
                left: 0,
                opacity: 1
            });
            setLabelAnimations({
                left: 250,
                opacity: 0
            });
        }
    }

    const onMouseLeave = () => {
        setHoverState(false);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1)'
            });
            setIconAnimations({
                left: -250,
                opacity: 0
            });
            setLabelAnimations({
                left: 0,
                opacity: 1
            });
        }
    }

    return (
        <animated.div style={buttonAnimations}>
            <button
            onClick={onButtonClick}
            className={'btn btn-pill'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={buttonRef}
            style={{
                ...getStyles(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'hidden'
            }}>
                {getContent()}
            </button>
        </animated.div>
    )
}
export default Button;
