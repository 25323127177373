import React, { useState, useEffect } from 'react';

import ListField from 'views/ListField.js';
import Request from 'files/Request.js';

const TimezonePickerField = ({ containerStyle, onChange, preserveResult, utils, value }) => {

    const [items, setItems] = useState([]);

    const onValueChange = val => {
        if(typeof(onChange) === 'function') {
            onChange(val.title);
        }
    }

    const fetchTimezones = async () => {
        try {
            let { timezones } = await Request.get(utils, '/resources/', {
                type: 'timezones'
            });
            setItems(timezones.map(zone => ({
                id: zone.title,
                code: zone.title,
                title: zone.title
            })));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the timezones list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTimezones();
    }, []);

    return (
        <div style={{
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            items={items}
            onChange={onValueChange} 
            preserveResult={preserveResult}
            utils={utils}
            value={value}/>
        </div>
    )
}
export default TimezonePickerField;
