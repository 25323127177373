import React, { useEffect, useRef, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Support from 'classes/Support.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const WorkflowLandingLookupField = ({ containerStyle, disableAssigned, icon, initialID, insetLabel, onChange, onRenderResult, placeholder, preserveResult, showValidation, utils, value, workflow = {} }) => {

    const containerRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [landings, setLandings] = useState([]);
    const [selected, setSelected] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onArrowClick = () => {
        setExpanded(!expanded);
    }

    const onRemoveSelectedLanding = () => {
        setText('');
        setSelected(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onResultClick = landing => {

        // prevent moving forward if assignment has already been made and disableAssigned is truthy
        if(workflow && landing.workflow && landing.workflow.id !== workflow.id && disableAssigned === true) {
            onSelectAssignedLanding(null, landing);
            return;
        }

        // hide results list, update selected landing, and update text field label
        setResults([]);
        setSelected(landing);
        setExpanded(false);
        setText(landing.getDescription().title);

        // remove result text from text field and update selected value if applicable
        if(preserveResult === false) {
            setText('');
            setSelected(null);
        }

        // send selected landing back to subscribers
        if(typeof(onChange) === 'function') {
            onChange(landing);
        }
    }

    const onSelectAssignedLanding = (evt, landing) => {
        if(evt) {
            evt.stopPropagation();
        }
        utils.alert.show({
            title: 'Landing Already Assigned',
            message: `This landing is assigned to the "${landing.workflow.name}" support workflow. Please remove it from this workflow if you wish to reassign it.`
        });
    }

    const onTextChange = text => {

        // clear pending search text timeout if applicable
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        // update textfield label, remove previously selected result, and begin request loading
        setText(text);
        setLoading(true);
        setSelected(null);
        setExpanded(false);

        // prepare next search text timeout
        setTextTimeout(async () => {
            try {

                // prevent moving forward if search text length is less than three characters
                await Utils.sleep(0.25);
                if(!text || (isNaN(text) && text.length < 3)) {
                    setResults([]);
                    setLoading(false);
                    return;
                }

                // send auto complete request to server
                let { landings } = await Request.get(utils, '/support/', {
                    type: 'all_workflow_landings',
                    limit: 5,
                    search_text: text,
                    workflow_id: workflow && workflow.id
                });
                setLoading(false);
                setResults(landings.map(landing => Support.Workflow.Landing.create(landing)));

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
                });
            }

        });
    }

    const onValidate = () => {
        return selected ? true : false
    }

    const getAppendContent = () => {
        if((text && text.length > 0) || landings.length === 0 && selected === null) {
            return null;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                right: 12,
                height: '100%',
                paddingLeft: 30,
                background: `linear-gradient(90deg, ${Appearance.colors.textField(0)}, ${Appearance.colors.textField(0.9)}, ${Appearance.colors.textField()})`
            }}>
                {landings.length > 0 && (
                    <img
                    className={'cursor-pointer'}
                    onClick={onArrowClick}
                    src={expanded ? 'images/up-arrow-grey-small.png' : 'images/down-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }} />
                )}
                {selected && Views.icon.remove({ onClick: onRemoveSelectedLanding })}
            </div>
        )
    }

    const getContent = () => {
        if(results.length === 0 && (expanded === false || landings.length === 0)) {
            return null;
        }
        let targets = expanded === true ? landings : results;
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8,
                ...insetLabel && {
                    borderRadius: 8
                }
            }}>
                {targets.map((landing, index) => {
                    let disabled = workflow && landing.workflow && landing.workflow.id !== workflow.id && disableAssigned === true;
                    if(typeof(onRenderResult) === 'function') {
                        return onRenderResult(landing, index, results);
                    }
                    return (
                        Views.entry({
                            ...landing.getDescription(),
                            badge: null,
                            bottomBorder: index !== results.length - 1,
                            icon: {
                                ...landing.getDescription().icon,
                                imageStyle: {
                                    backgroundColor: disabled ? Appearance.colors.grey() : Appearance.colors.primary()
                                }
                            },
                            key: index,
                            onClick: onResultClick.bind(this, landing),
                            rightContent: disabled && (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 8
                                }}>
                                    {Views.icon.right('warning', onSelectAssignedLanding.bind(this, landing))}
                                </div>
                            )
                        })
                    )
                })}
            </div>
        )
    }

    const getIconProps = () => {
        if(selected) {
            return {
                prependComponent: (
                    <img
                    src={selected.getDescription().icon.path}
                    style={{
                        width: 25,
                        minWidth: 25,
                        height: 25,
                        minHeight: 25,
                        borderRadius: 12.5,
                        overflow: 'hidden',
                        objectFit: 'cover',
                        backgroundColor: Appearance.colors.primary(),
                        marginRight: 8
                    }} />
                )
            }
        }
        return { icon: icon || 'search' }
    }

    const fetchInitialLanding = async () => {
        try {
            let { landing } = await Request.get(utils, '/support/', {
                id: initialID,
                type: 'workflow_landing_details'
            });
            if(landing) {
                onResultClick(Support.Workflow.Landing.create(landing));
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        let target = value && typeof(value.getDescription) === 'function' ? value : null;
        setSelected(target);
        setText(target && target.getDescription().title);
    }, [value]);

    useEffect(() => {
        if(initialID) {
            fetchInitialLanding();
        }
    }, []);

    return (
        <div
        ref={containerRef}
        style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
            }}>
                <TextField
                {...getIconProps()}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                insetLabel={insetLabel}
                loading={loading}
                onChange={onTextChange}
                onValidate={showValidation ? onValidate : null}
                placeholder={insetLabel ? null : (placeholder || 'Search by landing id or name...')}
                useDelay={true}
                value={text}
                appendComponent={getAppendContent()}
                containerStyle={{
                    flexGrow: 1
                }}/>
            </div>
            {getContent()}
        </div>
    )
}
export default WorkflowLandingLookupField;
