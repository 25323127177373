import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

class TaskClass {

    action = null;
    archive_id = null;
    category = null;
    date = null;
    duration = null;
    exit_code = null;
    id = null;
    manual = false;
    namespace = null;
    platform = null;
    output = null;
    recurring = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.action = props.action;
        this.archive_id = props.archive_id;
        this.category = props.category;
        this.date = props.date && moment.utc(props.date).local();
        this.duration = props.duration;
        this.exit_code = props.exit_code;
        this.id = props.id;
        this.manual = props.manual;
        this.namespace = props.namespace;
        this.platform = props.platform;
        this.output = props.output;
        this.recurring = props.recurring;
        return this;
    }

    getAction = () => {
        if(!this.action) {
            return '';
        }
        if(this.action.includes('_') === false) {
            return Utils.ucFirst(this.action);
        }
        return this.action.split('_').map(val => Utils.ucFirst(val)).join(' ');
    }

    getColor = () => {
        switch(this.platform) {
            case 'aft':
            return '#4B90E2';

            case 'global_data':
            return '#2F343A';

            case 'graci':
            return '#9B9B9B';

            case 'omnishield':
            return Appearance.colors.omnishieldPrimary;

            default:
            return Appearance.colors.grey();
        }
    }

    getIcon = () => {
        switch(this.platform) {
            case 'aft':
            return 'images/aft-mobile-app-icon.jpg';

            case 'global_data':
            return 'images/global-data-mobile-app-icon.jpg';

            case 'graci':
            return 'images/aft_system_user.png';

            case 'omnishield':
            return 'images/omnishield-mobile-app-icon.jpg';

            default:
            return null;
        }
    }

    getPlatformName = () => {
        return Utils.getPlatformName(this.platform);
    }

    getTitle = () => {

        // tasks with a category specified are normally recurring, platform tasks
        if(this.category) {
            let category = this.category.split('_').map(string => Utils.ucFirst(string)).join(' ');
            return `${this.getPlatformName()}: ${category}`;
        }
        
        // tasks with an action specified are normally one-off background tasks
        return `${this.getPlatformName()}: ${this.getAction()}`;
    }
}

export default {
    new: () => new TaskClass(),
    create: props => new TaskClass().create(props)
}
