import React from 'react';
import App from 'App';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render((
    <GoogleOAuthProvider clientId={'63291071645-j46ie896pjrdc71d22q30rv5ei0cfheb.apps.googleusercontent.com'}>
        <App />
    </GoogleOAuthProvider>
));