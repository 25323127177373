import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import { CheckboxWrapper } from 'views/Checkbox.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const ProtectionFilters = ({ onChange, requireLocation = false, searchProps = {}, utils }) => {

    const viewID = useRef(`${moment().unix()}.${Utils.randomString(10)}`);

    const [cities, setCities] = useState([]);
    const [didFetchFilterOptions, setDidFetchFilterOptions] = useState(false);
    const [filterCategory, setFilterCategory] = useState(null);
    const [filterValues, setFilterValues] = useState({});
    const [fullProtections, setFullProtections] = useState([]);
    const [soldByUsers, setSoldByUsers] = useState([]);
    const [states, setStates] = useState([]);

    const onBulkSelectionButtonClick = (field, select_all) => {
        let ids = [];
        if(select_all) {
            ids = field.items.map(item => item.id);
        }
        field.onChange({
            ids: ids,
            items: field.items.map(item => {
                item.selected = select_all;
                return item;
            })
        });
    }

    const getBadge = (key, value) => {

        // no additional logic is needed if no filter values are available
        if(!filterValues || !filterValues[key]) {
            return 0;
        }

        // look for a traditional key based match
        let match = filterValues[key].find(entry => entry.title === value);
        return match ? match.value : 0;
    }

    const getBulkSelectionButtons = field => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: 8
            }}>
                <AltBadge
                onClick={onBulkSelectionButtonClick.bind(this, field, false)}
                content={{
                    text: 'Deselect All',
                    color: Appearance.colors.grey()
                }}
                style={{
                    width: '100%',
                    marginRight: 4,
                    padding: '5px 16px 5px 16px'
                }}/>
                <AltBadge
                onClick={onBulkSelectionButtonClick.bind(this, field, true)}
                content={{
                    text: 'Select All',
                    color: Appearance.colors.primary()
                }}
                style={{
                    width: '100%',
                    marginRight: 0,
                    marginLeft: 4,
                    padding: '5px 16px 5px 16px'
                }}/>
            </div>
        )
    }

    const getContent = () => {
        if(didFetchFilterOptions === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return getFilterOptions();
    }

    const getFields = () => {
        return [{
            key: 'locality',
            title: 'City',
            items: cities.map(locality => ({
                ...locality,
                badge: getBadge('locality', locality.title)
            })),
            onChange: ({ ids, items }) => {
                setCities(items);
                onChange('locality', ids);
            }
        },{
            key: 'full_protection',
            title: 'Full Protection',
            items: fullProtections.map(entry => ({
                ...entry,
                badge: getBadge('full_protection', entry.id)
            })),
            onChange: ({ ids, items }) => {
                setFullProtections(items);
                onChange('full_protection', ids);
            }
        },{
            key: 'date',
            title: 'Purchase Date',
        },{
            key: 'administrative_area_level_1',
            title: 'State',
            items: states.map(entry => ({
                ...entry,
                badge: getBadge('administrative_area_level_1', entry.title)
            })),
            onChange: ({ ids, items }) => {
                setStates(items);
                onChange('administrative_area_level_1', ids);
            }
        },{
            key: 'sold_by_user',
            lastItem: true,
            title: 'Sold By',
            items: soldByUsers.map(user => ({
                ...user,
                badge: getBadge('sold_by_user', user.id)
            })),
            onChange: ({ ids, items }) => {
                setSoldByUsers(items);
                onChange('sold_by_user', ids);
            }
        },{
            key: 'total_units',
            title: 'Total Units',
            onChange: response => {
                onChange('total_units', response);
            }
        }].filter(field => {
            if(field.visible === false) {
                return false;
            }
            if(['date', 'total_units'].includes(field.key)) {
                return true;
            }
            return field.items.length > 0;
        });
    }

    const getFilterOptions = () => {
        
        // prepare list of filters and prevent moving forward if no filters are returned
        let fields = getFields();
        if(fields.length === 0) {
            return null;
        }

        return (
            <div style={{
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                paddingBottom: 0,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 12
            }}>
                {fields.filter(field => {
                    return field.visible !== false && field.key !== 'total_units';
                }).map((field, index, fields) => {
                    if(field.key === 'date') {
                        return (
                            <LayerItem
                            collapsed={true}
                            headerStyle={Appearance.textStyles.title()}
                            lastItem={index === fields.length - 1}
                            title={field.title}
                            style={{
                                width: '100%'
                            }}>
                                <DualDatePickerField
                                canRemoveDates={true}
                                dateFormat={'MM/DD/YYYY'}
                                dateTime={false}
                                onEndDateChange={date => {
                                    onChange('end_date', date && date.format('YYYY-MM-DD'));
                                }}
                                onStartDateChange={date => {
                                    onChange('start_date', date && date.format('YYYY-MM-DD'));
                                }}
                                selectedEndDate={searchProps && searchProps.end_date}
                                selectedStartDate={searchProps && searchProps.start_date}
                                style={{
                                    width: 140
                                }} 
                                utils={utils} />
                            </LayerItem>
                        )
                    }
                    return (
                        <CheckboxWrapper
                        {...field}
                        collapsed={true}
                        key={index}
                        lastItem={index === fields.length - 1}
                        onVisibilityChange={collapsed => {
                            setFilterCategory(collapsed ? null : field.key);
                        }}
                        style={{
                            borderBottom: index !== fields.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                            ...index === fields.length - 1 && {
                                marginBottom: 0
                            }
                        }}>
                            {getBulkSelectionButtons(field)}
                        </CheckboxWrapper>
                    )
                })}
            </div>
        )
    }

    const fetchFilterTargets = async () => {
        try {

            // send request to server
            let { cities, states, users } = await Request.get(utils, '/dealerships/', {
                type: 'filter_targets'
            })

            // set all available targets
            setCities(cities.filter(target => {
                return target !== null;
            }).map(target => ({
                id: target,
                selected: true,
                title: target
            })));
            setFullProtections([{
                id: true,
                selected: false,
                title: 'Yes'
            },{
                id: false,
                selected: false,
                title: 'No'
            }]);
            setStates(states.filter(target => {
                return target !== null;
            }).map(target => ({
                id: target,
                selected: true,
                title: target
            })));
            setSoldByUsers(users.map(target => ({
                id: target.user_id,
                selected: false,
                title: target.full_name
            })));

            // update local flag for filter target fetch
            setDidFetchFilterOptions(true);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the filter options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchFilterValues = async () => {
        try {

            // prevent moving forward if no filter category has been set
            if(!filterCategory) {
                return;
            }

            // use previously fetched fitler values if applicable
            if(filterValues[filterCategory]) {
                return;
            }

            // submit request to server
            let { values } = await Request.get(utils, '/cards/', {
                category: filterCategory,
                require_location: requireLocation,
                search_props: searchProps,
                type: 'filter_values'
            });

            // update local state with filter values for requested category
            setFilterValues(props => {
                return update(props, {
                    [filterCategory]: {
                        $set: values
                    }
                });
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the protection filter values. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(filterCategory) {
            fetchFilterValues();
        }
    }, [filterCategory]);

    useEffect(() => {

        fetchFilterTargets();
        utils.events.on(viewID.current, 'dealership_change', () => {
            setFilterValues({});
        });
        utils.content.subscribe(viewID.current, 'card', {
            onFetch: setFilterValues.bind(this, {})
        });
        return () => {
            utils.events.off(viewID.current, 'dealership_change');
            utils.content.unsubscribe(viewID.current);
        }

    }, []);

    return getContent();
}

export default ProtectionFilters;