import React, { useRef, useState, useEffect } from 'react';
import { onNotificationAction } from 'managers/Users.js';

import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const DesktopNotification = ({ notification, onClose, utils }) => {

    const { message, onClick, title } = notification;
    const interacted = useRef(null);
    const [opacity, setOpacity] = useState(0);
    const [top, setTop] = useState(-250);

    const onCloseNotification = async () => {
        return new Promise(async resolve => {
            try {
                setTop(-250);
                setOpacity(0);
                await Utils.sleep(0.5);
                if(typeof(onClose) === 'function') {
                    onClose();
                }
                resolve();
            } catch(e) {
                console.log(e.message);
            }
        });
    }

    const onCloseNotificationClick = async e => {
        try {
            e.stopPropagation();
            interacted.current = true;
            await onCloseNotification();
        } catch(e) {
            console.log(e.message);
        }
    }

    const onNotificationClick = async () => {
        try {
            interacted.current = true;
            await onCloseNotification();

            // use explicitly added onClick handler if found
            if(typeof(onClick) === 'function') {
                onClick();
                return;
            }
            // fallback to pre-defined notification actions
            onNotificationAction(utils, notification);

        } catch(e) {
            console.log(e.message);
        }
    }

    const setupContent = async () => {
        try {

            await Utils.sleep(0.5);
            setTop(15);
            setOpacity(1);

            // Runs for 7.5 seconds and checks whether user has interacted
            let index = 0;
            let interval = setInterval(() => {
                if(interacted.current) {
                    clearInterval(interval);
                    return;
                }
                if(index >= 30) {
                    clearInterval(interval);
                    onCloseNotification();
                    return;
                }
                index++;
            }, 250);

        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        setupContent();
    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            width: '100%',
            zIndex: 9500
        }}>
            <VelocityComponent
            animation={{ opacity }}
            duration={500}>
                <div style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,${window.theme === 'dark' ? '0.5' : '0.25'}), rgba(0,0,0,0))`,
                    height: 250,
                    position: 'absolute',
                    width: '100%'
                }} />
            </VelocityComponent>

            <VelocityComponent
            easing={[250, 20]}
            duration={750}
            animation={{ top: top }}>
                <div style={{
                    backgroundColor: Appearance.colors.sheet(),
                    borderRadius: 8,
                    overflow: 'hidden',
                    position: 'absolute',
                    textAlign: 'center',
                    maxWidth: window.innerWidth > 350 ? 400 : (window.innerWidth - 30),
                    minWidth: window.innerWidth > 350 ? 350 : (window.innerWidth - 30),
                    zIndex: 9999
                }}>
                    <div
                    className={'cursor-pointer'}
                    onClick={onNotificationClick}
                    style={{
                        width: '100%'
                    }}>
                        <div style={{
                            padding: 12
                        }}>
                            <div style={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    minWidth: 0,
                                    paddingLeft: 8,
                                    textAlign: 'left',
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        marginBottom: 2,
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis'
                                    }}>{title}</span>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        whiteSpace: 'break-spaces'
                                    }}>{message}</span>
                                </div>
                                <img
                                className={'text-button'}
                                src={'images/close-button-light-small.png'}
                                onClick={onCloseNotificationClick}
                                style={{
                                    width: 20,
                                    height: 20,
                                    minWidth: 20,
                                    minHeight: 20,
                                    marginLeft: 8
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </VelocityComponent>
         </div>
    )
}
export default DesktopNotification;
