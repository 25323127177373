import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import { AddEditDealership } from 'managers/Dealerships.js';
import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Views from 'views/Main.js';

const DealershipLookupField = ({ addNewDealership, containerStyle, icon, inline, onChange, placeholder, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const onSelectDealership = async selectedDealership => {

        setResults([]);
        setSelected(selectedDealership);
        setText(selectedDealership.name);

        if(typeof(onChange) === 'function') {
            onChange(selectedDealership);
        }
    }

    const onNewDealership = () => {
        let dealership = Dealership.new();
        utils.layer.open({
            id: 'new_dealership',
            abstract: Abstract.create({
                type: 'dealership',
                object: dealership
            }),
            Component: AddEditDealership.bind(this, {
                isNewTarget: true,
                requireDealer: false,
                onAddDealership: onSelectDealership
            })
        })
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {
            try {
                if(text.length < 3) {
                    setResults([]);
                    setLoading(false);
                    if(typeof(onChange) === 'function') {
                        onChange(null);
                    }
                    return;
                }
                let { dealerships } = await Request.get(utils, '/dealerships/', {
                    limit: 100,
                    search_text: text,
                    type: 'lookup'
                })
                setLoading(false);
                setResults(dealerships);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for dealerships. ${e.message || 'An unknown error occurred'}`
                });
            }

        }, 250));
    }

    const getAccessoryButton = () => {
        if(utils.user.get().level > User.levels.get().admin || addNewDealership === false) {
            return null;
        }
        return (
            <div style={{
                width: 25,
                height: 25,
                minWidth: 25,
                minHeight: 25,
                marginLeft: 12
            }}>
                <img
                className={'text-button'}
                src={'images/plus-button-blue-small.png'}
                onClick={onNewDealership}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }} />
            </div>
        )
    }

    const getStyles = () => {
        if(inline) {
            return {
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                border: `1px solid ${Appearance.colors.divider()}`,
                borderRadius: 12,
                marginBottom: 12,
                maxHeight: 500,
                marginTop: 8,
                overflowX: 'hidden',
                overflowY: 'scroll',
                padding: 0,
                textAlign: 'left'
            }
        }
        return {
            position: 'absolute',
            top: 43,
            left: 0,
            right: 0,
            padding: 0,
            maxHeight: 250,
            overflowY: 'scroll',
            textAlign: 'left',
            borderRadius: 8,
            zIndex: FloatingMenuIndex,
            border: `1px solid rgba(175,175,175,0.25)`,
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
            boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)'
        }
    }

    useEffect(() => {
        setSelected(value);
        setText(value ? value.name : null);
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <TextField
                value={text}
                icon={icon}
                iconStyle={{
                    color: selected ? Appearance.colors.primary() : Appearance.colors.softBorder()
                }}
                loading={loading}
                placeholder={placeholder || 'Search by Dealership name...'}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                onChange={onTextChange}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
                {getAccessoryButton()}
            </div>
            {results.length > 0 && (
                <div
                className={`dummy-field ${window.theme}`}
                style={getStyles()}>
                    {results.map((dealership, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onSelectDealership.bind(this, dealership),
                                subTitle: dealership.dealer ? dealership.dealer.full_name : 'No Dealer Found',
                                title: dealership.name
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default DealershipLookupField;
