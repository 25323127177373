import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class NotificationClass {

    id = null;
    unread = false;
    title = null;
    message = null;
    date = null;
    category = null;
    to_user = null;
    from_user = null;
    for_level = null;
    payload = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.unread = props.unread;
        this.title = props.title;
        this.message = props.message;
        this.date = props.date;
        this.to_user = props.to_user;
        this.from_user = props.from_user;
        this.for_level = props.for_level;
        this.category = props.category;
        this.payload = props.payload;
        return this;
    }

    setAsRead = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this.unread) {
                    resolve();
                    return;
                }
                await Request.post(utils, '/notifications/', {
                    type: 'set_as_read',
                    id: this.id
                });

                this.unread = false;
                utils.content.update(Abstract.create({
                    type: 'notification',
                    object: this
                }))
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    create: props => new NotificationClass().create(props)
};
