import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class SectorClass {

    id = null;
    type = null;
    name = null;
    date = null;
    director = null; // specialty data point
    director_user_id = null;
    active = null;
    avatar = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.type = props.type;
        this.name = props.name;
        this.date = props.date ? moment(props.date) : null;
        this.director = props.director ? User.create(props.director) : null;
        this.director_user_id = props.director_user_id;
        this.active = props.active;
        this.avatar = props.avatar;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                name: this.name,
                type: this.type,
                director: this.director
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                name: props.name || this.edits.name,
                type: props.type || this.edits.type,
                director: props.director || this.edits.director
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.name = this.edits.name || this.name;
            this.type = this.edits.type || this.type;
            this.director = this.edits.director || this.director;
            resolve();
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/sectors/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                await this.close();
                utils.content.fetch('user');
                utils.content.fetch('sector');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/sectors/', {
                    type: 'update',
                    ...this.toJSON(this.edits)
                })

                await this.close();
                utils.content.fetch('user');
                utils.content.update(Abstract.create({
                    type: 'sector',
                    object: this
                }));
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            name: target.name,
            sector_type: target.type,
            director_user_id: target.director ? target.director.user_id : null
        }
    }
}

const fetchSector = async (utils, id, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { sector } = await Request.get(utils, '/sectors/', {
                type: 'details',
                id: id,
                sector_type: type
            });
            resolve(new SectorClass().create(sector));
        } catch(e) {
            reject(e);
        }
    })
}

const types = {
    area: 1,
    division: 2,
    region: 3
}

const typeToText = type => {
    switch(type) {
        case types.area:
        return 'Area';

        case types.division:
        return 'Division';

        case types.region:
        return 'Region';

        default:
        return 'Unknown';
    }
}

export default {
    new: () => new SectorClass(),
    get: (utils, id, type) => fetchSector(utils, id, type),
    create: props => new SectorClass().create(props),
    types: types,
    typeToText: typeToText
}
