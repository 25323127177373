import API from 'files/api.js';
import SocketIO from 'socket.io-client';
import Utils from 'files/Utils.js';

class SocketManagerClass {

    auth = {};
    sockets = {};
    targets = {
        aft: ['cards', 'notifications', 'omnishield', 'sectors', 'system', 'tasks', 'users'],
        omnishield: ['comm_links']
    };

    constructor() {
        return this;
    }

    connect = async (user, channel) => {
        return new Promise(async (resolve, reject) => {

            // prepare authorization object
            this.auth[channel] = {
                token: user.token,
                user_id: user.user_id,
                version: API.version
            };

            // prepare default entry for main socket endpoint
            let url = channel === 'aft' ? API.sockets : API.external[channel].sockets;
            this.sockets[channel] = {
                main: new SocketIO(url, {
                    autoConnect: true,
                    forceNew: false,
                    jsonp: false,
                    query: { auth: JSON.stringify(this.auth[channel]) },
                    reconnection: true,
                    reconnectionAttempts: 30,
                    reconnectionDelay: 10000,
                    transports: ['websocket', 'polling'],
                    upgrade: true
                })
            };

            // register general error handler
            this.sockets[channel].main.on('error', e => {
                console.error(`[${channel}-websockets]: ${e.message}`);
            });

            // register connection timeout error handler, only connection errors will reject the promise
            this.sockets[channel].main.on('connect_timeout', e => {
                console.error(`[${channel}-websockets]: ${e.message}`);
                let error = new Error('The request to connect to our live update server has timed-out');
                reject(error);
            });

            // register connection error handler, only connection errors will reject the promise
            this.sockets[channel].main.on('connect_error', e => {
                console.error(`[${channel}-websockets]: ${e.message}`);
                let error = new Error('We ran into an issue while trying to connect to our live update server');
                reject(error);
            });

            // register disconnection handler, occasionally disconnections come from the load balancer closing an inactive connection
            this.sockets[channel].main.on('disconnect', reason => {
                console.error(`[${channel}-websockets]: disconnected ${reason}`);
            });

            // register connection handler
            this.sockets[channel].main.on('connect', async () => {

                // loop through socket targets and connect
                console.log(`[${channel}-websockets]: connected to ${channel}`);
                this.targets[channel].forEach(target => {

                    // reopen connection if target already exists
                    if(this.sockets[channel][target]) {
                        console.warn(`[${channel}-websockets]: reusing existing connection for ${channel}/${target}`);
                        return;
                    }

                    // open new connection to target 
                    console.log(`[${channel}-websockets]: connecting to ${channel}/${target}`);
                    this.sockets[channel][target] =  this.sockets[channel].main.io.socket(`/${target}`, {
                        autoConnect: true,
                        forceNew: true,
                        jsonp: false,
                        query: { auth: JSON.stringify(this.auth[channel]) },
                        reconnection: true,
                        reconnectionAttempts: 30,
                        reconnectionDelay: 10000,
                        transports: ['websocket', 'polling']
                    });
                });
                resolve();
            });
        })
    }

    emit = async (channel, socketKey, key, data) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[channel][socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[channel][socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key) {
                    throw new Error(`Missing required parameters for socket emit`);
                }
                this.sockets[channel][socketKey].emit(key, {
                    ...data,
                    auth: this.auth[channel]
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    off = async (channel, socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[channel][socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[channel][socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key || !callback) {
                    throw new Error(`Missing required parameters for socket off`);
                }
                this.sockets[channel][socketKey].off(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    on = async (channel, socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[channel][socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[channel][socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key || !callback) {
                    throw new Error(`Websocket key and callback are required for subscriptions`);
                }
                this.sockets[channel][socketKey].on(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    persistEmit = (channel, socketKey, key, data) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.emit(channel, socketKey, key, data);
                clearInterval(interval);
                console.log(`[${channel}-websockets]: data sent to ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`[${channel}-websockets]: unable to connect to ${socketKey}:${key}`);
                    return;
                }
                console.log(`[${channel}-websockets]: waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }

    persistOn = async (channel, socketKey, key, callback) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.on(channel, socketKey, key, callback);
                clearInterval(interval);
                console.log(`[${channel}-websockets]: listener added for ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`[${channel}-websockets]: unable to add ${socketKey}:${key} listener`);
                    return;
                }
                console.log(`[${channel}-websockets]: waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }
}

export default {
    new: () => new SocketManagerClass()
};
