import React, { useEffect, useState } from 'react';

import circle from '@turf/circle';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance.js';
import CustomSlider from 'views/CustomSlider.js';
import Layer from 'structure/Layer.js';
import { Map } from 'views/MapElements.js';
import Views from 'views/Main.js';

const LocationRadiusSelector = ({ locations, onChange }, { index, options, utils }) => {

    const layerID = 'location_radius_selector';
    const [layerState, setLayerState] = useState(null);
    const [selections, setSelections] = useState(locations || []);

    const onAddLocation = location => {
        setSelections(selections => {
            return update(selections, {
                $unshift: [{
                    ...location,
                    radius: 5
                }]
            });
        });
    }

    const onChangeRadius = (index, value) => {
        setSelections(selections => {
            return update(selections, {
                [index]: {
                    radius: {
                        $set: value
                    }
                }
            });
        });
    }

    const onRemoveLocation = index => {
        setSelections(selections => {
            return update(selections, {
                $splice: [[index, 1]]
            });
        });
    }

    const onSaveLocations = () => {

        // close layer and notify subscribers of data change
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(selections);
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            onClick: onSaveLocations,
            text: 'Save Changes'
        }]
    }

    const getCircles = () => {
        return selections.map(selection => {
            return circle([selection.location.longitude, selection.location.latitude], selection.radius, {
                steps: 90, 
                units: 'miles'
            });
        })
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Location Radius Selector'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <Map
            circles={getCircles()}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            style={{
                height: 350,
                width: '100%'
            }}/>

            <AddressLookupField
            containerStyle={{
                marginTop: 8
            }}
            onChange={onAddLocation}
            placeholder={'Search by address or point of interest...'}
            preserveResult={false}
            shouldShowCustomFields={false}
            utils={utils} />

            {selections.map((location, index) => {
                return (
                    <div 
                    key={index}
                    style={{  
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 8,
                        width: '100%'
                    }}>
                        {Views.entry({
                            hideIcon: true,
                            rightContent: (
                                <img 
                                className={'text-button'}
                                onClick={onRemoveLocation.bind(this, index)}
                                src={'images/red-x-icon.png'}
                                style={{
                                    height: 20,
                                    marginLeft: 8,
                                    width: 20
                                }} />
                            ),
                            subTitle: `${location.radius} mile radius`,
                            title: location.name || location.address,
                        })}
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 16
                        }}>
                            <CustomSlider
                            step={1}
                            min={1}
                            max={100}
                            value={location.radius}
                            onChange={(_, value) => onChangeRadius(index, value)} />
                        </div>
                    </div>
                )
            })}
        </Layer>
    )
}

export default LocationRadiusSelector;