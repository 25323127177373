import React, { useEffect, useRef, useState } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Support from 'classes/Support.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const WorkflowItemLookupField = ({ containerStyle, disableAssigned, icon, inline = true, insetLabel, onChange, onRenderResult, placeholder, preserveResult, showValidation, utils, value, workflow = {} }) => {

    const containerRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offsetY, setOffsetY] = useState(0);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [targets, setTargets] = useState([]);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onArrowClick = () => {
        setExpanded(!expanded);
    }

    const onRemoveSelectedItem = () => {
        setText('');
        setSelected(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onResultClick = abstract => {

        // prevent moving forward if assignment has already been made and disableAssigned is truthy
        if(abstract.object.workflow && abstract.object.workflow.id !== workflow.id && disableAssigned === true) {
            onSelectAssignedItem(null, abstract);
            return;
        }

        // hide results list, update selected abstract, and update text field label
        setResults([]);
        setSelected(abstract);
        setExpanded(false);
        setText(abstract.object.getDescription().title);

        // remove result text from text field and update selected value if applicable
        if(preserveResult === false) {
            setText('');
            setSelected(null);
        }

        // send selected abstract back to subscribers
        if(typeof(onChange) === 'function') {
            onChange(abstract);
        }
    }

    const onScroll = () => {
        setExpanded(false);
    }

    const onSelectAssignedItem = (evt, abstract) => {
        if(evt) {
            evt.stopPropagation();
        }
        utils.alert.show({
            title: `${getAbstractTypeTitle(abstract)} Already Assigned`,
            message: `This ${getAbstractTypeTitle(abstract).toLowerCase()} is assigned to the "${abstract.object.workflow.name}" support workflow. Please remove it from this workflow if you wish to reassign it.`
        });
    }

    const onTextChange = text => {

        // clear pending search text timeout if applicable
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        // update textfield label, remove previously selected result, and begin request loading
        setText(text);
        setLoading(true);
        setSelected(null);
        setExpanded(false);

        // prepare next search text timeout
        setTextTimeout(async () => {
            try {

                // prevent moving forward if search text length is less than three characters
                await Utils.sleep(0.25);
                if(!text || (isNaN(text) && text.length < 3)) {
                    setResults([]);
                    setLoading(false);
                    return;
                }

                // send auto complete request to server
                let { landings, rules } = await Request.get(utils, '/support/', {
                    type: 'all_workflow_items',
                    limit: 5,
                    search_text: text,
                    show_unassigned: true,
                    workflow_id: workflow.id
                });

                let targets = landings.map(landing => {
                    return Abstract.create({
                        type: 'support_workflow_landings',
                        object: Support.Workflow.Landing.create(landing)
                    });
                }).concat(rules.map(rule => {
                    return Abstract.create({
                        type: 'support_workflow_rules',
                        object: Support.Workflow.Rule.create(rule)
                    });
                }));
                setLoading(false);
                setResults(targets);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
                });
            }

        });
    }

    const onValidate = () => {
        return selected ? true : false
    }

    const getAbstractTypeTitle = abstract => {
        switch(abstract.type) {
            case 'workflow_actions':
            return 'Action';

            case 'support_workflow_landings':
            return 'Landing';

            case 'support_workflow_rules':
            return 'Rule';

            default:
            return 'Unknown action';
        }
    }

    const getAppendContent = () => {
        if((text && text.length > 0) || targets.length === 0 && selected === null) {
            return null;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                right: 12,
                height: '100%',
                paddingLeft: 30,
                background: `linear-gradient(90deg, ${Appearance.colors.textField(0)}, ${Appearance.colors.textField(0.9)}, ${Appearance.colors.textField()})`
            }}>
                <img
                className={'cursor-pointer'}
                onClick={onArrowClick}
                src={expanded ? 'images/up-arrow-grey-small.png' : 'images/down-arrow-grey-small.png'}
                style={{
                    height: 12,
                    width: 12,
                    objectFit: 'contain'
                }} />
                {selected && Views.icon.remove({ onClick: onRemoveSelectedItem })}
            </div>
        )
    }

    const getContent = () => {
        if(results.length === 0 && (expanded === false || targets.length === 0)) {
            return null;
        }
        let items = expanded === true ? targets : results;
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8,
                ...insetLabel && {
                    borderRadius: 8
                }
            }}>
                {items.map((abstract, index) => {
                    let disabled = abstract.object.workflow && abstract.object.workflow.id !== workflow.id && disableAssigned === true;
                    if(typeof(onRenderResult) === 'function') {
                        return onRenderResult(abstract, index, results);
                    }
                    return (
                        Views.entry({
                            ...abstract.object.getDescription(),
                            badge: null,
                            bottomBorder: index !== results.length - 1,
                            icon: {
                                ...abstract.object.getDescription().icon,
                                imageStyle: {
                                    backgroundColor: disabled ? Appearance.colors.grey() : Appearance.colors.primary()
                                }
                            },
                            key: index,
                            onClick: onResultClick.bind(this, abstract),
                            rightContent: disabled && (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 8
                                }}>
                                    {Views.icon.right('warning', onSelectAssignedItem.bind(this, abstract))}
                                </div>
                            )
                        })
                    )
                })}
            </div>
        )
    }

    const getIconProps = () => {
        if(selected) {
            return {
                prependComponent: (
                    <img
                    src={selected.getDescription().icon.path}
                    style={{
                        width: 25,
                        minWidth: 25,
                        height: 25,
                        minHeight: 25,
                        borderRadius: 12.5,
                        overflow: 'hidden',
                        objectFit: 'cover',
                        backgroundColor: Appearance.colors.primary(),
                        marginRight: 8
                    }} />
                )
            }
        }
        return { icon: icon || 'search' }
    }

    const fetchItems = async () => {
        try {
            let { landings, rules } = await Request.get(utils, '/support/', {
                type: 'all_workflow_items',
                limit: 100,
                show_unassigned: true,
                workflow_id: workflow.id
            });

            let targets = landings.map(landing => {
                return Abstract.create({
                    type: 'support_workflow_landings',
                    object: Support.Workflow.Landing.create(landing)
                });
            }).concat(rules.map(rule => {
                return Abstract.create({
                    type: 'support_workflow_rules',
                    object: Support.Workflow.Rule.create(rule)
                });
            }));
            setTargets(targets);

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        setSelected(value);
        setText(value ? value.object.getDescription().title : null);
    }, [value]);

    useEffect(() => {
        fetchItems();
    }, []);

    return (
        <div
        ref={containerRef}
        style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <TextField
                {...getIconProps()}
                appendComponent={getAppendContent()}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                insetLabel={insetLabel}
                loading={loading}
                onChange={onTextChange}
                onValidate={showValidation ? onValidate : null}
                placeholder={insetLabel ? null : (placeholder || 'Search by landing or rule name...')}
                useDelay={true}
                value={text}
                containerStyle={{
                    flexGrow: 1
                }}/>
            </div>
            {getContent()}
        </div>
    )
}
export default WorkflowItemLookupField;
