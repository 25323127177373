import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Appearance from 'styles/Appearance.js';
import Slider from '@material-ui/core/Slider';

const CustomSlider = withStyles({
    root: {
        color: Appearance.colors.primary(),
        height: 2,
        padding: '2px 0',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        marginTop: -8,
        marginLeft: -10,
        border: `2px solid ${Appearance.colors.softBorder()}`,
        '&:focus, &:hover, &$active': {
            boxShadow: 'none'
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        height: 3,
        opacity: 1,
        backgroundColor: Appearance.colors.softBorder(),
    },
    mark: {
        backgroundColor: Appearance.colors.primary(),
        height: 8,
        width: 1
    },
    markActive: {
        opacity: 1,
        backgroundColor: Appearance.colors.green,
    }
})(Slider);

export default CustomSlider;

export const StatefulSlider = props => {

    let { defaultValue, max, min, onChange, showCounter, step } = props;
    const [value, setValue] = useState(defaultValue);

    const onValueChange = (evt, value) => {
        setValue(parseInt(value));
        if(typeof(onChange) === 'function') {
            onChange(parseInt(value));
        }
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
        }}>
            <CustomSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onValueChange} />
            {showCounter && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 42,
                    height: 30,
                    borderRadius: 8,
                    marginLeft: 12,
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{value}</span>
                </div>
            )}
        </div>
    )
}
