import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import TextField, { getIcon } from 'views/TextField.js';
import Utils from 'files/Utils.js';

const MonthYearPicker = ({ onChange, style, utils, value }) => {

    const [month, setMonth] = useState(value ? moment(value).format('MMMM') : moment().format('MMMM'));
    const [months, setMonths] = useState([]);
    const [year, setYear] = useState(value ? moment(value).format('YYYY') : moment().format('YYYY'));
    const [years, setYears] = useState([]);

    const onMonthChange = evt => {
        let index = Utils.attributeForKey.select(evt, 'id');
        setMonth(months[index]);
    }

    const onYearChange = evt => {
        let index = Utils.attributeForKey.select(evt, 'id');
        setYear(years[index]);
    }

    const onParseSelection = () => {
        if(!month || !year) {
            return;
        }
        let start = moment(`${year}-${month}-01`, 'YYYY-MMMM-DD');
        let end = moment(start).endOf('month');
        if(typeof(onChange) === 'function') {
            onChange({
                end: end,
                start: start
            });
        }
    }

    const setupTarget = () => {
        setMonths([ ...Array(12) ].map((_, index) => moment().startOf('year').add(index, 'months').format('MMMM')));
        setYears([ ...Array(10) ].map((_, index) => moment().subtract(index, 'years').format('YYYY')));
    }

    useEffect(() => {
        onParseSelection();
    }, [month, year]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <div
        className={'row p-0 m-0'}
        style={{
            width: '100%',
            ...style
        }}>
            <div className={'col-12 col-md-6 p-0 pr-md-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={month}
                onChange={onMonthChange}
                style={{
                    marginRight: 8
                }}>
                    {months.map((month, index) => {
                        return <option key={index} id={index}>{month}</option>
                    })}
                </select>
            </div>
            <div className={'col-12 col-md-6 p-0 pl-md-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={year}
                onChange={onYearChange}
                style={{
                    marginRight: 8
                }}>
                    {years.map((year, index) => {
                        return <option key={index} id={index}>{year}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
export default MonthYearPicker;
