import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const GooglePlaceLookupField = ({ containerStyle, icon, onChange, placeholder, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [text, setText] = useState('');

    const onClick = async place => {

        // clear results and update label text with place name
        setText(place.name);
        setResults([]);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(place);
        }
    }

    const onTextChange = async text => {
        try {

            // start loading flag 
            setText(text);

            // prevent moving forward if less than 3 characters were provided
            if(text.length < 3) {

                // remove aging results
                setResults([]);

                // notify subscribers of data change
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // send request to server
            setLoading(true);
            let { places } = await Request.post(utils, '/resources/', {
                search_text: text,
                type: 'google_place_lookup'
            });

            // end loading and update local state with results
            console.log(places);
            setLoading(false);
            setResults(places);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setText(value && value.name);
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            autoCapitalize={'sentences'}
            autoComplete={false}
            autoCorrect={false}
            icon={icon || 'house'}
            loading={loading}
            onChange={onTextChange}
            placeholder={placeholder}
            useDelay={true}
            value={text}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }} />
            {results.length > 0 && (
                <div style={{
                    backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                    borderColor: Appearance.colors.softBorder(),
                    borderRadius: 12,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    marginBottom: 12,
                    marginTop: 8,
                    overflow: 'hidden',
                    padding: 0,
                    textAlign: 'left'
                }}>
                    {results.map((result, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onClick.bind(this, result),
                                style: { padding: '6px 12px 6px 12px' },
                                subTitle: result.address,
                                title: result.name
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default GooglePlaceLookupField;
