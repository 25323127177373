import React, { useEffect, useState } from 'react';
import ListField from 'views/ListField.js';
import Request from 'files/Request.js';

const GlobalDataSurveyLookupField = ({ category, containerStyle, dealershipID, onChange, placeholder, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [surveys, setSurveys] = useState([]);

    const onItemChange = async item => {

        // update state for selected survey
        let target = surveys.find(survey => item && survey.id === item.id);
        setSelected(target);

        // notify subscribers of selection change
        if(typeof(onChange) === 'function') {
            onChange(target);
        }
    }

    const getItems = () => {
        return surveys.map(survey => ({
            id: survey.id,
            title: survey.name
        }))
    }

    const fetchSurveys = async () => {
        try {

            // set loading flag and send request to server
            setLoading(true);
            let response = await Request.get(utils, '/dealerships/', {
                category: category,
                dealership_id: dealershipID,
                type: 'global_data_surveys'
            });

            // end loading and update state with results
            setLoading(false);
            setSurveys(response.surveys);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of surveys. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setSelected(value && surveys.find(survey => survey.id === value.id));
    }, [surveys, value]);

    useEffect(() => {
        fetchSurveys();
    }, []);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            items={getItems()}
            loading={loading}
            onChange={onItemChange}
            placeholder={placeholder || 'Select a survey from the list...'}
            utils={utils}
            value={selected && selected.name} />
        </div>
    )
}
export default GlobalDataSurveyLookupField;
