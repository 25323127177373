import React, { useRef, useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Draggable from 'react-draggable';
import ProgressBar from 'views/ProgressBar.js';
import ResizeObserver from 'react-resize-observer';
import TextField from 'views/TextField.js';

export const CalloutIndex = 4950;
export const FloatingLayerMenuIndex = 7500;
export const FloatingMenuIndex = 4550;
export const EndIndex = 4990;
export const FrontIndex = EndIndex + 10;
export const LayerItemSpacing = 25;

export const getLayerSizingWidth = sizing => {
    switch(sizing) {
        case 'small':
        return 400;

        case 'medium':
        return 550;

        case 'extra_large':
        return window.innerWidth < 1000 ? window.innerWidth : 1000;

        case 'fullscreen':
        return window.innerWidth - 48;

        default:
        return 700;
    }
}

const Layer = ({ buttons, children, header, id, options = {}, title, utils }) => {

    const { layerState, onRequestClose, rightButton } = options;

    const buttonContainer = useRef(null);
    const headerRef = useRef(null);
    const ref = useRef(null);
    const scrollContainer = useRef(null);
    const stickyBottom = useRef(null);

    const [animations, animationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 0,
        transform: 'scale(0.75)'
    }));
    const [dragging, setDragging] = useState(false);
    const [height, setHeight] = useState(0);
    const [layerPosition, setLayerPosition] = useState(options.position);
    const [size, setSize] = useState({
        height: window.innerHeight,
        type: options.sizing || 'large',
        width: window.innerWidth
    });
    const [zIndex, setZIndex] = useState(options.zIndex);

    const onAnimateComponentsIntoView = () => {
        animationsApi.start({ opacity: 1, transform: 'scale(1)' })
    }

    const onDragEnded = (_, node) => {

        // update layer position
        setDragging(false);
        setLayerPosition({ x: node.x, y: node.y });

        // notify subscribers of reposition if applicable
        if(typeof(options.onReposition) === 'function') {
            options.onReposition({
                id: id,
                position: {
                    x: node.x,
                    y: node.y
                }
            });
        }
    }

    const onDragMoved = () => {
        setDragging(true);
    }

    const onDragStarted = () => {
        // trigger a layer press when drag has started in order to bring layer to the foreground
        onLayerPress();
    }

    const onLayerAction = ({ detail }) => {
        if(detail.layerID === id) {
            onLayerStateChange(detail.action);
        }
    }

    const onLayerPress = () => {
        setZIndex(FrontIndex);
        options.onSetLayerIndex(id);
    }

    const onMouseEnter = () => {
        document.body.style.overflowY = 'hidden';
    }

    const onMouseLeave = () => {
        document.body.style.overflowY = 'scroll';
    }

    const onToggleFullscreen = () => {

        // reset layer position to center of screen if previous screen state was fullscreen
        if(size.type === 'fullscreen') {
            setLayerPosition({
                x: (size.width / 2) - (getLayerSizingWidth(options.sizing) / 2),
                y: height ? ((size.height / 2) - (height / 2)) : 50
            });
        }

        // update state for size type
        let next = size.type === 'fullscreen' ? (options.sizing || 'large') : 'fullscreen'
        setSize(props => ({
            ...props,
            type: next
        }));

        // notify subscribers of size change
        if(typeof(options.onSizingChange) === 'function') {
            options.onSizingChange(next);
        }
    }

    const onUpdateHeight = rect => {

        // update height state
        setHeight(rect.height);
        
        // notify subscribers of height change if applicable
        if(typeof(options.onUpdateHeight) === 'function') {
            options.onUpdateHeight(rect.height)
        }
    }

    const onLayerStateChange = async state => {
        try {

            // determine if a close action was received
            if(state === 'close') {

                // determine if a pre-close confirmation needs to be requested
                if(shouldRequestClose() === true) {
                    await utils.alert.showAsync({
                        title: `Close Layer`,
                        message: 'Are you sure that you want to close this layer? All unsaved changes will be lost.',
                        buttons: [{
                            key: 'confirm',
                            title: 'Yes',
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Close',
                            style: 'default'
                        }],
                        onClick: (key, resolve, reject) => {
                            if(key === 'confirm') {
                                resolve();
                                return;
                            }
                            let error = new Error('user rejected close');
                            reject(error);
                        }
                    });
                }

                // update animation values for layer
                animationsApi.start({ opacity: 0, transform: 'scale(0.75)' });

                // re-enable body scrolling 
                document.body.style.overflowY = 'scroll';

                // notify subscribers of layer close if applicable
                if(typeof(options.onClose) === 'function') {
                    setTimeout(options.onClose.bind(this, id), 500);
                }
                return;
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onWindowSizeChange = () => {
        setSize(props => ({
            ...props,
            height: window.innerHeight,
            width: window.innerWidth
        }))
    }

    const getButtons = () => {
        let targets = buttons ? buttons.filter(button => button.visible !== false) : [];
        return targets.length > 0 && (
            <div
            ref={buttonContainer}
            style={{
                padding: 12,
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'row p-0 m-0'}>
                    {targets.map((button, index, buttons) => {
                        let paddingX = 'px-0';
                        if(buttons.length === 2) {
                            paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                        } else if(buttons.length > 2) {
                            paddingX = index === 1 ? 'px-2' : 'px-0';
                        }
                        return (
                            <div
                            key={index}
                            className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                <Button
                                {...button}
                                label={button.text}
                                type={'large'}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    const getFilterOptions = () => {
        if(!options.filter) {
            return null;
        }
        return (
            <div
            className={'not-draggable'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 15
            }}>
                <TextField
                icon={'search'}
                placeholder={options.filter.placeholder || 'Search for something...'}
                onChange={options.filter.onChange} />
            </div>
        )
    }

    const getHeaderContent = () => {
        return (
            <div
            className={dragging ? 'cursor-grabbing' : 'cursor-grab'}
            style={{
                position: 'relative'
            }}>
                <div style={Appearance.styles.header()}>
                    {header}
                    <div
                    ref={headerRef}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        minWidth: 0,
                        width: '100%'
                    }}>
                        <div
                        className={'not-draggable'}
                        style={{
                            width: 100
                        }}>
                            <AltBadge
                            onClick={onLayerStateChange.bind(this, 'close')}
                            content={{
                                color: Appearance.colors.red,
                                text: 'Close'
                            }}
                            style={{
                                width: 60
                            }}/>
                        </div>

                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: 0
                        }}>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 14,
                                fontWeight: 700,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textAlign: 'center',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{title}</span>
                            {shouldRequestClose() === true && (
                                <div style={{
                                    backgroundColor: Appearance.colors.red,
                                    borderRadius: 5,
                                    height: 10,
                                    marginLeft: 8,
                                    width: 10
                                }}/>
                            )}
                        </div>

                        <div
                        className={'not-draggable'}
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: 100
                        }}>
                            {options.supports_fullscreen && (
                                <AltBadge
                                onClick={onToggleFullscreen}
                                content={{
                                    color: size.type === 'fullscreen' ? Appearance.colors.grey() : Appearance.colors.green,
                                    text: size.type === 'fullscreen' ? 'Standard' : 'Fullscreen'
                                }}
                                style={{
                                    width: 95
                                }}/>
                            )}
                            {rightButton && (
                                <AltBadge
                                onClick={rightButton.onClick}
                                content={{
                                    color: rightButton.color,
                                    text: rightButton.text
                                }}
                                style={{
                                    width: 60,
                                    ...rightButton.style
                                }}/>
                            )}
                        </div>
                    </div>
                </div>
                {options.loading && (
                    <div style={{
                        borderRadius: 2,
                        bottom: 0,
                        height: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: 0,
                    }}>
                        <ProgressBar/>
                    </div>
                )}
            </div>
        )
    }

    const getLayerHeight = () => {

        let height = size.height;
        if(buttonContainer.current) {
            height -= buttonContainer.current.clientHeight;
        }
        if(headerRef.current) {
            height -= headerRef.current.clientHeight;
        }
        if(stickyBottom.current) {
            height -= stickyBottom.current.clientHeight;
        }
        
        return size.type === 'fullscreen' ? (height - 20) : (height - 60);
    }

    const getLayerPosition = () => {
        if(size.type === 'fullscreen') {
            return {
                x: 0,
                y: 0
            }
        }
        return layerPosition || {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 50
        }
    }

    const getLayerWidth = () => {
        if(size.type === 'fullscreen') {
            return window.innerWidth;
        }
        let width = getWidthForSizing();
        return size.width > width ? width : size.width - 60;
    };

    const getWidthForSizing = () => {
        return getLayerSizingWidth(size.type);
    }

    const shouldRequestClose = () => {
        return typeof(onRequestClose) === 'function' && onRequestClose() === true;
    }

    const shouldShowScrollbars = () => {
        return scrollContainer.current ? scrollContainer.current.scrollHeight > getLayerHeight() : false;
    }

    useEffect(() => {
        if(height && ref.current) {
            setLayerPosition({
                x: (size.width / 2) - (ref.current.clientWidth / 2),
                y: (size.height / 2) - (ref.current.clientHeight / 2)
            });
        }
    }, [height]);

    useEffect(() => {
        setZIndex(options.zIndex);
    }, [options]);

    useEffect(() => {
        if(options.position) {
            setLayerPosition(options.position);
        }
    }, [options.position]);

    useEffect(() => {
        onLayerStateChange(layerState);
    }, [layerState]);

    useEffect(() => {

        // prevent moving forward if a ref has not yet posted
        if(!ref.current) {
            return
        }

        // update height using layer ref
        setHeight(ref.current.clientHeight);

        // animate components into view
        onAnimateComponentsIntoView();

        // add listeners for layer action changes and browser window changes
        window.addEventListener('onLayerAction', onLayerAction);
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('onLayerAction', onLayerAction);
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [ref.current]);

    useEffect(() => {
        if(typeof(options.onMount) === 'function') {
            let { position } = options.onMount({
                options: options,
                getWidthForSizing: getWidthForSizing
            });
            if(position) {
                setLayerPosition(position);
            }
        }
    }, [options.onMount]);

    return (
        <Draggable
        cancel={'.not-draggable'}
        defaultPosition={getLayerPosition()}
        key={id}
        onDrag={onDragMoved}
        onStart={onDragStarted}
        onStop={onDragEnded}
        position={getLayerPosition()}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <animated.div
                onClick={onLayerPress}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    backgroundColor: Appearance.colors.layerBackground(),
                    height: 'auto',
                    overflow: 'hidden',
                    position: 'fixed',
                    width: getLayerWidth(),
                    zIndex: zIndex,
                    ...animations,
                    ...size.type !== 'fullscreen' && {
                        border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        borderRadius: 10,
                        boxShadow: '0px 0px 50px rgba(0,0,0,0.2)'
                    }
                }}>
                    <ResizeObserver onResize={onUpdateHeight}/>
                    <div
                    ref={ref}
                    style={{
                        flexGrow: 1,
                        padding: 0,
                        margin: 0
                    }}>
                        {getHeaderContent()}
                        {getFilterOptions()}
                        <div
                        className={'not-draggable'}
                        style={{
                            position: 'relative'
                        }}>
                            {options && options.loading && (
                                <div style={{
                                    borderRadius: 2,
                                    height: 2,
                                    left: 0,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                }}>
                                    <ProgressBar/>
                                </div>
                            )}
                            {options.sticky && options.sticky.top}
                            <div
                            ref={scrollContainer}
                            className={shouldShowScrollbars() ? 'custom-scrollbars' : ''}
                            style={{
                                height: size.type === 'fullscreen' ? getLayerHeight() : '100%',
                                maxHeight: getLayerHeight(),
                                overflowX: 'hidden',
                                overflowY: 'scroll',
                                padding: options.removePadding ? 0 : 12
                            }}>
                                {children}
                            </div>
                            {options.sticky && options.sticky.bottom && (
                                <div ref={stickyBottom}>
                                    {options.sticky.bottom}
                                </div>
                            )}
                            {getButtons()}
                        </div>
                    </div>
                </animated.div>
            </div>
        </Draggable>
    )
}

export const LayerItem = React.forwardRef((props, ref) => {

    const { badge, children, childrenStyle, collapsed, headerStyle, lastItem, leftContent, onVisibilityChange, subTitle, title, useStyle, required, rightContent, style } = props;

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: collapsed ? 0 : 1,
        maxHeight: collapsed ? 0 : 1500,
        config: { mass: 1, tension: 180, friction: 30 }
    }));

    const onCollapseClick = () => {
        let next = !_collapsed;
        _setCollapsed(next);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange(next);
        }
    }

    const isCollapseEnabled = () => {
        return typeof(_collapsed) === 'boolean' ? true : false;
    }

    const getAnimationsStyles = () => {
        if(isCollapseEnabled()) {
            return {
                overflowX: 'hidden',
                overflowY: 'scroll',
                ...animations
            };
        }
        return null;
    }

    const getCollapseStyles = () => {
        if(!isCollapseEnabled() || _collapsed === false) {
            return {
                marginBottom: lastItem ? 0 : LayerItemSpacing
            };
        }
        return {
            paddingBottom: 2,
            marginBottom: _collapsed ? 10 : LayerItemSpacing,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }

    useEffect(() => {
        setAnimations({
            opacity: _collapsed ? 0 : 1,
            maxHeight: _collapsed ? 0 : 1500
        });
    }, [_collapsed]);

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    return (
        <div 
        ref={ref}
        style={{
            width: '100%',
            ...getCollapseStyles(),
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'left',
                width: '100%'
            }}>
                {required && (
                    <div style={{
                        backgroundColor: Appearance.colors.red,
                        borderRadius: 4,
                        height: 8,
                        overflow: 'hidden',
                        marginRight: 8,
                        minWidth: 8,
                        minHeight: 8,
                        width: 8
                    }} />
                )}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    jusitfyContent: 'space-between',
                    minWidth: 0,
                    ...headerStyle
                }}>
                    {leftContent}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.layerItemTitle()
                        }}>{title}</span>
                        {typeof(subTitle) === 'string' && (
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                display: 'block'
                            }}>{subTitle}</span>
                        )}
                    </div>
                    {typeof(badge) === 'object' && (
                        <AltBadge
                        content={badge}
                        style={{
                            marginLeft: 8,
                            marginRight: 0,
                            top: 0
                        }} />
                    )}
                </div>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {rightContent}
                    {isCollapseEnabled() && (
                        <CollapseArrow
                        collapsed={_collapsed}
                        onClick={onCollapseClick} />
                    )}
                </div>
            </div>
            <animated.div style={{
                marginTop: 8,
                width: '100%',
                ...useStyle && Appearance.styles.unstyledPanel(),
                ...childrenStyle,
                ...getAnimationsStyles()
            }}>
                {children}
            </animated.div>
        </div>
    )
});

export const CollapseArrow = ({ collapsed, color, onClick, style }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        transform: `rotate(${collapsed === true ? '0deg' : '180deg'})`
    }));

    const onCollapseClick = evt => {
        let nextCollapse = !_collapsed;
        if(typeof(onClick) === 'function') {
            onClick(nextCollapse, evt);
        }
        _setCollapsed(nextCollapse);
    }

    const getImage = () => {
        switch(color) {
            case Appearance.colors.primary():
            return `images/down-arrow-blue-small.png`;

            default:
            return 'images/down-arrow-grey-small.png';
        }
    }

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    useEffect(() => {
        setAnimations({ transform: `rotate(${_collapsed === true ? '0deg' : '180deg'})` });
    }, [_collapsed]);

    return (
        <div syle={{
            height: 15,
            width: 15,
            ...style
        }}>
            <animated.img
            className={'text-button'}
            onClick={onCollapseClick}
            src={getImage()}
            style={{
                height: 15,
                objectFit: 'contain',
                width: 15,
                ...style,
                ...animations
            }} />
        </div>
    )
}

export default Layer;
