import moment from 'moment';
import Request from 'files/Request.js';

class IncentivesTrackingReportClass {

    active = null;
    date = null;
    id = null;
    name = null;
    preferences = null;
    target_dealership_id = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment(props.date);
        this.id = props.id;
        this.name = props.name;
        this.preferences = props.preferences || {};
        this.target_dealership_id = props.target_dealership_id;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                name: this.name,
                preferences: this.preferences
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                ...this.edits,
                ...props
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.name = this.edits.name;
            this.preferences = this.edits.preferences;
            this.target_dealership_id = this.edits.target_dealership_id;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, preferences } = await Request.post(utils, '/resources/', {
                    type: 'new_incentives_tracking_report',
                    ...this.edits
                });
                await this.close();
                this.id = id;
                this.preferences = preferences;
                utils.content.fetch('incentives_tracking_report');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { preferences } = await Request.post(utils, '/resources/', {
                    target_dealership_id: this.target_dealership_id,
                    type: 'update_incentives_tracking_report',
                    id: this.id,
                    ...this.edits
                });

                await this.close();
                this.preferences = preferences;

                utils.content.update({
                    type: 'incentives_tracking_report',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new IncentivesTrackingReportClass(),
    create: props => new IncentivesTrackingReportClass().create(props)
}
