import React from 'react';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import Request from 'files/Request.js';

class PresentationClass {

    active = null;
    author = null;
    date = null;
    description = null;
    id = null;
    last_updated = null;
    options = null;
    pages = null;
    published = null;
    title = null;
    version = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.active = props.active;
        this.author = props.author;
        this.date = props.date && moment(props.date);
        this.description = props.description;
        this.id = props.id;
        this.last_updated = props.last_updated && moment(props.last_updated);
        this.options = props.options;
        this.pages = props.pages;
        this.published = props.published;
        this.title = props.title;
        this.version = props.version;
        return this;

    }
}

export const fetchPresentation = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { presentation } = await Request.get(utils, '/flipchart/', {
                type: 'presentation_details',
                id: id
            });
            resolve(new PresentationClass().create(presentation));
        } catch(e){
            reject(e);
        }
    })
}

export default {
    Presentation: {
        new: () => new PresentationClass(),
        get: (utils, id) => fetchPresentation(utils, id),
        create: props => new PresentationClass().create(props)
    }
}
