import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';
import smoothscroll from 'smoothscroll-polyfill';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views from 'views/Main.js';

const PickerField = ({ containerStyle, collapsible, disableScroll, hideTextField, icon, items, multiple, onAddNew, onChange, placeholder, showValidation, utils, value }) => {

    const scrollEnd = useRef(null);
    const scrollStart = useRef(null);

    const selectedRef = useRef(null);
    const [text, setText] = useState(null);
    const [options, setOptions] = useState(items);
    const [showOptions, setShowOptions] = useState(collapsible ? false : true);
    const [selected, setSelected] = useState([]);
    const [maxHeight, setMaxHeight] = useState(0);

    const onValidate = () => {
        return selected.length > 0
    }

    const onFieldClick = () => {
        if(collapsible === false) {
            return;
        }
        setShowOptions(showOptions => !showOptions);
        if(scrollStart.current && disableScroll !== true) {
            scrollStart.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    const onShowOptionsChange = () => {
        setMaxHeight(showOptions ? window.innerHeight / 3 : 0);
    }

    const onClick = option => {

        let nextItems = [];
        if(selectedRef.current.find(item => item.key === option.key)) {
            nextItems = update(selectedRef.current, {
                $apply: items => items.filter(item => item.key !== option.key)
            });
        } else if(multiple) {
            nextItems = update(selectedRef.current, {
                $push: [option]
            });
        } else {
            nextItems = update(selectedRef.current, {
                $set: [option]
            });
        }

        setSelected(nextItems);
        if(!multiple && collapsible !== false) {
            setShowOptions(false);
        }
        if(typeof(onChange) === 'function') {
            onChange(multiple ? nextItems : nextItems[0]);
        }
    }

    useEffect(() => {
        if(!items || !Array.isArray(items)) {
            return;
        }
        setSelected(items.filter(item => {
            if(!value) {
                return false;
            }
            if(Array.isArray(value)) {
                return value && value.find(prevItem => {
                    return prevItem.key === item.key
                });
            }
            return value.key === item.key;
        }))
    }, [value, items])

    useEffect(() => {
        if(selected) {
            selectedRef.current = selected;
            setText(selected.map(item => item.title).join(', '));
        }
    }, [selected])

    useEffect(() => {
        setOptions(items)
    }, [items]);

    useEffect(() => {
        onShowOptionsChange();
    }, [showOptions])

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            {hideTextField !== true && (
                <TextField
                icon={icon}
                value={text}
                focused={showOptions}
                onValidate={showValidation ? onValidate : null}
                placeholder={placeholder}
                onClick={onFieldClick}
                style={{
                    flexGrow: 1,
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text()
                }}/>
            )}

            <div ref={scrollStart} />
            {options.length > 0
                ?
                <VelocityComponent
                duration={500}
                animation={{
                    maxHeight: maxHeight
                }}>
                    <div style={{
                        borderRadius: 12,
                        overflowY: 'scroll',
                        maxHeight: maxHeight,
                        border: `${showOptions ? 1 : 0}px solid ${Appearance.colors.divider()}`,
                        backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                        marginTop: showOptions && hideTextField !== true ? 8 : 0
                    }}>
                        {options.map((option, index) => {
                            return (
                                <div
                                key={index}
                                className={'text-button'}
                                onClick={onClick.bind(this, option)}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '8px 12px 8px 12px',
                                        borderBottom: index !== options.length - 1 ? `1px solid ${Appearance.colors.divider()}` : 0
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                fontWeight: '600',
                                                color: selected.find(item => item.key === option.key) ? Appearance.colors.primary() : Appearance.colors.text()
                                            }}>{option.title}</span>
                                            {option.subTitle
                                                ?
                                                <span style={{
                                                    fontSize: 10,
                                                    fontWeight: '600',
                                                    color: selected.find(item => item.key === option.key) ? Appearance.colors.primary() : Appearance.colors.subText()
                                                }}>{option.subTitle}</span>
                                                :
                                                null
                                            }
                                        </div>
                                        {selected.find(item => item.key === option.key)
                                            ?
                                            <img src={'images/checkmark-small-green.png'}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                objectFit: 'contain'
                                            }}/>
                                            :
                                            <img src={'images/next-arrow-small.png'}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                objectFit: 'contain',
                                                opacity: 0.5
                                            }}/>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {onAddNew && typeof(onAddNew.onClick) === 'function' && (
                            <div
                            className={'text-button'}
                            onClick={onAddNew.onClick}
                            style={{
                                textAlign: 'center',
                                padding: '8px 12px 8px 12px',
                                borderTop: `1px solid ${Appearance.colors.divider()}`
                            }}>
                                <span style={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: Appearance.colors.primary()
                                }}>{onAddNew.label}</span>
                            </div>
                        )}
                    </div>
                </VelocityComponent>
                :
                null
            }
            <div ref={scrollEnd} />
        </div>
    )
}
export default PickerField;
