import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Backup from 'classes/Backup.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Task from 'classes/Task.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';
import AltFieldMapper from 'views/AltFieldMapper';

export const SystemBackups = ({ index, options, utils }) => {

    const panelID = 'system_backups';
    const category = useRef(null);
    const limit = 10;
    const offset = useRef(0);

    const [backups, setBackups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onBackupClick = backup => {
        utils.layer.open({
            id: `system_backup_details_${backup.id}`,
            abstract: Abstract.create({
                object: backup,
                type: 'system_backup'
            }),
            Component: SystemBackupDetails
        });
    }

    const onPreferencesClick = () => {
        utils.layer.open({
            id: 'system_backup_preferences',
            Component: SystemBackupPreferences
        });
    }

    const onRequestNewBackupClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'aft',
                title: 'Applied Fire Technologies'
            },{
                key: 'global_data',
                title: 'Global Data'
            },{
                key: 'omnishield',
                title: 'OmniShield'
            },{
                key: 'all',
                title: 'All Platforms'
            }],
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                onRequestNewBackupConfirm(key);
                return;
            }
        })
    }

    const onRequestNewBackupConfirm = async key => {
        try {
            setLoading(true);
            await Request.post(utils, '/resources/', {
                type: 'run_manual_backup',
                platform: key 
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your ${key === 'all' ? 'backups have' : `${Utils.getPlatformName(key)} backup has`} been requested. If you have backup completion notifications enabled, we'll send a notification when the ${key === 'all' ? 'backups have' : `${Utils.getPlatformName(key)} backup has`} completed. Otherwise, the ${key === 'all' ? 'backups' : `${Utils.getPlatformName(key)} backup`} will be available in the backups list when completed.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue requesting your backup. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'backup',
            onClick: onRequestNewBackupClick,
            style: 'default',
            title: 'Backup Now',
        },{
            key: 'preferences',
            onClick: onPreferencesClick,
            style: 'grey',
            title: 'Preferences',
        }]
    }

    const getCategoryList = () => {

        // prepare list of valid items
        let items = [{
            id: 'aft',
            title: 'Applied Fire Technologies'
        },{
            id: 'global_data',
            title: 'Global Data'
        },{
            id: 'omnishield',
            title: 'OmniShield'
        }];
        
        return Utils.isMobile() === false && (
            <ListField 
            items={items} 
            onChange={item => {
                category.current = item && item.id;
                fetchBackups();
            }}
            placeholder={'Choose a platform...'}
            style={{ 
                marginLeft: 8,
                maxWidth: 225 
            }}
            utils={utils}
            value={category.current && items.find(cat => cat.id === category.current).title} />
        )
    }

    const getContent = () => {
        if(backups.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: searchText ? 'No backups were found from your search' : 'There are no backups in the system',
                    title: 'No Backups Found'
                })
            )
        }

        return backups.map((backup, index) => {
            return (
                Views.entry({
                    badge: {
                        color: backup.getColor(),
                        text: Utils.formatBytes(backup.size)
                    },
                    bottomBorder: index !== backups.length - 1,
                    icon: { 
                        path: backup.getIcon(),
                        imageStyle: {
                            borderRadius: 8
                        } 
                    },
                    key: index,
                    onClick: onBackupClick.bind(this, backup),
                    subTitle: Utils.formatDate(backup.date),
                    title: backup.title
                })
            )
        });
    }

    const fetchBackups = async () => {
        try {
            setLoading(true);
            let { backups, paging } = await Request.get(utils, '/resources/', {
                type: 'backups',
                category: category.current,
                limit: limit,
                offset: offset.current,
                search_text: searchText
            });

            setLoading(false);
            setBackups(backups.map(backup => Backup.create(backup)));
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving the list of backups. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchBackups();
    }, [searchText]);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Backups'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            removePadding: true,
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => offset.current = next
            },
            search: {
                placeholder: 'Search by archive id or platform name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                },
                rightContent: getCategoryList()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SystemBackupDetails = ({ abstract, index, options, utils }) => {

    const layerID = `system_backup_details_${abstract.getID()}`;
    const limit = 10;

    const [backups, setBackups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [manifest, setManifest] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onDownloadBackup = backup => {

        // show alert to user explaining s3 deep archive restrictions if applicable
        if(backup.provider.id === 'aws.s3' && backup.current_storage_class.retrieval_cost > 0) {

            // prepare retrieval cost 
            let cost = Utils.toCurrency(backup.current_storage_class.retrieval_cost * (abstract.object.size / 1000000000));

            // request confirmation for the user 
            utils.alert.show({
                title: `Download from ${backup.provider.name}`,
                message: `Are you sure that you want to download this backup? Backups stored with Amazon S3 on a non-standard class incur a charge when content is downloaded. The cost to retrieve this backup is roughly ${cost}`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Do Not Download',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onDownloadBackupConfirm(backup);
                        return;
                    }
                }
            });
            return;
        }
        
        // fallback to auto-downloading backup content
        onDownloadBackupConfirm(backup);
    }

    const onDownloadBackupConfirm = async backup => {
        utils.alert.dev();
    }

    const onDownloadBackupRequestClick = evt => {

        // auto select first backup destination if only one destination is present
        if(backups.length === 1) {
            onDownloadBackup(backups[0]);
            return;
        }

        // prompt user to select a backup destination before moving on
        utils.sheet.show({
            items: backups.map(backup => ({
                key: backup.provider.id,
                style: 'default',
                title: backup.provider.name
            })),
            target: evt.target
        }, key => {
            let backup = backups.find(b => b.provider.id === key);
            if(backup) {
                onDownloadBackup(backup);
            }
        });
    }

    const onOptionsClick = evt => {

        // auto select first backup destination if only one destination is present
        if(backups.length === 1) {
            onShowOptions(backups[0]);
            return;
        }

        // prompt user to select a backup destination before moving on
        utils.sheet.show({
            items: backups.map(backup => ({
                key: backup.provider.id,
                style: 'default',
                title: backup.provider.name
            })),
            target: evt.target
        }, key => {
            let backup = backups.find(b => b.provider.id === key);
            if(backup) {
                onShowOptions(backup);
            }
        });
    }

    const onSetStorageClass = async backup => {

        // retrieve current costs for s3 storage classes
        let sizeGB = (abstract.object.size / 1000000000);
        let { deep_archive, glacier, standard } = await Request.get(utils, '/resources/', {type: 's3_backup_costs'});

        // prepare list of storage class options
        let items = [{
            id: 'deep_archive',
            title: `Glacier Deep Archive $${parseFloat(deep_archive.storage_cost * sizeGB).toFixed(5)}`
        },{
            id: 'deep_archive',
            title: `Glacier Instant Retrieval $${parseFloat(glacier.storage_cost * sizeGB).toFixed(5)}`
        },{
            id: 'standard',
            title: `Standard $${parseFloat(standard.storage_cost * sizeGB).toFixed(5)}`
        }];

        // show alert to user acount changing storage classes
        let result = null;
        utils.alert.show({
            title: 'Change Storage Class',
            message: 'Changing a storage class can help save costs for backups that are not frequently accessed. The system will make these changes automatically as each backup begins to age. Be advised that manually changing the storage class will change the cost associated with storing and retrieving this backup. Backups stored using Glacier Deep Archive can take up to 12 hours to change or retrieve.',
            content: (
                <div style={{
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <ListField
                    items={items}
                    onChange={item => result = item.id} 
                    style={{
                        width: '100%'
                    }}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: 'cancel'
            }],
            onClick: key => {
                if(result && key === 'confirm') {
                    onSetStorageClassConfirm(backup, result);
                    return;
                }
            }
        });
    }

    const onSetStorageClassConfirm = async (backup, storageClass) => {
        try {

            setLoading(true);
            await Utils.sleep(0.5);
            let { result } = await Request.post(utils, '/resources/', {
                type: 'set_s3_backup_storage_class',
                id: backup.id,
                storage_class: storageClass
            });

            // update local storage class object
            abstract.object.current_storage_class = result;

            // notify user that request was successful
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The storage class for this backup has been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the storage class for this backup. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowOptions = backup => {

        // prepare list of sheet items for selected backup
        let items = [];
        switch(backup.provider.id) {
            case 'aws.s3':
            items.push({
                key: 'set_storage_class',
                title: 'Change Storage Class',
                style: 'primary'
            });
            break;
        }

        // show list of options if at least one item is present
        if(items.length > 0) {
            utils.sheet.show({ items }, key => {
                if(key === 'set_storage_class') {
                    onSetStorageClass(backup);
                    return;
                }
            })
        }
    }

    const onUpdatePagingProps = () => {
        let items = getManifestItems();
        setPaging({
            total: items.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: items.length > limit ? Math.ceil(items.length / limit) : 1
        });
    }

    const getArchiveFields = () => {
        return [{
            key: 'details',
            lastItem: false,
            title: 'Archive Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: abstract.object.id
            },{
                key: 'archive_id',
                title: 'Archive ID',
                value: abstract.object.archive_id
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(abstract.object.date)
            },{
                key: 'destinations',
                title: 'Destinations',
                value: abstract.object.formatDestinations()
            },{
                key: 'encrypted',
                title: 'Encrypted',
                value: abstract.object.encrypted ? 'Yes' : 'No'
            },{
                key: 'size',
                title: 'Size',
                value: Utils.formatBytes(abstract.object.size)
            }]
        }];
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'download',
            onClick: onDownloadBackupRequestClick,
            text: 'Download'
        },{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        }];
    }

    const getBackupDetails = () => {
        return backups.map((backup, index) => {
            
            // prepare entry for aws s3 backup
            if(backup.provider.id === 'aws.s3') {

                let items = [{
                    key: 'details',
                    lastItem: false,
                    title: `${backup.provider.name} Details`,
                    items: [{
                        key: 'entity_tag',
                        title: 'ID',
                        value: backup.entity_tag
                    },{
                        key: 'bucket',
                        title: 'Bucket',
                        value: backup.bucket
                    },{
                        key: 'original_storage_class',
                        title: 'Original Storage Class',
                        value: backup.original_storage_class.name
                    },{
                        key: 'current_storage_class',
                        title: 'Current Storage Class',
                        value: backup.current_storage_class.name
                    },{
                        key: 'region',
                        title: 'Region',
                        value: backup.region
                    }]
                }];
        
                return (
                    <FieldMapper
                    key={index}
                    fields={items}
                    utils={utils} />
                )
            }

            return null;
        });
    }

    const getManifestItemIcon = item => {
        switch(item.type) {
            case 'file-system.directory':
            return 'images/backup-manifest-item-file-system-directory.png';

            case 'mongo.database':
            return 'images/backup-manifest-item-mongo-db.png';

            case 'mysql.database':
            return 'images/backup-manifest-item-mysql.png';

            default:
            return null;
        }
    }

    const getManifestItemOverview = item => {
        switch(item.type) {
            case 'file-system.directory':
            return `Contains ${Utils.softNumberFormat(item.metadata.count)} ${item.metadata.count == 1 ? 'file' : 'files'}`;

            case 'mongo.database':
            return 'Mongo DB non-relational database';

            case 'mysql.database':
            return 'MySQL relational database';

            default:
            return 'Metadata overview not available';
        }
    }

    const getManifest = () => {

        let items = getManifestItems()
        return (
            <LayerItem 
            collapsed={false}
            title={'Manifest'}>
                <div style={{ ...Appearance.styles.unstyledPanel() }}>
                    <div style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        padding: 8
                    }}>
                        <TextField
                        placeholder={'Search by name or tag...'}
                        onChange={text => setSearchText(text && text.toLowerCase())} 
                        value={searchText}/>
                    </div>
                    {items.length > 0 && items.map((item, index, items) => {
                        return (
                            Views.entry({
                                badge: item.metadata && {
                                    color: abstract.object.getColor(),
                                    text: Utils.formatBytes(item.metadata.size)
                                },
                                bottomBorder: index !== items.length - 1,
                                icon: { path: getManifestItemIcon(item) },
                                key: index,
                                title: item.name,
                                subTitle: getManifestItemOverview(item)
                            })
                        )
                    })}
                    {items.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            title: 'No Items Found',
                            subTitle: searchText ? 'There were no items found from your search' : 'No manifest items are available'
                        })
                    )}
                    {paging && (
                         <PageControl
                         data={paging}
                         limit={limit}
                         offset={offset}
                         onClick={next => setOffset(next)}/>
                    )}
                </div>
            </LayerItem>
        )
    }

    const getManifestItems = () => {
    
        // prepare list of filtered items
        return manifest.filter(item => {

            // restrict item to search text if applicable
            if(searchText) {
                if(item.name.toLowerCase().includes(searchText) === true) {
                    return true;
                }
                return item.tags.find(tag => tag.toLowerCase().includes(searchText)) ? true : false;
            }
            return true;

        }).filter((_, index) => {

            // return full list of items if no paging props are available
            if(!paging) {
                return true;
            }

            // return paged list of items
            return index >= ((paging.current_page - 1) * limit) && index < (paging.current_page * limit)
        });
    }

    const fetchBackupDetails = async () => {
        try {

            setLoading(true);
            let { backups } = await Request.get(utils, '/resources/', {
                type: 'backup_details',
                archive_id: abstract.object.archive_id
            });

            setLoading(false);
            setBackups(backups);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this backup. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupManifestItems = () => {

        // prepare list of manifest items and convert dates if applicable
        let items = abstract.object.manifest.map(item => {
            if(item.metadata && item.metadata.modified) {
                item.metadata.modified = moment.unix(item.metadata.modified).local();
            }
            return item;
        });

        // sort items by title ascending
        items = items.sort((a,b) => {
            return a.name.localeCompare(b.name);
        });

        // update state with formatted items
        setManifest(items);
    }

    useEffect(() => {
        onUpdatePagingProps();
    },[manifest, offset, searchText]);

    useEffect(() => {
        fetchBackupDetails();
        setupManifestItems();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.object.title} System Backup`}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}>
            <FieldMapper
            fields={getArchiveFields()}
            utils={utils} />

            {getBackupDetails()}
            {getManifest()}
        </Layer>
    )
}

export const SystemBackupPreferences = ({ index, options, utils }) => {

    const layerID = 'system_backup_preferences';
    const [loading, setLoading] = useState(null);
    const [preferences, setPreferences] = useState([]);
    const [layerState, setLayerState] = useState(null);

    const onSaveChanges = async () => {
        try {

            // convert local timestamps back to utc
            let next = preferences.map(pref => ({
                ...pref,
                time: moment(pref.time, 'HH:mm').utc().format('HH:mm')
            }))

            // send request to server to update preferences 
            setLoading(true);
            await Request.post(utils, '/resources/', {
                type: 'update_backup_preferences',
                data: next
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your backup preference changes hvae been saved',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the system backup preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdatePreferences = (index, props) => {
        setPreferences(preferences => {

            // determine if prop value needs to be alphabetically sorted
            Object.keys(props).map(key => {
                if(['days', 'months', 'weekdays'].includes(key)) {
                    props[key] = props[key] && props[key].sort((a,b) => a < b ? -1 : 1);
                }
            });

            // update entry using supplied index
            let next = update(preferences, {
                [index]: {
                    $set: {
                        ...preferences[index],
                        ...props
                    }
                }
            });

            // remove frequency supporter values if the frequency has changed
            if(props.frequency) {
                next = update(next, {
                    [index]: {
                        $unset: ['days', 'months', 'weekdays']
                    }
                });
            }
            return next;
        });
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'confirm',
            onClick: onSaveChanges,
            text: 'Save Changes',
        }]
    }

    const getContent = () => {
        return preferences.map((entry, index) => {
            let fields = [{
                collapsed: false,
                key: index,
                lastItem: false,
                title: (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <img 
                        src={getIcon(entry.id)}
                        style={{
                            borderRadius: 6,
                            height: 25,
                            marginRight: 8,
                            width: 25
                        }} />
                        <span>{entry.name}</span>
                    </div>
                ),
                items: [{
                    key: 'time',
                    component: 'time_picker',
                    onChange: date => onUpdatePreferences(index, { time: date && date.format('HH:mm') }),
                    props: { minuteInterval: 5 },
                    title: 'Start Time',
                    value: entry.time && moment(entry.time, 'HH:mm')
                },{
                    key: 'frequency',
                    component: 'list',
                    onChange: item => onUpdatePreferences(index, { frequency: item && item.id }),
                    title: 'Frequency',
                    value: entry.frequency && getFrequencies().find(frequency => frequency.id === entry.frequency).title,
                    items: getFrequencies()
                },{
                    key: 'days',
                    component: 'multiple_list',
                    onChange: results => onUpdatePreferences(index, { days: results && results.map(result => result.id) }),
                    title: 'Days of the Month',
                    value: entry.days && entry.days.map(code => getDays().find(day => day.id === code)),
                    visible: entry.frequency === 3,
                    items: getDays()
                },{
                    key: 'months',
                    component: 'multiple_list',
                    onChange: results => onUpdatePreferences(index, { months: results && results.map(result => result.id) }),
                    title: 'Months of the Year',
                    value: entry.months && entry.months.map(code => getMonths().find(month => month.id === code)),
                    visible: entry.frequency === 4,
                    items: getMonths()
                },{
                    key: 'weekdays',
                    component: 'multiple_list',
                    onChange: results => onUpdatePreferences(index, { weekdays: results && results.map(result => result.id) }),
                    title: 'Days of the Week',
                    value: entry.weekdays && entry.weekdays.map(code => getWeekdays().find(day => day.id === code)),
                    visible: entry.frequency === 2,
                    items: getWeekdays()
                },{
                    key: 'encrypt',
                    component: 'list',
                    items: getYesNoItems(),
                    onChange: item => onUpdatePreferences(index, { encrypt: item && Boolean(item.id) || false }),
                    title: 'Encrypt',
                    value: entry.encrypt ? 'Yes' : 'No'
                },{
                    key: 'send_push_notification_on_backup_completion',
                    component: 'list',
                    items: getYesNoItems(),
                    onChange: item => onUpdatePreferences(index, { send_push_notification_on_backup_completion: item && Boolean(item.id) || false }),
                    title: 'Send Backup Completion Push Notification',
                    value: entry.send_push_notification_on_backup_completion ? 'Yes' : 'No'
                },{
                    key: 'send_push_notification_on_restore_completion',
                    component: 'list',
                    items: getYesNoItems(),
                    onChange: item => onUpdatePreferences(index, { send_push_notification_on_restore_completion: item && Boolean(item.id) || false }),
                    title: 'Send Restore Completion Push Notification',
                    value: entry.send_push_notification_on_restore_completion ? 'Yes' : 'No'
                },{
                    key: 'transfer_to_aws_s3',
                    component: 'list',
                    items: getYesNoItems(),
                    onChange: item => onUpdatePreferences(index, { transfer_to_aws_s3: item && Boolean(item.id) || false }),
                    title: 'Transfer To Amazon S3',
                    value: entry.transfer_to_aws_s3 ? 'Yes' : 'No'
                },{
                    key: 'transfer_to_ghs_server',
                    component: 'list',
                    items: getYesNoItems(),
                    onChange: item => onUpdatePreferences(index, { transfer_to_ghs_server: item && Boolean(item.id) || false }),
                    title: 'Transfer to GHS Home Office NAS',
                    value: entry.transfer_to_ghs_server ? 'Yes' : 'No'
                }]
            }]

            return (
                <AltFieldMapper
                key={index} 
                fields={fields}
                utils={utils} />
            )
        })
    }

    const getDays = () => {
        return [...Array(31)].map((_, index) => ({
            id: index + 1,
            title: `${index + 1}${getOrdinal(index + 1)}`
        }));
    }

    const getIcon = key => {
        switch(key) {
            case 'aft':
            return 'images/aft-mobile-app-icon.jpg';

            case 'global_data':
            return 'images/global-data-mobile-app-icon.jpg';

            case 'omnishield':
            return 'images/omnishield-mobile-app-icon.jpg';

            default:
            return null;
        }
    }

    const getFrequencies = () => {
        return ['daily','weekly','monthly','yearly'].map((text, index) => ({
            id: index + 1,
            title: Utils.ucFirst(text)
        }))
    }

    const getMonths = () => {
        return [...Array(12)].map((_, index) => ({
            id: index + 1,
            title: moment().startOf('year').add(index, 'months').format('MMMM')
        }));
    }

    const getOrdinal = number => {
        if(number % 10 == 1 && number % 100 != 11) {
            return 'st';
        }
        if(number % 10 == 2 && number % 100 != 12) {
            return 'nd';
        }
        if(number % 10 == 3 && number % 100 != 13) {
            return 'rd';
        }
        return 'th';
    }

    const getWeekdays = () => {
        return [{
            id: 0,
            title: 'Sunday'
        },{
            id: 1,
            title: 'Monday'
        },{
            id: 2,
            title: 'Tuesday'
        },{
            id: 3,
            title: 'Wednesday'
        },{
            id: 4,
            title: 'Thursday'
        },{
            id: 5,
            title: 'Friday'
        },{
            id: 6,
            title: 'Saturday'
        }];
    }

    const getYesNoItems = () => {
        return [{
            id: 1,
            title: 'Yes'
        },{
            id: 0,
            title: 'No'
        }];
    }

    const fetchPreferences = async () => {
        try {
            let { preferences } = await Request.get(utils, '/resources/', {
                type: 'backup_preferences'
            });

            setPreferences(preferences.map(entry => ({
                ...entry,
                time: moment.utc(entry.time, 'HH:mm').local().format('HH:mm')
            })));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the backup preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchPreferences();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'System Backup Preferences'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const SystemTasks = ({ index, options, utils }) => {

    const panelID = 'system_tasks';

    const limit = 10;
    const offset = useRef(0);
    const platform = useRef(null);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [tasks, setTasks] = useState([]);

    const onTaskClick = task => {
        utils.layer.open({
            id: `system_task_details_${task.id}`,
            abstract: Abstract.create({
                object: task,
                type: 'system_task'
            }),
            Component: SystemTaskDetails
        });
    }

    const getCategoryList = () => {

        // prepare list of valid items
        let items = [{
            id: 'aft',
            title: 'Applied Fire Technologies'
        },{
            id: 'global_data',
            title: 'Global Data'
        },{
            id: 'omnishield',
            title: 'OmniShield'
        }];
        
        return Utils.isMobile() === false && (
            <ListField 
            items={items} 
            onChange={item => {
                platform.current = item && item.id;
                fetchTasks();
            }}
            placeholder={'Choose a platform...'}
            style={{ 
                marginLeft: 8,
                maxWidth: 225 
            }}
            utils={utils}
            value={platform.current && items.find(cat => cat.id === platform.current).title} />
        )
    }

    const getContent = () => {
        if(tasks.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: searchText ? 'No tasks were found from your search' : 'There are no tasks in the system',
                    title: 'No Tasks Found'
                })
            )
        }

        return tasks.map((task, index) => {
            return (
                Views.entry({
                    badge: [{
                        color: Appearance.colors.red,
                        text: task.exit_code === 0 ? null : 'Failed'
                    },{
                        color: Appearance.colors.secondary(),
                        text: task.manual === true && 'Manually Started'
                    }],
                    bottomBorder: index !== tasks.length - 1,
                    icon: { 
                        path: task.getIcon(),
                        imageStyle: {
                            backgroundColor: Appearance.colors.transparent,
                            borderRadius: task.platform === 'graci' ? '50%' : 8
                        } 
                    },
                    key: index,
                    onClick: onTaskClick.bind(this, task),
                    subTitle: Utils.formatDate(task.date),
                    title: task.getTitle()
                })
            )
        });
    }

    const fetchTasks = async () => {
        try {
            setLoading(true);
            let { tasks, paging } = await Request.get(utils, '/resources/', {
                type: 'tasks',
                limit: limit,
                offset: offset.current,
                platform: platform.current,
                search_text: searchText
            });

            setLoading(false);
            setTasks(tasks.map(task => Task.create(task)));
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving the list of tasks. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchTasks();
    }, [searchText]);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Tasks'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => {
                    offset.current = next;
                    fetchTasks();
                }
            },
            search: {
                placeholder: 'Search by archive id, action, category, or task id...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                },
                rightContent: getCategoryList()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SystemTaskDetails = ({ abstract, index, options, utils }) => {

    const layerID = `system_task_details_${abstract.getID()}`;

    const getFields = () => {
        return [{
            key: 'details',
            lastItem: false,
            title: 'About this Task',
            items: [{
                key: 'id',
                title: 'ID',
                value: abstract.object.id
            },{
                key: 'action',
                title: 'Action',
                value: abstract.object.getAction(),
                visible: abstract.object.action ? true : false
            },{
                key: 'archive_id',
                title: 'Archive ID',
                value: abstract.object.archive_id,
                visible: abstract.object.archive_id ? true : false
            },{
                key: 'category',
                title: 'Category',
                value: abstract.object.category && Utils.ucFirst(abstract.object.category),
                visible: abstract.object.category ? true : false
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(abstract.object.date)
            },{
                key: 'duration',
                title: 'Duration',
                value: Utils.parseDuration(abstract.object.duration)
            },{
                key: 'exit_code',
                title: 'Exit Code',
                value: `${abstract.object.exit_code === 0 ? 'Successful' : 'Failed'} (${abstract.object.exit_code})`
            },{
                key: 'manual',
                title: 'Manually Started',
                value: 'Yes',
                visible: abstract.object.manual === true
            },{
                key: 'namespace',
                title: 'Namespace',
                value: abstract.object.namespace,
                visible: abstract.object.namespace ? true : false
            },{
                key: 'platform',
                title: 'Platform',
                value: abstract.object.getPlatformName()
            },{
                key: 'recurring',
                title: 'Recurring',
                value: abstract.object.recurring ? 'Yes' : 'No',
                visible: abstract.object.manual !== true
            }]
        }];
    }

    const getOutput = () => {
        return abstract.object.output && (
            <LayerItem
            collapsed={false}
            title={'Output'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {abstract.object.output.map((item, index) => {
                        return item.length > 0 && (
                            <div 
                            key={index}
                            style={{
                                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}> 
                                <span style={{
                                    ...Appearance.textStyles.key(),
                                    whiteSpace: 'wrap'
                                }}>{item}</span>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    return (
        <Layer
        id={layerID}
        index={index}
        options={options}
        title={`${abstract.object.getTitle()} Details`}
        utils={utils}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
            {getOutput()}
        </Layer>
    )
}
