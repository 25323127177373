import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';

const DualDatePickerField = props => {

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const onFieldClick = (field, evt) => {
        if(typeof(props.onDisabledClick) === 'function') {
            props.onDisabledClick(evt);
            return;
        }
        props.utils.datePicker.show({
            date: field === 'start' ? selectedStartDate : selectedEndDate,
            ...props,
            onDateChange: date => onDateChange(field, date),
            ...props.blockStyle && {
                highlightDates: props.highlightDates || [selectedStartDate, selectedEndDate]
            }
        })
    }

    const onDateChange = (field, date) => {

        if(field === 'end') {
            setSelectedEndDate(date);
            if(typeof(props.onEndDateChange) === 'function') {
                props.onEndDateChange(moment(date));
            }
        }
        if(field === 'start') {
            setSelectedStartDate(date);
            if(typeof(props.onStartDateChange) === 'function') {
                props.onStartDateChange(moment(date));
            }
        }
        runBlockStyle(date);
    }

    const onRemoveDate = (field, evt) => {

        evt.stopPropagation();
        if(field === 'end' && selectedEndDate) {
            setSelectedEndDate(null);
            if(typeof(props.onEndDateChange) === 'function') {
                props.onEndDateChange(null);
            }
        }
        if(field === 'start' && selectedStartDate) {
            setSelectedStartDate(null);
            if(typeof(props.onStartDateChange) === 'function') {
                props.onStartDateChange(null);
            }
        }
    }

    const runBlockStyle = (date) => {
        switch(props.blockStyle) {
            case 'week':

            let startDate = moment(date).startOf('week');
            let endDate = moment(date).endOf('week');

            setSelectedStartDate(startDate)
            setSelectedEndDate(endDate);

            if(typeof(props.onStartDateChange) === 'function') {
                props.onStartDateChange(startDate);
            }
            if(typeof(props.onEndDateChange) === 'function') {
                props.onEndDateChange(endDate);
            }
            break;
        }
    }

    useEffect(() => {
        setSelectedStartDate(props.selectedStartDate ? moment(props.selectedStartDate) : null);
        setSelectedEndDate(props.selectedEndDate ? moment(props.selectedEndDate) : null);
    }, [props.selectedStartDate, props.selectedEndDate]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            ...props.style
        }}>
            <div
            onClick={onFieldClick.bind(this, 'start')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 35,
                minWidth: 140,
                paddingLeft: 12,
                paddingRight: 12,
                position: 'relative',
                ...props.style
            }}>
                <span style={{
                    color: selectedStartDate ? Appearance.colors.text() : Appearance.colors.subText(),
                    flexGrow: 1,
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: 'center'
                }}>{selectedStartDate ? moment(selectedStartDate).format(props.dateFormat || 'MMMM Do, YYYY') : 'Start Date'}</span>
                {props.canRemoveDates && selectedStartDate && (
                    <img 
                    className={'text-button'}
                    onClick={onRemoveDate.bind(this, 'start')}
                    src={'images/red-x-icon.png'}
                    style={{
                        height: 20,
                        width: 20
                    }} />
                )}
            </div>
            <img 
            src={'images/next-arrow-grey.png'} 
            style={{
                height: 35,
                minHeight: 35,
                minWidth: 35,
                objectFit: 'contain',
                padding: 10,
                width: 35
            }} />
            <div
            onClick={onFieldClick.bind(this, 'end')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 35,
                minWidth: 140,
                paddingLeft: 12,
                paddingRight: 12,
                position: 'relative',
                ...props.style
            }}>
                <span style={{
                    color: selectedEndDate ? Appearance.colors.text() : Appearance.colors.subText(),
                    flexGrow: 1,
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: 'center'
                }}>{selectedEndDate ? moment(selectedEndDate).format(props.dateFormat || 'MMMM Do, YYYY') : 'End Date'}</span>
                {props.canRemoveDates && selectedEndDate && (
                    <img 
                    className={'text-button'}
                    onClick={onRemoveDate.bind(this, 'end')}
                    src={'images/red-x-icon.png'}
                    style={{
                        height: 20,
                        width: 20
                    }} />
                )}
            </div>
        </div>
    )
}
export default DualDatePickerField;
