import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const ImagePickerField = ({ fileTypes, onChange, previewStyle, requirements, style, utils, value }) => {

    const [image, setImage] = useState(value);
    const [label, setLabel] = useState('');

    const getFileTypes = () => {
        if(!fileTypes || !Array.isArray(fileTypes)) {
            return 'image/png, image/jpeg';
        }
        return fileTypes.map(type => {
            switch(type) {
                case 'jpg':
                return 'image/jpeg';

                case 'png':
                return 'image/png';

                default:
                return null;
            }
        }).filter(type => {
            return type !== null;
        }).join(', ');
    }

    const onFileChange = evt => {

        let { files } = evt.target;
        if(files && files.length > 0) {

            let file = files[0];
            const reader = new FileReader();
            reader.onload = async data => {
                try {
                    let image = {
                        data: data.target.result,
                        file_name: file.name,
                        file_type: file.type
                    };
                    await onValidateImage(image);

                    setImage(image);
                    setLabel(image.file_name);
                    if(typeof(onChange) === 'function') {
                        onChange(image);
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue processing your image. ${e.message || 'An unknown error occurred'}`
                    })
                }
            };
            reader.readAsDataURL(files[0]);
        }
    }

    const onImageOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove Image',
                style: 'destructive'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'remove') {
                setImage(null);
                setLabel('No image selected...');
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
            }
        })
    }

    const onValidateImage = async image => {
        return new Promise((resolve, reject) => {

            // immediately resolve if no image requirements are found
            if(!requirements) {
                resolve();
                return;
            }

            // check that image falls within dimension requirements if applicable
            if(requirements.dimensions) {

                // define optional requirements
                let { height, maxHeight, minHeight, maxWidth, minWidth, width } = requirements.dimensions;

                // prepare image object and load data
                let component = new Image();
                component.src = image.data;
                component.onload = function() {
                    try {

                        // verify that image does not exceed maximums if applicable
                        if((maxWidth && this.width > maxWidth) || (maxHeight && this.height > maxHeight)) {
                            throw new Error(`Your image size must be no larger than ${maxWidth} x ${maxHeight}. Your image is ${this.width} x ${this.height} and is too large to use.`);
                        }

                        // verify that image exceeds minimums if applicable
                        if((minWidth && this.width < minWidth) || (minHeight && this.height < minHeight)) {
                            throw new Error(`Your image size must be at least ${minWidth} x ${minHeight}. Your image is ${this.width} x ${this.height} and is too small to use.`);
                        }

                        // verify that image meets exact dimensions if applicable
                        if((width && this.width !== width) || (height && this.height !== height)) {
                            throw new Error(`Your image size must be ${width} x ${height}. Your image is ${this.width} x ${this.height} and needs to be resized before you can use it.`);
                        }
                        resolve();

                    } catch(e) {
                        console.error(e.message);
                        reject(e);
                    }
                };
                return;
            }
            resolve();
        })
    }

    useEffect(() => {
        setImage(value);
        setLabel(value ? (value.name || value.file_name || 'Image selected') : 'No image selected...');
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            {image && (
                <div
                className={'text-button'}
                onClick={onImageOptionsClick}
                style={{
                    marginRight: 4
                }}>
                    <img
                    src={image.url || image.data}
                    style={{
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: '0.25rem',
                        height: 35,
                        objectFit: 'cover',
                        width: 45,
                        ...previewStyle
                    }}/>
                </div>
            )}
            <div
            className={`custom-file ${window.theme}`}
            style={{
                flexGrow: 1,
                overflow: 'hidden'
            }}>
                <input
                accept={getFileTypes()}
                className={`custom-file-input ${window.theme}`}
                onChange={onFileChange} 
                type={'file'}/>
                <label
                className={`custom-file-label ${window.theme}`}
                style={{
                    color: image ? Appearance.colors.text() : Appearance.colors.subText(),
                    fontSize: 12,
                    fontWeight: 500
                }}>{label}</label>
            </div>
        </div>
    )
}

export default ImagePickerField;
