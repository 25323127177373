import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import User from 'classes/User.js';

class SystemEventClass {

    action = null;
    alt_title = null;
    date = null;
    id = null;
    props = null;
    target = null;
    title = null;
    user = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.action = props.action;
        this.alt_title = props.alt_title;
        this.date = props.date && moment(props.date);
        this.id = props.id;
        this.props = props.props || {};
        this.title = props.title;
        this.user = props.user && User.create(props.user);
        this.target = Abstract.create({
            object: props.target.object,
            type: props.target.type
        });
        this.target.sub_type = props.target.sub_type;
        return this;
    }
}

const actions = {
    create: 1,
    update: 2,
    delete: 3,
    warning: 4,
    info: 5
}

export default {
    create: props => new SystemEventClass().create(props),
    actions: actions,
}
