import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { LayerItem } from 'structure/Layer.js';
import { Map } from 'views/MapElements.js';
import ProgressBar from 'views/ProgressBar.js';

const FieldMapper = ({ editable, fields, filter, group, onEditClick, utils }) => {

    const [sections, setSections] = useState(fields);

    const onIncompleteAddressClick = () => {
        utils.alert.show({
            title: 'About this Map',
            message: 'The location provided is incomplete or invalid. Please provide a street address, city, state, and zipcode to take advantage of location based services'
        });
    }

    const onLockClick = () => {
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your dealership has set this information as hidden for your account. Please speak with your dealer if you have questions about this piece of information.'
        });
    }

    const getButtonComponent = (item, index, items) => {
        return (
            <div
            key={index}
            style={{
                alignItems: 'center',
                borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                borderTopLeftRadius: index === 0 ? 6 : 0,
                borderTopRightRadius: index === 0 ? 6 : 0,
                display: 'flex',
                flexDirection: 'row',
                padding: '8px 12px 8px 12px'
            }}>
                <span style={{
                    ...Appearance.textStyles.key(),
                    width: '50%'
                }}>{item.title}</span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '50%'
                }}>
                    {getChild(item.key, (
                        <Button {...item.button} />
                    ))}
                </div>
            </div>
        )
    }

    const getChild = (key, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <img
                key={key}
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    height: 20,
                    objectFit: 'contain',
                    width: 20
                }} />
            )
        }
        return child;
    }

    const getContent = () => {

        // prepare list of target sections
        let targets = sections && sections.length > 0 && sections.filter(section => {
            if(section.visible === false || section.items.length === 0) {
                return false;
            }
            return true;
        }) || [];

        // prevent moving forward if no valid sections were found
        if(!targets || targets.length === 0) {
            return null;
        }

        return targets.map((section, index, sections) => {
            return (
                <div key={index}>
                    {getSectionContainer(section, index, sections, (
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            borderRadius: 15
                        }}>
                            {section.items.filter(item => {
                                return item.visible !== false;
                            }).map((item, index, items) => {
                                switch(item.component) {
                                    case 'button':
                                    return getButtonComponent(item, index, items);

                                    case 'image':
                                    return getImageComponent(item, index, items);

                                    case 'map':
                                    return getMapComponent(item, index);

                                    default:
                                    return getDefaultComponent(item, index, items)
                                }
                            })}
                        </div>
                    ))}
                </div>
            )
        });
    }

    const getSectionContainer = (section, index, sections, children) => {

        // return a layer item container if a title was provided
        if(section.title) {
            return (
                <LayerItem
                collapsed={section.collapsed}
                key={index}
                lastItem={typeof(section.lastItem) === 'boolean' ? section.lastItem : index === sections.length - 1}
                title={section.title}
                style={section.style}>
                    {children}
                </LayerItem>
            )
        }

        // return children as-is if no title was provided
        return children;
    }

    const getDefaultComponent = (item, index, items) => {

        // prepare editable flag and onclick handler
        let canEdit = editable || item.onClick;
        let onClick = item.onClick || onEditClick;

        return (
            <div
            className={canEdit ? `view-entry ${window.theme}` : ''}
            key={index}
            onClick={onClick ? onClick.bind(this, item) : null}
            style={{
                borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                borderTopLeftRadius: index === 0 ? 6 : 0,
                borderTopRightRadius: index === 0 ? 6 : 0,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '8px 12px 8px 12px',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.key(),
                        flexShrink: 1,
                        ...item.style && item.style.key
                    }}>{item.title}</span>
                    <span style={{
                        ...Appearance.textStyles.value(),
                        flexGrow: 1,
                        ...item.style && item.style.value,
                        ...item.color && {
                            color: item.color
                        }
                    }}>{getValue(item)}</span>
                    {canEdit && (
                        item.rightContent || (
                            <img
                            src={'images/next-arrow-grey-small.png'}
                            style={{
                                height: 12,
                                marginLeft: 8,
                                objectFit: 'contain',
                                opacity: 0.75,
                                width: 12
                            }} />
                        )
                    )}
                </div>
                {item.loading === true && (
                    <div style={{
                        bottom: 0,
                        height: 2,
                        overflow: 'hidden',
                        position: 'absolute',
                        width: '100%'
                    }}>
                        <ProgressBar />
                    </div>
                )}
            </div>
        )
    }

    const getImageComponent = (item, index, items) => {
        return (
            <div
            key={index}
            className={`view-entry ${window.theme}`}
            onClick={window.open.bind(this, item.url)}
            style={{
                alignItems: 'center',
                borderBottom: item.title && index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                borderTopLeftRadius: index === 0 ? 6 : 0,
                borderTopRightRadius: index === 0 ? 6 : 0,
                display: 'flex',
                flexDirection: 'row',
                padding: '8px 12px 8px 12px'
            }}>
                <img
                src={item.url}
                style={{
                    borderRadius: 5,
                    height: 30,
                    objectFit: 'cover',
                    overflow: 'hidden',
                    marginRight: 8,
                    width: 30
                }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                }}>
                    <span style={{
                        ...Appearance.textStyles.key(),
                        ...item.style && item.style.key
                    }}>{item.title}</span>
                    <span style={{
                        ...Appearance.textStyles.value(),
                        textAlign: 'left',
                        ...item.style && item.style.value
                    }}>{item.value}</span>
                </div>
                <img
                src={'images/next-arrow-grey-small.png'}
                style={{
                    height: 12,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 0.75,
                    width: 12
                }} />
            </div>
        )
    }

    const getMapComponent = (item, index) => {

        // prevent moving forward if a value was not provided
        if(!item.value) {
            return null;
        }

        // show a placeholder component if map content is not valid
        if(item.isValid === false) {
            return getView(item.key, {
                backgroundColor: Appearance.colors.divider(),
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                height: 250,
                width: '100%'
            }, (
                <div
                className={'text-button'}
                key={index}
                onClick={onIncompleteAddressClick}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                }}>
                    <img
                    src={window.theme === 'dark' ? 'images/map-placeholder-dark.jpg' : 'images/map-placeholder.jpg'}
                    style={{
                        height: 250,
                        width: '100%'
                    }}/>
                    <img
                    src={'images/red-x-icon.png'}
                    style={{
                        position: 'absolute',
                        height: 40,
                        width: 40
                    }} />
                </div>
            ));
        }

        // fallback to rendering an actual map component
        return getView(item.key, {
            backgroundColor: Appearance.colors.divider(),
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            height: 250,
            width: '100%'
        }, (
            <Map
            annotations={[{
                key: 'location',
                location: item.value
            }]}
            center={item.value}
            key={index}
            useShadows={false}
            style={{
                height: 250,
                width: '100%'
            }}/>
        ));
    }

    const getValue = item => {
        if(group && utils.groups.check(group, item.key) === false) {
            return (
                <img
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    height: 20,
                    objectFit: 'contain',
                    width: 20
                }} />
            )
        }
        return item.value || 'Not Added';
    }

    const getView = (key, style, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <div
                key={key}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    ...style
                }}>
                    <img
                    className={'text-button'}
                    onClick={onLockClick}
                    src={'images/lock-icon-small-red.png'}
                    style={{
                        height: 50,
                        objectFit: 'contain',
                        width: 50
                    }} />
                </div>
            )
        }
        return child;
    }

    useEffect(() => {
        if(!filter) {
            return;
        }
        if(typeof(filter) === 'function') {
            setSections(fields.map(section => {
                return section.items.filter(filter);
            }))
            return;
        }
        setSections(fields.map(section => {
            section.items = section.items.filter(item => {
                if(section.title.toLowerCase().includes(filter.toLowerCase())) {
                    return true;
                }
                if(item.title) {
                    return item.title.toLowerCase().includes(filter.toLowerCase());
                }
                return false;
            });
            return section;
        }))

    }, [filter]);

    useEffect(() => {
        setSections(fields);
    }, [fields]);

    return getContent();
}
export default FieldMapper;
